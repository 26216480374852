// import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// const initialState: any = {
//   loginData: [],
// };

// export const LoginSlice = createSlice({
//   name: "Login",
//   initialState,
//   reducers: {
//     login: (state, action: PayloadAction<any>) => {
//       state.loginData = action.payload;
//     },
//   },
// });

// export default LoginSlice.reducer;
// export const { login } = LoginSlice.actions;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loginData: null, // Store login data
  sessionToken: null, // Store session token for future requests
  roles: [], // Store user roles
};

export const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      state.loginData = action.payload;
      state.sessionToken = action.payload?.attributes?.sessionToken;
      state.roles = action.payload?.attributes?.RoleAssigned || [];
    },
    logout: (state) => {
      state.loginData = null;
      state.sessionToken = null;
      state.roles = [];
    },
  },
});

export default LoginSlice.reducer;
export const { login, logout } = LoginSlice.actions;
