import { memo } from "react";
import "./widget.scss";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

type widgetProps = {
  type: String;

  data: any;
  
};

const Widget = memo((props: widgetProps) => {
  const { type, data } = props;

  let content;

  switch (props.type) {
    case "Total_Bookings":
      content = {
        title: "Total Bookings",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: false,
        link: "",
        icon: <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 21H6C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V10.5M16 3V7M8 3V7M4 11H15M17.8 20.817L15.628 21.955C15.5635 21.9886 15.491 22.0035 15.4186 21.9982C15.3461 21.993 15.2765 21.9677 15.2176 21.9251C15.1587 21.8826 15.1127 21.8245 15.0849 21.7574C15.0571 21.6903 15.0485 21.6167 15.06 21.545L15.475 19.134L13.718 17.427C13.6656 17.3763 13.6284 17.3119 13.6108 17.2411C13.5933 17.1703 13.5959 17.096 13.6186 17.0267C13.6412 16.9573 13.6829 16.8957 13.7388 16.8489C13.7948 16.8021 13.8627 16.772 13.935 16.762L16.363 16.41L17.449 14.217C17.4815 14.1517 17.5315 14.0967 17.5935 14.0583C17.6556 14.0199 17.727 13.9995 17.8 13.9995C17.8729 13.9995 17.9444 14.0199 18.0064 14.0583C18.0685 14.0967 18.1185 14.1517 18.151 14.217L19.237 16.41L21.665 16.762C21.737 16.7724 21.8047 16.8027 21.8604 16.8495C21.9162 16.8964 21.9576 16.9579 21.9802 17.027C22.0028 17.0962 22.0056 17.1704 21.9882 17.241C21.9708 17.3117 21.9341 17.3761 21.882 17.427L20.125 19.134L20.539 21.544C20.5514 21.6159 20.5434 21.6898 20.516 21.7573C20.4885 21.8249 20.4426 21.8834 20.3836 21.9262C20.3245 21.969 20.2547 21.9944 20.1819 21.9995C20.1092 22.0046 20.0364 21.9892 19.972 21.955L17.8 20.817Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "Cancelled_Bookings":
      content = {
        title: "Cancelled Bookings",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: false,
        link: "",
        icon: <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 21H6C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V13.5M16 3V7M8 3V7M4 11H20M22 22L17 17M17 22L22 17" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "Upcoming_Bookings":
      content = {
        title: "Upcoming Bookings",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: false,
        link: "",
        icon: <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.795 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V11H3M15 3V7M7 3V7M18 16.496V18L19 19M14 18C14 19.0609 14.4214 20.0783 15.1716 20.8284C15.9217 21.5786 16.9391 22 18 22C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18C22 16.9391 21.5786 15.9217 20.8284 15.1716C20.0783 14.4214 19.0609 14 18 14C16.9391 14 15.9217 14.4214 15.1716 15.1716C14.4214 15.9217 14 16.9391 14 18Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "Expired_Bookings":
      content = {
        title: "Expired Bookings",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: false,
        link: "",
        icon: <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.795 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V11H3M15 3V7M7 3V7M18 16.496V18L19 19M14 18C14 19.0609 14.4214 20.0783 15.1716 20.8284C15.9217 21.5786 16.9391 22 18 22C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18C22 16.9391 21.5786 15.9217 20.8284 15.1716C20.0783 14.4214 19.0609 14 18 14C16.9391 14 15.9217 14.4214 15.1716 15.1716C14.4214 15.9217 14 16.9391 14 18Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "usage":
      content = {
        title: "Energy Consumed",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total.toFixed(2) + " " + "kW",
        isMoney: false,
        link: "",
        icon: <ElectricalServicesIcon className="icon" />,
      };
      break;
    case "customers":
      content = {
        title: "Customer Enrollments",
        thisMonth: (50.0345).toFixed(2),
        lastMonth: (20.0345).toFixed(2),
        icon: <EmojiPeopleIcon className="icon" />,
      };
      break;
    case "billedRevenue":
      content = {
        title: "Revenue",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        total: "₹" + " " + data.total.toFixed(2),
        isMoney: false,
        link: "",
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M15 8.26953H9H10C10.7956 8.26953 11.5587 8.5856 12.1213 9.14821C12.6839 9.71082 13 10.4739 13 11.2695C13 12.0652 12.6839 12.8282 12.1213 13.3909C11.5587 13.9535 10.7956 14.2695 10 14.2695H9L12 17.2695M9 11.2695H15M3 12.2695C3 13.4514 3.23279 14.6218 3.68508 15.7137C4.13738 16.8056 4.80031 17.7978 5.63604 18.6335C6.47177 19.4692 7.46392 20.1322 8.55585 20.5844C9.64778 21.0367 10.8181 21.2695 12 21.2695C13.1819 21.2695 14.3522 21.0367 15.4442 20.5844C16.5361 20.1322 17.5282 19.4692 18.364 18.6335C19.1997 17.7978 19.8626 16.8056 20.3149 15.7137C20.7672 14.6218 21 13.4514 21 12.2695C21 11.0876 20.7672 9.91731 20.3149 8.82538C19.8626 7.73345 19.1997 6.7413 18.364 5.90557C17.5282 5.06984 16.5361 4.40691 15.4442 3.95462C14.3522 3.50232 13.1819 3.26953 12 3.26953C10.8181 3.26953 9.64778 3.50232 8.55585 3.95462C7.46392 4.40691 6.47177 5.06984 5.63604 5.90557C4.80031 6.7413 4.13738 7.73345 3.68508 8.82538C3.23279 9.91731 3 11.0876 3 12.2695Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,
      };
      break;
    case "avRevenue":
      content = {
        title: "Average Revenue Per Session",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        total: data.total,
        isMoney: true,
        link: "",
        icon: <PriceChangeIcon className="icon" />,
      };
      break;
    case "chargeSession":
      content = {
        title: "Charge Sessions",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: true,
        link: "",
        icon: <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 3C2 2.46957 2.21071 1.96086 2.58579 1.58579C2.96086 1.21071 3.46957 1 4 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V3.5C17 3.63261 17.0527 3.75979 17.1464 3.85355C17.2402 3.94732 17.3674 4 17.5 4C17.6326 4 17.7598 4.05268 17.8536 4.14645C17.9473 4.24021 18 4.36739 18 4.5V7.5C18 7.63261 17.9473 7.75979 17.8536 7.85355C17.7598 7.94732 17.6326 8 17.5 8C17.3674 8 17.2402 8.05268 17.1464 8.14645C17.0527 8.24021 17 8.36739 17 8.5V9C17 9.53043 16.7893 10.0391 16.4142 10.4142C16.0391 10.7893 15.5304 11 15 11H10.5M4 16V13M2 9V6.5M6 9V6.5M1 9H7V11C7 11.5304 6.78929 12.0391 6.41421 12.4142C6.03914 12.7893 5.53043 13 5 13H3C2.46957 13 1.96086 12.7893 1.58579 12.4142C1.21071 12.0391 1 11.5304 1 11V9Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    default:
      break;
  }
  return (
   
    <div className="Widget_container chargeSession_container ml-5">
      <div className="title flex items-center mx-1">
       <div className="icon_widget_transaction">
       {content?.icon}
        </div> 
        <h1 className="ml-2">
        {content?.title}
          </h1>
        </div>

      <h1 style={{ color: "rgb(61, 57, 57)" }}
          className=" text-lg font-lexend font-bold ml-2"> {content?.total}</h1>
    </div>
  );
});

export default Widget;
