import React, { useEffect, useState } from "react";
import {
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";

import { Autocomplete, TextField, Checkbox, Chip } from "@mui/material";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import Fade from "@mui/material/Fade";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface optionObj {
  id: string;
  label: string;
}
interface LocationFilter {
  id: string;
  label: string;
}

interface RevenueData {
  YearMonth: string;
  Revenue?: number;
  ForecastedRevenue?: number;
  ForecastedRevenueTillDate?: number;
  ForecastedRevenueFull?: number;
}

interface RevenueDataErrorMargin {
  YearMonth: string;
  Revenue?: number;
  ForecastedRevenue?: number;
}

export default function IndexBar() {
  const [expanded, setExpanded] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false); // To open Filter
  // Show / Hide Filter Function
  const handleFilterButtonClick = () => {
    // Toggle filter visibility when user clicks on "Filters" button
    setIsFilterVisible((prev) => !prev);
  };

  const [data, setData] = useState([]);
  const date = "This Month";

  const [billedRevenue, setBilledRevenue] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });

  const [series, setSeries] = useState(0);
  const [currentTillDate, setCurrentTillDate] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const loadSession = async () => {
    try {
      // Charge session for current month
      const ChargeSession = Parse.Object.extend("ChargeSession");
      const parseQuery = new Parse.Query(ChargeSession);
      parseQuery.include("ChargePoint");
      if (date === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("month").toString())
        );
      }
      parseQuery.limit(5000);
      parseQuery.find().then((result: any[]) => {
        let newRow: {
          id: number;
          status: string;
          startTime: string;
          endTime: string;
          chargeBy: string;
          chargeFor: string;
          customer: string;
          location: string;
          carCharged: string;
          aid: string;
          date: string;
          cost: string;
          energy: string;
          duration: string;
          obj: any;
          serialNum: string;
          power: string;
          connector: string;
        }[] = [];

        let usersArray: any[] = [];
        let totalMoney = 0;
        let thisMonthMoney = 0;
        let lastMonthMoney = 0;
        result.forEach((item, index) => {
          totalMoney = item.get("TotalCost")
            ? item.get("TotalCost") + totalMoney
            : 0 + totalMoney;
          let car = "";
          if (`${item.get("Vehicle")}` !== "undefined") {
            car = `${item.get("Vehicle").get("Name")}`;
          }

          const userObj = {
            id: `${item.get("User").id}`,
            label: `${item.get("User").get("FullName")}`,
          };

          if (
            !usersArray.find(function (i) {
              return i.id === userObj.id;
            })
          ) {
            usersArray.push(userObj);
          }

          newRow.push({
            id: index + 1,
            status: `${item.get("User").get("FullName")}`,
            customer: `${item.get("User").get("FullName")}`,
            location: `${item.get("Location").get("Name")}`,
            serialNum: `${item.get("ChargePoint").get("Serial")}`,
            power: `${item.get("ChargePoint").get("Power")}`,
            connector: `${item.get("ChargePoint").get("Connector")}`,
            startTime: `${
              moment(item.get("createdAt")).format("lll")
                ? moment(item.get("createdAt")).format("lll")
                : "-"
            }`,

            endTime: `${
              moment(item.get("updatedAt")).format("lll")
                ? moment(item.get("updatedAt")).format("lll")
                : "-"
            }`,
            carCharged: car,
            chargeBy: `${item.get("ChargeBy")}`,
            chargeFor: `${item.get("ChargeFor")}`,
            duration: `${
              moment
                .duration(
                  moment(new Date(item.get("updatedAt"))).diff(
                    moment(new Date(item.get("createdAt")))
                  )
                )
                .hours() +
              "hr" +
              " " +
              moment
                .duration(
                  moment(new Date(item.get("updatedAt"))).diff(
                    moment(new Date(item.get("createdAt")))
                  )
                )
                .minutes() +
              "min"
            }  `,
            aid: `${item.get("AID")}`,
            date: `${item.get("createdAt")}`,
            cost: `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
            energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
              2
            )} kWh`,
            obj: item,
          });
        });

        // console.log("userArray", usersArray);
        // console.log("", usersArray);
        setBilledRevenue({
          thisMonth: thisMonthMoney,
          lastMonth: lastMonthMoney,
          total: totalMoney,
        });

        // setAllUsers(!allUsers.length ? usersArray : allUsers);
      });

      const url = `${process.env.REACT_APP_OCPP_BASE_URL}/projections?projection_type=revenue`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      setData(result?.revenue_data); // Update state with the fetched data

      // Get current month and year
      const now = new Date();
      const day = String(now.getDate()).padStart(2, "0");
      const currentMonth = String(now.getMonth() + 1).padStart(2, "0"); // Add 1 because getMonth() returns 0-based month
      const currentYear = now.getFullYear();

      // Format as "MM-YYYY"
      const currentYearMonth = `${currentMonth}-${currentYear}`;
      console.log("currentYearMonth", day + "-" + currentYearMonth);
      const fullDate = `${day}-${currentYearMonth}`;
      setCurrentTillDate(fullDate);
      const monthName = now.toLocaleString("default", { month: "long" });
      setCurrentMonth(monthName);
      console.log("monthName", monthName);
      // Find the data for the current month and year
      const currentData = result?.revenue_data.find(
        (item: any) =>
          item.YearMonth === currentYearMonth && item?.ForecastedRevenueTillDate
      );

      setSeries(currentData?.ForecastedRevenueTillDate.toFixed(2));
    } catch (err) {
      console.error("Error fetching the projections data:", err);
    }
  };

  useEffect(() => {
    loadSession(); // Call the async function when the component mounts
  }, []);

  console.log("series", series);
  console.log("billedRevenue", billedRevenue?.total);

  const formatYearMonth = (yearMonth: string) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const [month, year] = yearMonth.split("-");
    const monthName = monthNames[parseInt(month, 10) - 1]; // Convert month number to month name

    return `${monthName}-${year.slice(2)}`; // Extract the last two digits of the year
  };

  const transformedData = (() => {
    console.log("Full Data", data);

    // Step 1: Get unique data based on YearMonth, prioritizing Revenue over ForecastedRevenue or ForecastedRevenueTillDate
    const uniqueData = (data as RevenueData[]).reduce(
      (acc: RevenueData[], item: RevenueData) => {
        const existingIndex = acc.findIndex(
          (t) => t.YearMonth === item.YearMonth
        );

        if (existingIndex === -1) {
          acc.push(item);
        } else {
          const existingItem = acc[existingIndex];

          // Prioritize Revenue over ForecastedRevenue and ForecastedRevenueTillDate
          if (item.Revenue) {
            acc[existingIndex] = item;
          } else if (item.ForecastedRevenue) {
            existingItem.ForecastedRevenue = item.ForecastedRevenue;
          } else if (
            item.ForecastedRevenueTillDate &&
            !existingItem.ForecastedRevenue
          ) {
            existingItem.ForecastedRevenueTillDate =
              item.ForecastedRevenueTillDate;
          }
        }

        return acc;
      },
      []
    );

    console.log("uniqueData", uniqueData);

    // Step 2: Get current month and year for comparison
    const now = new Date();
    const currentMonth = String(now.getMonth() + 1).padStart(2, "0"); // Add 1 because getMonth() returns 0-based month
    const currentYear = now.getFullYear();
    const currentYearMonth = `${currentMonth}-${currentYear}`;

    // Step 3: Extract the last 7 months of Revenue data (including current month if it has Revenue)
    const energyConsumedData = uniqueData
      .filter((item: RevenueData) => item.Revenue) // Include only items with Revenue
      .slice(-7) // Select last 7 months of Revenue data
      .map((item: RevenueData) => ({
        name: formatYearMonth(item.YearMonth),
        Revenue: item.Revenue,
        type: "historical",
      }));

    // Step 4: Get forecasted data starting from the current month and for the next 3 future months
    const forecastedData = uniqueData
      .filter((item: RevenueData) => {
        const [month, year] = item.YearMonth.split("-").map((str) =>
          parseInt(str, 10)
        );
        const itemDate = new Date(year, month - 1);

        // Ensure forecasted data is for current month or future months
        const isFutureOrCurrent =
          itemDate >= new Date(currentYear, parseInt(currentMonth) - 1);
        const isCurrentMonth = item.YearMonth === currentYearMonth;

        // Include if it's a future month or the current month with forecasted revenue (ignore ForecastedRevenueTillDate if ForecastedRevenue exists)
        return (
          isFutureOrCurrent &&
          (item.ForecastedRevenue ||
            (isCurrentMonth && item.ForecastedRevenueTillDate)) &&
          (!item.Revenue || isCurrentMonth) // Avoid duplication if Revenue is already shown in historical
        );
      })
      .slice(0, 3) // Limit to the next 3 months including current
      .map((item: RevenueData) => ({
        name: formatYearMonth(item.YearMonth),
        Revenue: item.ForecastedRevenue || item.ForecastedRevenueTillDate,
        type: "predictive",
      }));

    console.log("forecastedData", forecastedData);

    // Step 5: Concatenate the two sets of data
    return [...energyConsumedData, ...forecastedData];
  })();

  const slicedData = transformedData.slice(-10);
  console.log("slicedData", slicedData);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const [lineChartData, setLineChartData] = useState<any>(0);

  const CustomTooltip: React.FC<{
    active?: boolean;
    payload?: any[];
    label?: string;
  }> = ({ active, payload, label }) => {
    console.log("active Custom Tooltip", active);
    console.log("payload Custom Tooltip", payload);
    console.log("label Custom Tooltip", label);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-5 w-64">
          <h3 className="font-lexend text-l font-semibold">{`${label}`}</h3>

          <div>
            <span className="flex">
              {/* <span>
                <TripOriginIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color:"#FFA38E"
                  }}
                />
                </span>
              */}
              <span className="font-lexend text-sm ml-2">
                Actual : {payload?.[1]?.payload?.actual?.toFixed(2)}
              </span>
            </span>
            <span className="flex">
              <span>
                {/* <TripOriginIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color:"#6AD3A7"
                  }}
                /> */}
              </span>

              <span className="font-lexend text-sm ml-2">
                Projection : {payload?.[1]?.payload?.forecasted?.toFixed(2)}
              </span>
            </span>
            <span className="flex">
              <span>
                {/* <TripOriginIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color:"#bf2502"
                  }}
                /> */}
              </span>

              <span className="font-lexend text-sm ml-2">
                Margin : {payload?.[1]?.payload?.margin?.toFixed(2)}
              </span>
            </span>

            {/* <span>{ele?.payload?.actual}</span> */}
            {/* <span className="flex items-center" key={index}>
              <span>
                <TripOriginIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color:"#FFA38E"
                  }}
                />
              </span>
              {/* <span className="font-lexend text-l ml-2">{ele?.name} :</span>{" "}
              <span className="font-lexend text-l ml-3">{ele?.value}</span>{" "} */}
            {/* <span>Actual: {ele?.payload?.actual}</span> */}
            {/* </span> */}
          </div>
        </div>
      );
    }

    return null;
  };

  const ActualVsProjection: React.FC<{
    active?: boolean;
    payload?: any[];
    label?: string;
  }> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-5 w-64">
          <h3 className="font-lexend text-l font-semibold">{`${label}`}</h3>
          {payload?.map((ele, index) => (
            <span className="flex items-center" key={index}>
              <span>
                <TripOriginIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color:
                      ele?.payload?.type === "predictive"
                        ? "#6AD3A7"
                        : ele?.payload?.type === "historical"
                        ? "#FFA38E"
                        : "#6AD3A7",
                  }}
                />
              </span>
              <span className="font-lexend text-l ml-2">{ele?.name} :</span>{" "}
              {/* Added curly braces to render value */}
              <span className="font-lexend text-l ml-3">
                {ele?.value.toFixed(2)}
              </span>{" "}
              {/* Added curly braces to render value */}
            </span>
          ))}
        </div>
      );
    }

    return null;
  };

  const ErrorMarginTooltip: React.FC<{
    active?: boolean;
    payload?: any[];
    label?: string;
  }> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log("ActiveErrorMargin", active);
      console.log("PayloadErrorMargin", payload);
      console.log("LabelErrorMargin", label);

      return (
        <div
          style={{ background: "rgba(255, 255, 255, 0.324)" }}
          className="custom-tooltip m-2 w-42 "
        >
          <h3 className="font-lexend text-l font-semibold">{`${label}`}</h3>

          {payload?.map((ele, index) => (
            <div key={ele?.payload?.actual}>
              {/* Actual */}
              <span className="flex items-center">
                <span>
                  <TripOriginIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#FFA38E",
                    }}
                  />
                </span>
                {/* <span className="font-lexend text-sm ml-2">{ele?.payload?.type === "predictive" ? "Projection Energy Consumption" : "Energy Consumption"} :</span>{" "} */}
                {/* Added curly braces to render value */}
                <span className="font-lexend text-sm ml-2">Actual:</span>
                <span className="font-lexend text-l ml-3">
                  {ele?.payload?.actual?.toFixed(2)}
                </span>{" "}
                {/* Added curly braces to render value */}
              </span>

              {/* Projection */}
              <span className="flex items-center" key={index}>
                <span>
                  <TripOriginIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#6AD3A7",
                    }}
                  />
                </span>
                {/* <span className="font-lexend text-sm ml-2">{ele?.payload?.type === "predictive" ? "Projection Energy Consumption" : "Energy Consumption"} :</span>{" "} */}
                {/* Added curly braces to render value */}
                <span className="font-lexend text-sm ml-2">
                  Projection: {ele?.forecasted}
                </span>
                <span className="font-lexend text-l ml-3">
                  {ele?.payload?.forecasted?.toFixed(2)}
                </span>{" "}
                {/* Added curly braces to render value */}
              </span>

              {/* Margin */}
              <span className="flex items-center" key={index}>
                <span>
                  <TripOriginIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#c50808",
                    }}
                  />
                </span>
                {/* <span className="font-lexend text-sm ml-2">{ele?.payload?.type === "predictive" ? "Projection Energy Consumption" : "Energy Consumption"} :</span>{" "} */}
                {/* Added curly braces to render value */}
                <span className="font-lexend text-sm ml-2">Margin:</span>
                <span className="font-lexend text-l ml-3">
                  {ele?.value.toFixed(2)}
                </span>{" "}
                {/* Added curly braces to render value */}
              </span>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const getWidgetsData = () => {
    const ChargeSession = Parse.Object.extend("ChargeSession");
    const parseQuery = new Parse.Query(ChargeSession);
    parseQuery.include("ChargePoint");

    // if (userIds.length > 0) {
    //   parseQuery.containedIn("User", userIds);
    // }

    // if (locationIds.length > 0) {
    //   parseQuery.containedIn("Location", locationIds);
    // }

    //  Check the current is super admin or not if user is super admin than all Charge
    //  session data would be showed otherwise only user CPO Charge session data would
    //  be shown
    // if (currentUser) {
    //   // The current user must be super admin to view all Charge session data
    //   var innerQuery = new Parse.Query("Chargers");
    //   innerQuery.equalTo("CPO", currentUser.get("CPO"));
    //   if (!JSON.parse(userDetail).isSuperAdmin) {
    //     parseQuery.matchesQuery("ChargePoint", innerQuery);
    //   } else {
    //     parseQuery.matchesQuery("ChargePoint", innerQuery);
    //   }
    // }

    parseQuery.include("Location");
    parseQuery.ascending("createdAt");
    parseQuery.limit(5000);
    parseQuery.find().then((result) => {
      type Person = {
        name: string;
        Revenue: any;
      };
      var dateArray: any[] = [];
      var tempData: Person[] = [];
      var lineData: any = [];
      let totalEnergyCost = 0;

      let totalMoney = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      let totalEnergyConsumed = 0;
      let totalElectricityTarrif = 0;

      result.forEach((item, index) => {
        let dateM = item.createdAt;

        let dateValue = moment(dateM).format("MMM YY");
        let currentRevenue = item.get("TotalCost") ? item.get("TotalCost") : 0;
        let currentEnergyCost = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") *
            item.get("Location").get("ElectricityTariff")
          : 0;

        let currentMargin = currentRevenue - currentEnergyCost;
        if (!dateArray.includes(dateValue)) {
          dateArray.push(dateValue);
          let newItem = {
            name: dateValue,
            Revenue: parseFloat(currentRevenue.toFixed(2)),
          };
          let oldItem = {
            name: dateValue,
            Revenue: parseFloat(currentRevenue.toFixed(2)),
            Actual: parseFloat(currentEnergyCost.toFixed(2)),
            Projection: currentMargin,
          };
          tempData.push(newItem);
          lineData.push(oldItem);
        } else {
          tempData.forEach((el: { name: string; Revenue: any }) => {
            if (el.name === dateValue) {
              var sessions = el.Revenue;
              el.Revenue = parseFloat((sessions + currentRevenue).toFixed(2));
            } else {
              return;
            }
          });
          lineData.forEach(
            (el: {
              name: string;
              Revenue: any;
              Actual: any;
              Projection: any;
            }) => {
              if (el.name === dateValue) {
                var sessions = el.Revenue;
                var energy = el.Actual;
                var gross = el.Projection;
                el.Revenue = parseFloat((sessions + currentRevenue).toFixed(2));
                el.Actual = parseFloat((energy + currentEnergyCost).toFixed(2));
                el.Projection = parseFloat((gross + currentMargin).toFixed(2));
              } else {
                return;
              }
            }
          );
        }

        totalEnergyCost = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") *
              item.get("Location").get("ElectricityTariff") +
            totalEnergyCost
          : 0 + totalEnergyCost;
        totalMoney = item.get("TotalCost")
          ? item.get("TotalCost") + totalMoney
          : 0 + totalMoney;
        totalElectricityTarrif = item.get("Location").get("ElectricityTariff")
          ? item.get("Location").get("ElectricityTariff") +
            totalElectricityTarrif
          : 0 + totalElectricityTarrif;
        totalSession = totalSession + 1;
        totalEnergyConsumed = item.get("TotalEnergyConsumed")
          ? totalEnergyConsumed + item.get("TotalEnergyConsumed")
          : totalEnergyConsumed;
      });
      setLineChartData(lineData);
      console.log("tempData", tempData);
    });
  };

  useEffect(() => {
    getWidgetsData();
  }, []);

  // Filter code start from Here
  const [chargerSerialFilter, setChargerSerialFilter] = useState<any>("");
  const [isSelected, setIsSelected] = useState(false);
  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [locationFilter, setLocationFilter] = useState<LocationFilter[]>([]);
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );
  const [location_Type_Filter, set_location_Type_Filter] = useState("");

  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [currentTypeFilter, setCurrentTypeFilter] = useState<any>("");
  const [locationTypeFilter, setLocationTypeFilter] = useState<any>("");
  const [dateFilter, setDateFilter] = useState("");
  // Chips Code
  const [dateChips, setDateFilterChips] = useState<any[]>([]);
  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [locationTypeChips, setLocationTypeChips] = useState<any[]>([]);
  const [currentTypeChips, setCurrentTypeFilterChips] = useState<any[]>([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);
  let userDetail: any = sessionStorage.getItem("user-details");
  const currentUser: any = Parse.User.current();
  const getAllLocations = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);
      locationQuery.find().then((result) => {
        let addressArray: any[] = [];

        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          addressArray.push({
            id: item.id,
            label: item.get("Name"),
          });
          setAllLocations(addressArray);
        });
      });
    });
  };

  const getAllLocationTypes = () => {
    const Locations = Parse.Object.extend("Locations");
    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let addressArray: optionObj[] = [];
      let locationType: any[] = [];

      result.forEach((item) => {
        if (
          !locationType.find(function (i) {
            return i === item.get("LocationType");
          })
        ) {
          locationType.push(item.get("LocationType"));
        }
        addressArray.push({
          id: item.id,
          label: item.get("Name"),
        });
      });

      // setAllLocationType(locationType);
    });
  };

  useEffect(() => {
    getAllLocations();
    getAllLocationTypes();
  }, []);

  // Location Type Change Code for apply Location Type Filter
  const handleLocationTypeChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = locationTypeChips.find((chip) => chip.label === value);

    // If the selected date is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous date chip if it exists
      const updatedChips = locationTypeChips.filter(
        (chip) => chip.id !== location_Type_Filter
      );

      setLocationTypeChips([...updatedChips, { id: value, label: value }]);
    }
    setLocationTypeFilter(value);
    set_location_Type_Filter((prevValue) => (prevValue === value ? "" : value));
  };
  // Current Type Change Code for apply Current Type Filter (AC/DC)
  const handleCurrentChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = currentTypeChips.find((chip) => chip.label === value);

    if (!existingChip) {
      const updatedChips = currentTypeChips.filter(
        (chip) => chip.id !== currentTypeFilter
      );

      setCurrentTypeFilterChips([...updatedChips, { id: value, label: value }]);
    }
    setCurrentTypeFilter(value);
  };

  // Single Date Change function for apply single Date Filter
  const handleDateChange = (value: string) => {
    if (value === "Custom") {
      setIsSelected(!isSelected);
      // setOpenCustom((prevOpenCustom) => !prevOpenCustom);
      setDateFilterChips([]);
      setDateFilter("");
      // Clear custom date range when selecting "Custom"
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
    } else {
      // Update the dateFilter state
      setDateFilter(value);
      // Update the dateFilterChips array based on the selected value
      const selectedChip = {
        id: value,
        label:
          value === "Today"
            ? "Today"
            : value === "This Month"
            ? "This Month"
            : value === "This Week"
            ? "This Week"
            : "This Year",
      };
      setDateFilterChips([selectedChip]);
      // Clear custom date range when selecting predefined filters
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
      setIsSelected(false);
    }
  };
  // Range Code for apply Range Date Filter
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);

    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  const options: ApexOptions = {
    series: [
      {
        data: [billedRevenue?.total, series],
      },
    ],
    chart: {
      type: "bar", // Ensure this is a string literal matching allowed chart types
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
        distributed: true,
      },
    },

    colors: ["#FFA38E", "#6AD3A7"],
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        rotate: -45,
        maxWidth: 150, // Limit the width of the y-axis labels
        style: {
          fontSize: "13px",
          fontWeight: 400,
        },
        formatter: (value: any) => {
          console.log("Value of yaxis");

          // Format large numbers into "k" notation
          if (value >= 1000) return `${value / 1000}k`;
          return value; // Return original value if it's less than 1000
        },
      },
      axisTicks: {
        show: true,

        color: "#78909C",

        offsetX: 0,
        offsetY: 0,
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
        formatter: (value: any) => {
          console.log("Value of xaxis", value);

          // Format large numbers into "k" notation
          if (value >= 1000) return `${value / 1000}k`;
          return value; // Return original value if it's less than 1000
        },
      },
      axisTicks: {
        show: true,

        color: "#78909C",

        offsetX: 0,
        offsetY: 0,
      },
      categories: [`Current Revenue`, `Projected Revenue`],
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const heading =
          dataPointIndex === 0
            ? `${currentMonth} Revenue`
            : `Projected ${currentMonth} Projected Revenue`;
        const width = dataPointIndex === 0 ? `160px` : `300px`;
        return `
         <div style="display:flex; justify-item:center;align-items:center; flex-direction:column;border: 1px solid #808D7C;border-radius:5px">
         <div style="font-family: 'Lexend', sans-serif; margin: 7px auto ; width: ${width}; font-size: 13px; padding: 2px 15px; font-weight: bold; border-bottom: 1px solid #808D7C">
          ${heading}
        </div>
        <div style="font-family: 'lexend', sans-serif; padding: 0.5rem;">
          ${series[seriesIndex][dataPointIndex].toFixed(2)}
        </div>
        </div>

      `;
      },
    },
    legend: {
      show: false, // Hide the legend
    },
  };

  const getPastMonths = (numMonths: number): string[] => {
    const monthsArray: string[] = [];
    
    // const currentDate = new Date(2025, 3); // February 2025 (Months are zero-based in JS)
    const currentDate = new Date();
    // currentDate.setMonth(currentDate.getMonth() - 1);
    currentDate.setMonth(currentDate.getMonth() - 1)
    for (let i = 0; i < numMonths; i++) {
      const month = currentDate.getMonth() + 1; // Get month (1-based)
      const year = currentDate.getFullYear(); // Get year
      const formattedMonth = `${String(month).padStart(2, '0')}-${year}`; // Format MM-YYYY
      monthsArray.push(formattedMonth);
      currentDate.setMonth(currentDate.getMonth() - 1); // Move to the previous month
    }
  
    return monthsArray.reverse(); // Reverse to maintain chronological order
  };
  
  
  
  // Generate past 7 months (including current)
  const dynamicMonthArray = getPastMonths(7);
  
  console.log("dynamicMonthArray",dynamicMonthArray);

  // Error Margin
  const ErrorMarginData = dynamicMonthArray?.map((month) => {
    // Finding actual data where EnergyConsumed is defined
    const actualData = data.find(
      (item: RevenueDataErrorMargin) =>
        item.YearMonth === month && item.Revenue !== undefined
    ) as RevenueDataErrorMargin | undefined; // Type assertion here

    // Finding forecasted data where ForecastedEnergy is defined
    const forecastedData = data.find(
      (item: RevenueDataErrorMargin) =>
        item.YearMonth === month && item.ForecastedRevenue !== undefined
    ) as RevenueDataErrorMargin | undefined; // Type assertion here

    console.log("actualData", actualData);
    console.log("forecastedData", forecastedData);

    // Ensure that TypeScript understands the types and provides default values
    const actualEnergy = actualData?.Revenue ?? 0; // Use optional chaining and nullish coalescing
    const forecastedEnergy = forecastedData?.ForecastedRevenue ?? 0; // Use optional chaining and nullish coalescing

    // Logging actual and forecasted data for debugging
    console.log("actualEnergy:", actualEnergy);
    console.log("forecastedEnergy:", forecastedEnergy);

    return {
      name: month,
      actual: actualEnergy,
      forecasted: forecastedEnergy,
      margin: actualEnergy - forecastedEnergy, // Calculate the margin
    };
  });

  console.log("Error Margin calculation", ErrorMarginData);

  return (
    <div className="transaction-container">
      <div className="transaction_table_container flex">
        <div style={{ width: "100%" }}>
          <div className="flex justify-between mx-6 mt-5 ">
            <h2 className="main_heading mt-2">Revenue Projection</h2>
            <div className="flex justify-between items-center">
              <button
                className="rounded-full p-3 border-2 border-black-600"
                onClick={handleFilterButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                    stroke="#111111"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* Chips container to display chip */}

          {/* All Graph Code here */}

          {/* Cost Margin Line Graph */}
          <span>
            <p className="font-lexend mt-4 ml-14 absolute top-22 left-30 font-semibold ">
              Actual Vs Projected Revenue
            </p>
            <ResponsiveContainer
              className="border-2 border-gray-300 rounded-md m-4"
              width="94%"
              maxHeight={400}
            >
              <LineChart
                data={ErrorMarginData}
                margin={{
                  top: 55,
                  right: 30,
                  left: 20,
                  bottom: 40,
                }}
              >
                <CartesianGrid strokeDasharray="3 1" />
                <XAxis
                  tickLine={false}
                  dataKey="name"
                  style={{
                    fill: "rgba(0, 0, 0, 0.40)",
                    fontSize: "13px",
                    fontWeight: "600",
                    fontStyle: "normal",
                    fontFamily: "Lexend",
                    textAlign: "right",
                    lineHeight: "18px",
                    letterSpacing: "-0.12px",
                  }}
                />
                <YAxis
                  tickLine={false}
                  style={{
                    fill: "rgba(0, 0, 0, 0.40)",
                    fontSize: "13px",
                    fontWeight: "600",
                    fontStyle: "normal",
                    fontFamily: "Lexend",
                    textAlign: "right",
                    lineHeight: "18px",
                    letterSpacing: "-0.12px",
                  }}
                />
                {/* <Tooltip /> */}
                <Tooltip
                  wrapperStyle={{
                    backgroundColor: "#fff",
                    border: "none",
                    boxShadow: "none",
                    outline: "none",
                  }}
                  content={<CustomTooltip />}
                />

                <Line
                  type="monotone"
                  dataKey="actual"
                  name="actual"
                  activeDot={{ r: 8 }}
                  stroke="#FFA38E"
                  strokeWidth={3}
                />

                <Line
                  type="monotone"
                  dataKey="forecasted"
                  activeDot={{ r: 8 }}
                  stroke="#6AD3A7"
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="flex relative bottom-12 left-30 justify-center my-4">
              <span className="flex items-center">
                <div
                  style={{ background: "#FFA38E" }}
                  className="w-4 h-4"
                ></div>
                <div className="font-lexend ml-2">Actual</div>
              </span>
              <span className="flex items-center ml-4">
                <div
                  style={{ background: "#6AD3A7" }}
                  className="w-4 h-4"
                ></div>
                <div className="font-lexend ml-2">Projected</div>
              </span>
            </div>
          </span>

          <span>
            <p className="font-lexend mt-4 ml-12 absolute top-22 left-30 font-semibold">
              Error Margin (Actual - Projected)
            </p>

            <ResponsiveContainer
              className="border-2 border-gray-300 rounded-md m-4"
              width="94%"
              maxHeight={400}
            >
              <BarChart
                data={ErrorMarginData}
                width={300}
                margin={{
                  top: 55,
                  right: 30,
                  left: 20,
                  bottom: 40,
                }}
              >
                <CartesianGrid strokeDasharray="3 1" />
                <XAxis dataKey="name" angle={-30} tickMargin={20} />
                <YAxis />
                {/* <Tooltip /> */}
                {/* <Legend /> */}

                <Tooltip
                  wrapperStyle={{
                    backgroundColor: "#fff",
                    border: "none",
                    boxShadow: "none",
                    outline: "none",
                  }}
                  content={<ErrorMarginTooltip />}
                />

                <ReferenceLine y={0} stroke="#c34444" />
                {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                <Bar dataKey="margin" fill="#FFA38E" />
              </BarChart>
            </ResponsiveContainer>
          </span>

          <div style={{ width: "100%" }} className="flex item-center">
            <div className="w-1/2 p-2">
              <div className="mt-6 absolute top-22 left-30 flex items-center justify-center ml-10">
                <p
                  style={{
                    fontSize: isFilterVisible ? "15px" : "17px",
                    marginRight: isFilterVisible ? "5px" : "130px",
                  }}
                  className="font-lexend font-semibold "
                >
                  Actual & Projected Revenue
                </p>

                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #d5d5d5",
                    borderRadius: 3,
                    lineHeight: "40px",
                    padding: "3px 10px",
                  }}
                  className="flex justify-center"
                >
                  <span className="flex items-center">
                    <div
                      style={{ background: "#FFA38E" }}
                      className="w-4 h-4"
                    ></div>
                    <div className="font-lexend ml-2">Actual</div>
                  </span>
                  <span className="flex items-center ml-4">
                    <div
                      style={{ background: "#6AD3A7" }}
                      className="w-4 h-4"
                    ></div>
                    <div className="font-lexend ml-2">Projected</div>
                  </span>
                </div>
              </div>

              <ResponsiveContainer
                className="border-2 border-gray-300 rounded-md m-4"
                width="100%"
                maxHeight={400}
              >
                <BarChart
                  data={slicedData}
                  margin={{ top: 60, right: 30, left: 20, bottom: 30 }}
                  barSize={30}
                >
                  <XAxis
                    dataKey="name"
                    tick={{ fontSize: 14 }} // Adjust font size if needed
                    interval={0} // Ensure all ticks are shown
                    angle={-15} // Rotate labels if they overlap
                    textAnchor="end" // Align text properly
                  />
                  <YAxis />
                  <Tooltip
                    wrapperStyle={{
                      backgroundColor: "#fff",
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    content={<ActualVsProjection />}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Bar
                    dataKey="Revenue"
                    isAnimationActive={false}
                    shape={(props: any) => {
                      const { fill, x, y, width, height, ...restProps } = props;
                      const isHistorical =
                        slicedData[props.index].type === "historical";
                      return (
                        <rect
                          {...restProps}
                          x={x}
                          y={y}
                          width={width}
                          height={height}
                          fill={isHistorical ? "#FFA38E" : "#6AD3A7"}
                        />
                      );
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div
              style={{ width: "43%", height: "24.9rem", marginTop: "25px" }}
              className=" border-2 border-gray-300 rounded-md ml-6 p-3 bg-slate-50"
            >
              <h1
                style={{ fontSize: "14.9px" }}
                className="font-lexend font-bold text-wrap"
              >
                Comparison of actual-projected revenue for {currentTillDate}{" "}
                till last date
              </h1>
              <ReactApexChart
                options={options}
                series={options.series}
                type="bar"
                width="100%"
                height={isFilterVisible ? 300 : 320}
              />
              <div
                style={{
                  position: "relative",
                  bottom: isFilterVisible ? "27px" : "1.6rem",
                }}
                className="flex relative bottom-12 -left-30 justify-center"
              >
                <span className="flex items-center">
                  <div
                    style={{ background: "#FFA38E" }}
                    className="w-4 h-4"
                  ></div>
                  <div className="font-lexend ml-2">Current</div>
                </span>
                <span className="flex items-center ml-4">
                  <div
                    style={{ background: "#6AD3A7" }}
                    className="w-4 h-4"
                  ></div>
                  <div className="font-lexend ml-2">Projected</div>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Filter Container By Default Hidden */}
        {isFilterVisible && (
          <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
            <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-2">
              <h1 className="font-semibold font-lexend text-lg">Filters</h1>
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                }}
                onClick={handleFilterButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                    stroke="white"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="filter_main_container px-4">
              <h1 className="font-lexend text-xl font-bold ml-4">Search by</h1>
              {/* Location Accordion  */}
              <Accordion
                defaultExpanded
                expanded={expanded}
                onChange={handleExpansion}
                slots={{ transition: Fade as AccordionSlots["transition"] }}
                slotProps={{ transition: { timeout: 400 } }}
                sx={{
                  "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                  "& .MuiAccordionDetails-root": {
                    display: expanded ? "block" : "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    <h1 className="font-semibold font-lexend text-lg mb-2">
                      Location
                    </h1>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Autocomplete
                    className="mb-4"
                    freeSolo
                    id="checkboxes-tags-demo"
                    disableClearable
                    multiple
                    limitTags={-1}
                    options={allLocations}
                    // loading={loadingUsers}
                    autoHighlight
                    size="small"
                    value={locationFilter} // Set the value to control the selected Location
                    onChange={(event: any, newValue: any) => {
                      console.log("New Value", newValue);

                      setLocationChips(
                        (prevChips: { id: string; label: string }[]) => {
                          // Ensure that newValue is an array of objects with 'id' property
                          const newChips: { id: string; label: string }[] =
                            newValue.map((user: any) => ({
                              id: user.id,
                              label: `Location: ${user.label}`,
                            }));

                          const filteredChips = newChips.filter(
                            (chip) =>
                              !prevChips.some(
                                (prevChip) => prevChip.id === chip.id
                              )
                          );

                          return [...prevChips, ...filteredChips];
                        }
                      );
                      setLocationFilter(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionDisabled={(option) =>
                      filterChips.some((chip) => chip.id === option.id)
                    }
                    renderOption={(props, option) => {
                      let counter = 0;
                      const uniqueKey = `${option.id}_${
                        option.label
                      }_${counter++}`;
                      const selected = filterChips.some(
                        (chip) => chip.id === option.id
                      );
                      // console.log("selected", selected);

                      return (
                        <MenuItem key={uniqueKey} {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={
                              selected ||
                              filterChips.some((chip) => chip.id === option.id)
                            }
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} label="Search" />
                    )}
                  />
                </AccordionDetails>
              </Accordion>
              {/* Location Type Accordion */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <h1 className="font-semibold font-lexend text-lg">
                    Location Type
                  </h1>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={locationTypeFilter === "Public Parking"}
                      onChange={() =>
                        handleLocationTypeChange("Public Parking")
                      }
                    />
                    <label htmlFor="" className="font-lexend">
                      Public Parking
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={locationTypeFilter === "Mall"}
                      onChange={() => handleLocationTypeChange("Mall")}
                    />
                    <label htmlFor="" className="font-lexend">
                      Mall
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={locationTypeFilter === "Office Building"}
                      onChange={() =>
                        handleLocationTypeChange("Office Building")
                      }
                    />
                    <label htmlFor="" className="font-lexend">
                      Office Building
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={locationTypeFilter === "Residential"}
                      onChange={() => handleLocationTypeChange("Residential")}
                    />
                    <label htmlFor="" className="font-lexend">
                      Residential
                    </label>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* Current Type Accordion */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <h1 className="font-semibold font-lexend text-lg">
                    Current Type
                  </h1>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={currentTypeFilter === "AC"}
                      onChange={() => handleCurrentChange("AC")}
                    />
                    <label htmlFor="" className="font-lexend">
                      AC
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={currentTypeFilter === "DC"}
                      onChange={() => handleCurrentChange("DC")}
                    />
                    <label htmlFor="" className="font-lexend">
                      DC
                    </label>
                  </div>
                </AccordionDetails>
              </Accordion>

              <h1 className="font-semibold mt-5 mb-2 font-lexend text-lg">
                Charger Serial
              </h1>
              <TextField
                id="outlined-search"
                label="Search Charger Serial"
                type="text"
                size="small"
                sx={{ width: "100%", marginBottom: 5 }}
                onChange={(event: any) => {
                  setChargerSerialFilter(event.target.value);
                }}
              />

              {/* Date Filter - (Today,This Week,This Month,This Year) */}
              <div className="date_filter_container mt-5 ml-4">
                <h1 className="font-semibold font-lexend text-lg">Date</h1>

                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "This Month"}
                    onChange={() => handleDateChange("This Month")}
                  />
                  <label htmlFor="" className="font-lexend">
                    This Month
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="rounded-checkbox accent-black-500 mr-3"
                    checked={isSelected}
                    onChange={() => handleDateChange("Custom")}
                  />
                  <label htmlFor="" className=" font-lexend">
                    Custom Date
                  </label>
                </div>
              </div>
              {/* When we select Custom than it will open  the date picker */}
              {isSelected && (
                <div className="dateSec mb-4">
                  <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                  <div className="start_dateContainer mt-4">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MuiDatePicker
                        label="Start Date"
                        value={startDateFilter}
                        onChange={dateRangeChange}
                        disabled
                        className="mt-4"
                        renderInput={(params) => (
                          <TextField {...params} error={false} />
                        )}
                        inputFormat="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="endDate_container mt-4">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MuiDatePicker
                        label="End Date"
                        disabled
                        value={endDateFilter}
                        onChange={(item) => {
                          setEndDateFilter(item || "");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} error={false} />
                        )}
                        inputFormat="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Date Range Picker Code for filtering Range date  */}
                  <div className="rangePicker_container mt-4">
                    {/* Range Date Picker */}
                    <DatePicker
                      selected={startDateFilter}
                      onChange={dateRangeChange}
                      startDate={startDateFilter}
                      endDate={endDateFilter}
                      maxDate={new Date()} // Disabled Future Date
                      selectsRange
                      inline
                      peekNextMonth
                      showMonthDropdown // month dropdown
                      showYearDropdown // year dropdown
                      dropdownMode="select"
                      minDate={new Date(2022, 0, 1)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
