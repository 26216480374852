import React, { useEffect, useState, memo } from "react";
import "./databaseTable.scss";
import AddEditModal from "../addEditModal/AddEditModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddEditChargerModal from "../addEditCharger/AddEditChargerModal";

import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { spawn } from "child_process";
import CloseChargerModal from "../ActionChargerModal";
import StopChargerModal from "../ActionChargerModal/StopModal";

interface tableProps {
  dataRow: any;
  refreshLocation: any;
  liveOcppData: any;
  loading: boolean;
  setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DatabaseTable = memo((props: tableProps) => {
  const [allChargePointData, setAllChargePointData] = useState<any>([]);
  const { dataRow, setIsFilterVisible } = props;
  console.log("setIsFilterVisible", setIsFilterVisible);
  const [fetchCharger, setFetchCharger] = useState("");
  const [isLoading, setLoading] = useState(false);

  // TO get all live session of OCPP Charges and its Data
  const loadSessions = () => {
    // setLoading(true);
    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.equalTo("Live", true);
    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");
    parseQuery.find().then((result) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        console.log("Charging Data", item);

        newRow.push({
          chargeSessionId: item.id,
          userId: `${item.get("User").id}`,
          serial: `${item.get("ChargePoint").get("Serial")}`,
          transactionId: `${item.get("TransactionId")}`,
          userName: `${item.get("User").get("FullName")}`,
          userNameChargingWithGun_2:
            item?.get("ChargeGunId") == 2 &&
            `${item.get("User").get("FullName")} : "-"`,
          chargingGunId: `${item.get("ChargeGunId")}`,
          createdAt: `${item.get("createdAt")}`,
          MeterStart: `${item.get("MeterStart")}`,
        });
      });
      getAllChargePoint(newRow);
    });
  };

  let userDetail: any = sessionStorage.getItem("user-details");
  const currentUser: any = Parse.User.current();
  const getOcppData = async (
    id: any,
    item: any,
    userId: any,
    userName: any,
    start: any,
    chargeSessionId: any,
    meterStart: any
  ) => {
    console.log("Live charging using userName", userName);

    const transaction_ID = id;
    await fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${id}`)
      .then((response: any) => response.json())
      .then((res: any) => {
        console.log("Live meter Value", res);
        setAllChargePointData((oldArray: any) => [
          ...oldArray,
          {
            ...item,
            energyConsumed: (res.energy_active_import_register / 1000).toFixed(
              2
            ),

            duration:
              moment.duration(moment(res.timestamp).diff(start)).hours() +
              "hr" +
              " " +
              moment.duration(moment(res.timestamp).diff(start)).minutes() +
              "min",

            ocppCost: (
              item.tariff *
              (res.energy_active_import_register / 1000)
            ).toFixed(2),

            // ocppTransactionId: res.transaction_id,
            ocppTransactionId: transaction_ID,
            ocppUserId: userId,
            userName: userName,
            chargeSessionId: chargeSessionId,
            meterStart: meterStart,
          },
        ]);
      });
  };
  const [showError, setShowError] = useState(false);
  const getAllChargePoint = (chargerData: any) => {
    console.log("live Charger data", chargerData);
    setLoading(true);
    const ChargePoints = Parse.Object.extend("Chargers");

    const parseQuery = new Parse.Query(ChargePoints);
    parseQuery.include("Location");
    parseQuery.include("CP_Vendors");
    parseQuery.include("ConnectorType");
    parseQuery.include("ChargePointOperators");
    parseQuery.limit(1000);

    if (fetchCharger) {
      console.log("chargerData", fetchCharger);

      // Create a subquery to match chargerData with Location objectId
      const Location = Parse.Object.extend("Locations");
      const locationQuery = new Parse.Query(Location);
      locationQuery.equalTo("objectId", fetchCharger);

      // Use matchesQuery to filter Chargers based on Location
      parseQuery.matchesQuery("Location", locationQuery);
      parseQuery.equalTo("isEnabled", true);
    }

    if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
      parseQuery.equalTo("CPO", currentUser.get("CPO"));
    }
    parseQuery
      .find()
      .then((result) => {
        console.log("Result", result);

        if (result.length === 0) {
          setShowError(true);
          setLoading(false);
          return; // Stop further execution
        }
        let chargerArray: any[] = [];

        result.forEach((item, index) => {
          console.log("Item of charger:", item);
          console.log("props.liveOcppData", props.liveOcppData);

          const ocppData = props.liveOcppData?.find(
            (el: any) => el.ocppName === item.get("ChargeId")
          );

          const connector1 = ocppData?.connectors?.find(
            (c: any) => c.connectorNum === 1
          );
          const connector2 = ocppData?.connectors?.find(
            (c: any) => c.connectorNum === 2
          );

          let connectorType = item.get("ConnectorType");
          let vendor = item.get("Vendor");
          let cpo = item.get("CPO");
          let ab = {
            id: item.id,
            serial: item.get("Serial"),
            power: `${item.get("Power")}`,
            chargingRate: `${item.get("ChargingRate")}`,
            location: `${item.get("Location")?.id}`,

            status: item.get("isOCPP")
              ? connector1?.status || "Offline"
              : "Offline",
            connector_1_Status: item.get("isOCPP")
              ? connector1?.status || "Offline"
              : "Offline",
            connector_2_Status: item.get("isOCPP")
              ? connector2?.status || "Offline"
              : "Offline",

            allConnector: props.liveOcppData?.filter(
              (el: any) => el.ocppName === item.get("ChargeId")
            ),
            ocppName: props.liveOcppData?.filter(
              (el: any) => el.ocppName === item.get("ChargeId")
            )[0]?.ocppName,
            isActive: props.liveOcppData?.filter(
              (el: any) => el.ocppName === item.get("ChargeId")
            )[0]?.active,
            connector: {
              id: connectorType?.id ? connectorType.id : "",
              name: connectorType?.get("Name") ? connectorType.get("Name") : "",
            },
            tariff: `${item.get("Cost")}`,
            taxRate: `${item.get("TaxRate")}`,
            user: "-",
            duration: "-",
            connected: "-",
            energyConsumed: "-",
            vendor: {
              id: vendor?.id,
              name: vendor?.get("Name"),
            },
            chargerId: item.get("ChargeId"),
            inclusiveTax: item.get("inclusiveTax") ? "true" : "false",
            cost: item.get("Cost"),
            isEnabled: item.get("isEnabled") ? "true" : "false",
            disabledReason: item.get("DisableReason"),
            cpo: {
              id: cpo?.id,
              name: cpo?.get("Name"),
            },
            isOcpp: item.get("isOCPP") ? "true" : "false",
            // meterStart: liveOcppsChargesession?.map((ele:any)=> ele?.MeterStart)
          };

          // Trasaction id to get meter values and userId to Stop charging
          const liveCC = chargerData
            .map((item: any) => {
              console.log("Item in liveOcppsChargesession:", item); // Log the item here
              return item.serial;
            })
            .includes(ab.serial);

          const matchingSessions = chargerData.filter(
            (item: any) => item.serial === ab.serial
          );

          if (matchingSessions.length > 0) {
            matchingSessions.forEach((session: any) => {
              getOcppData(
                session.transactionId,
                { ...ab, chargingGunId: session.chargingGunId }, // Attach chargingGunId to `ab` for clarity
                session.userId,
                session.userName,
                session.createdAt,
                session.chargeSessionId,
                session.MeterStart
              );
            });
          } else {
            chargerArray.push(ab);
          }
        });
        setShowError(false);
        setAllChargePointData(chargerArray);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  function createData(
    id: string,
    stationName: number,
    type: number,
    city: number,
    access: string,
    operator: number
  ) {
    return {
      id,
      stationName,
      type,
      city,
      access,
      operator,
    };
  }

  const [actionType, setActionType] = useState("");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const openModalCloseCharger = Boolean(anchorEl);
  const id = openModalCloseCharger ? "simple-popover" : undefined;

  const [openRows, setOpenRows] = React.useState<{ [key: string]: boolean }>(
    {}
  );

  const [dualGunChargersData, setDualGunChargers] = useState<any[]>([]);;

 

  const getChargerStatus = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_OCPP_BASE_URL}/active_clients`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch chargers");
      }

      const apiData = await response.json();
      console.log("API Data:", apiData); // ✅ Debug API data

      // Fetch Chargers data from Parse
      const ChargePoints = Parse.Object.extend("Chargers");
      const parseQuery = new Parse.Query(ChargePoints);
      parseQuery.include("Location");
      parseQuery.include("CP_Vendors");
      parseQuery.include("ConnectorType");
      parseQuery.include("ChargePointOperators");
      parseQuery.limit(1000);

      if (fetchCharger) {
        const Location = Parse.Object.extend("Locations");
        const locationQuery = new Parse.Query(Location);
        locationQuery.equalTo("objectId", fetchCharger);
        parseQuery.matchesQuery("Location", locationQuery);
        parseQuery.equalTo("isEnabled", true);
      }

      const parseData = await parseQuery.find();
      console.log("Parse Data:", parseData); // ✅ Debug Parse data

      // Create a map for Charger data from Parse
      const chargerMap = new Map();
      parseData.forEach((item) => {
        let cpo = item.get("CPO");
        chargerMap.set(item.get("ChargeId"), {
          power: item.get("Power"),
          cpo: {
            id: cpo?.id,
            name: cpo?.get("Name"),
          },
          ocppName: item.get("ChargeId"),
          chargerObjID: item.get("ObjectId"),
        });
      });

      console.log("Charger Map:", chargerMap); // ✅ Debug mapped chargers

      // Fetch ChargeSession data for active charging connectors
     
      const sessionQuery = new Parse.Query("ChargeSession");
      // parseQuery.include("User");
      sessionQuery.include("User.Fleet");
      sessionQuery.include("User");
      sessionQuery.include("ChargePoint");
      sessionQuery.equalTo("Live", true); // Only fetch active charging sessions
      const chargeSessions = await sessionQuery.find();

      console.log("Charge Sessions:", chargeSessions); // ✅ Debug ChargeSession data

      const sessionMap = new Map();
      chargeSessions.forEach((session) => {
       
        const chargerId = session.get("ChargePoint")?.get("ChargeId"); // Ensure correct key
        const chargeGunId = session.get("ChargeGunId");
        console.log("chargeGunId", chargeGunId);
        console.log("chargerId", chargerId);

        if (chargerId && chargeGunId) {
          const user = session?.get("User");
          let fleet = null;
          let fleetCost = 0;// Avoid undefined keys
          fleet = user?.get("Fleet");
          fleetCost = fleet?.get("FleetCost") ?? "Fleet has no cost"; 
          console.log("Fleet exists", fleet);
          console.log("Fleet Cost exists", fleetCost);
          
          sessionMap.set(`${chargerId}-${chargeGunId}`, {
            user: session?.get("User")?.get("FullName") || "Unknown",
            ocppUserId: session?.get("User")?.id || "Unknown",
            energy: session.get("energy") || 0,
            chargeSessionId: session?.id,
            cost: fleetCost || "User has no fleet",
            status: session.get("status"), // Add default value
            ocppTransactionId: session.get("TransactionId") || "Unknown", // Add default value
            createdAt: session.get("createdAt") || "Unknown", // Add default value
            meterStart: session.get("MeterStart") || "Unknown", // Add default value
            gunId: session.get("ChargeGunId") || "Unknown", // Add default value
          });
        } else {
          console.warn("Missing chargerId or chargeGunId", {
            chargerId,
            chargeGunId,
          });
        }
      });

      console.log("sessionMap ", sessionMap);

      const processedChargers = await Promise.all(
        apiData
          .filter((charger: any) => {
            if (!chargerMap.has(charger.name)) return false;
      
            const validConnectors = charger.connectors.filter(
              (c: any) => c.connector_num !== 0
            );
      
            const hasConnector1 = validConnectors.some(
              (c: any) => c.connector_num === 1
            );
            const hasConnector2 = validConnectors.some(
              (c: any) => c.connector_num === 2
            );
      
            return hasConnector1 && hasConnector2;
          })
          .flatMap((charger: any) => {
            const chargerDetails = chargerMap.get(charger.name);
      
            return charger.connectors
              .filter((c: any) => c.connector_num !== 0)
              .map(async (c: any) => {
                const key = `${charger.name.trim()}-${c.connector_num}`;
                const chargeSessionData = sessionMap.get(key) || {};
      
                let energyValue = chargeSessionData.energy || null;
                let duration = " ";
                let ocppCost = " ";
      
                if (chargeSessionData?.ocppTransactionId) {
                  try {
                    const ocppResponse = await fetch(
                      `${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${chargeSessionData.ocppTransactionId}`
                    );
                    const res = await ocppResponse.json();
                    energyValue = (res.energy_active_import_register / 1000).toFixed(2);
                    duration =
                    moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).hours() +
                    "hr" +
                    " " +
                    moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).minutes() +
                    "min";
      
                  ocppCost = (
                    res.tariff *
                    (res.energy_active_import_register / 1000)
                  ).toFixed(2);
                  } catch (error) {
                    console.error(`Error fetching OCPP data for ${key}:`, error);
                  }
                }
      
                return {
                  charge_point_serial_number:
                    charger.charger_attributes[0]?.charge_box_serial_number ||  charger.charger_attributes[0]?.charge_point_serial_number || null,
                  power: chargerDetails?.power || null,
                  cpo: chargerDetails?.cpo || {},
                  ocppName: chargerDetails?.ocppName || "",
                  chargerObjID: chargerDetails?.chargerObjID || "",
                  connector_num: c.connector_num,
                  status: chargeSessionData.status || c.status,
                  user: chargeSessionData.user || null,
                  energy: energyValue,
                  duration: duration,
                  ocppCost: ocppCost,
                  cost: chargeSessionData.cost || null,
                  meterStart: chargeSessionData.meterStart || null,
                  gunId : chargeSessionData.gunId || null
                };
              });
          })
      );
      
      // Wait for all async map operations to complete
      const filteredChargers = await Promise.all(processedChargers);
      setDualGunChargers(filteredChargers)
      console.log("Processed Charger Data:", filteredChargers); // ✅ Final check

      return filteredChargers; // Store this in state if needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log("dualGunChargersData",dualGunChargersData);

  useEffect(() => {
    if (fetchCharger) {
      loadSessions();
      getChargerStatus();
    }
  }, [openRows]);

  const handleCharger = (id: string) => {
    console.log("Charger id", id);

    setFetchCharger((prevFetchCharger) => (prevFetchCharger === id ? "" : id));

    setOpenRows((prevOpenRows) => {
      return prevOpenRows[id] ? {} : { [id]: true };
    });
    setDualGunChargers([]);
    setAllChargePointData([]);
  };

  const singleGunChargers = allChargePointData.filter(
    (charger: {
      isEnabled: string;
      location: any;
      allConnector: any[];
      status: string;
    }) => {
      // If the charger is offline and has no connectors, consider it a single-gun charger
      if (charger.status === "Offline" && charger.allConnector.length === 0) {
        return true;
      }
      if (charger.status === "" && charger.allConnector.length === 0) {
        return true;
      }

      // Otherwise, check if it has exactly one valid connector (excluding connector_num 0)
      return charger.allConnector.some(
        (connector) =>
          connector.allConnector.filter(
            (conn: { connector_num: number }) => conn.connector_num !== 0
          ).length === 1
      );
    }
  );

  const dualGunChargers = allChargePointData.filter(
    (charger: {
      isEnabled: string;
      location: any;
      allConnector: any[];
      status: string;
    }) =>
      charger.allConnector.some(
        (connector) =>
          connector.allConnector.some(
            (connector: { connector_num: any }) => connector.connector_num === 1
          ) &&
          connector.allConnector.some(
            (connector: { connector_num: any }) => connector.connector_num === 2
          )
      )
  );
  // const dualGunChargersFormatted = dualGunChargers.flatMap((charger:any) => {
  //   return charger.allConnector.flatMap((connector:any) =>
  //     connector.allConnector
  //       .filter(
  //         (c: { connector_num: number }) => c.connector_num === 1 || c.connector_num === 2
  //       )
  //       .map((c: { connector_num: number; status: string; error_code: string }) => ({
  //         chargerId: charger.chargerId,
  //         ocppName: charger.ocppName,
  //         connectorNum: c.connector_num,
  //         status: c.status,
  //         errorCode: c.error_code,
  //         location: charger.location,
  //         tariff: charger.tariff,
  //         taxRate: charger.taxRate,
  //         cost: charger.cost,
  //         vendor: charger.vendor,
  //         cpo: charger.cpo,
  //       }))
  //   );
  // });

  // console.log("dualGunChargersFormatted",dualGunChargersFormatted);

  console.log("dualGunChargers", dualGunChargers);
  console.log("singleGunChargers", singleGunChargers);

  const [allOcppStatus, setAllOcppStatus] = useState([]);
  const OcppData = () => {
    fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/active_clients`)
      .then((response: any) => response.json())
      .then((res: any) => {
        let ab: any = [];
        res.forEach((el: any) => {
          ab.push({
            id:
              el.charger_attributes[0].charge_box_serial_number ||
              el.charger_attributes[0].charge_point_serial_number,
            status: el.is_active
              ? el.connectors.filter((item: any) => item.connector_num === 1)[0]
                  .status
              : "Offline",
            // status1 :el.is_active
            // ? el?.connectors?.filter((item: any) => item?.connector_num === 2)[0]
            //     ?.status
            // : "Offline"
            // ,
            active: el.is_active,
            ocppName: el.name,
            allConnector: el?.connectors,
          });
        });
        setAllOcppStatus(ab);
      });
  };

  useEffect(() => {
    OcppData();
  }, []);

  function Row(props: {
    [x: string]: any;
    row: ReturnType<typeof createData>;
  }) {
    const { row } = props;
    const [actionOpen, setActionOpen] = React.useState(false);
    const [showAddEditLocationModal, setShowAddEditLocationModal] =
      useState(false);
    const [showAddEditChargerModal, setShowAddEditChargerModal] =
      useState(false);
    const [showRebootChargerModal, setShowRebootChargerModal] = useState(false);
    const [showStopChargerModal, setShowStopChargerModal] = useState(false);
    const [editLocationData, setEditLocationData] = useState({});
    const [editChargerData, setEditChargerData] = useState({});
    const [rebootChargerData, setRebootChargerData] = useState({});
    const [stopChargerData, setStopChargerData] = useState({});

    const handleEditCharger = (item: any) => {
      setEditChargerData(item);
      setShowAddEditChargerModal(true);
    };

    const handleRebootCloseChargerModal = (item: any, id: any, serial: any) => {
      setActionType("Reboot");
      setRebootChargerData([item, id, serial]);
      setShowRebootChargerModal(true);
    };
    const handleStopCloseChargerModal = (
      csId: any,
      name: any,
      transactionId: any,
      userId: any,
      cost: any,
      energy: any,
      time: any,
      range: any
    ) => {
      setActionType("Reboot");
      setStopChargerData([
        csId,
        name,
        transactionId,
        userId,
        cost,
        energy,
        time,
        range,
      ]);
      setShowStopChargerModal(true);
    };

    //  Code For checking location has charger or not if not then show message in UI No charger existing
    const checkLocationHasAnyCharger = allChargePointData.filter(
      (item: { isEnabled: string; location: any }) =>
        item.location === row.id && item?.isEnabled == "true"
    );

    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset", background: "#F6F8FA" } }}
        >
          <TableCell
            style={{ fontFamily: "lexend" }}
            component="th"
            scope="row"
          >
            {row.id}
          </TableCell>
          <TableCell style={{ fontFamily: "lexend" }}>
            {row.stationName}
          </TableCell>
          <TableCell style={{ fontFamily: "lexend" }}>{row.type}</TableCell>
          <TableCell style={{ fontFamily: "lexend" }}>{row.city}</TableCell>
          {/* <TableCell  style={{fontFamily:'lexend'}}>{row.access}</TableCell> */}
          <TableCell style={{ fontFamily: "lexend" }}>
            <div className="flex">
              <span>
                {row.access == "Restricted" && (
                  <div className="flex">
                    <span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00011 8C7.80119 8 7.61043 7.92098 7.46978 7.78033C7.32912 7.63968 7.25011 7.44891 7.25011 7.25C7.25011 7.05109 7.32912 6.86032 7.46978 6.71967C7.61043 6.57902 7.80119 6.5 8.00011 6.5C8.19902 6.5 8.38978 6.57902 8.53044 6.71967C8.67109 6.86032 8.75011 7.05109 8.75011 7.25C8.75011 7.44891 8.67109 7.63968 8.53044 7.78033C8.38978 7.92098 8.19902 8 8.00011 8ZM8.00011 8V9.875M8.00012 1.25C9.75199 2.79993 12.0385 3.60692 14.3751 3.5C14.7153 4.65726 14.8194 5.8711 14.6812 7.06939C14.543 8.26767 14.1654 9.42595 13.5707 10.4754C12.976 11.5248 12.1764 12.444 11.2195 13.1784C10.2625 13.9127 9.16771 14.4472 8.00012 14.75C6.83253 14.4472 5.73773 13.9127 4.78078 13.1784C3.82383 12.444 3.02425 11.5248 2.42957 10.4754C1.83488 9.42595 1.45722 8.26767 1.31903 7.06939C1.18083 5.8711 1.28493 4.65726 1.62512 3.5C3.96177 3.60692 6.24825 2.79993 8.00012 1.25Z"
                          stroke="#C41A1A"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="ml-2" style={{ color: "#C41A1A" }}>
                      Restricted
                    </span>
                  </div>
                )}
              </span>
              {row.access == "Public" && (
                <div className="flex">
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.00012 15.75C7.83253 15.4472 6.73773 14.9127 5.78078 14.1784C4.82383 13.444 4.02425 12.5248 3.42957 11.4754C2.83488 10.426 2.45722 9.26767 2.31903 8.06939C2.18083 6.8711 2.28493 5.65726 2.62512 4.5C4.96177 4.60692 7.24825 3.79993 9.00012 2.25C10.752 3.79993 13.0385 4.60692 15.3751 4.5C15.7324 5.71613 15.829 6.99398 15.6586 8.25M13.5001 16.5L16.0126 14.037C16.1667 13.8875 16.2892 13.7086 16.373 13.511C16.4569 13.3133 16.5002 13.1008 16.5006 12.8862C16.5009 12.6715 16.4582 12.4589 16.3751 12.2609C16.2919 12.063 16.1699 11.8838 16.0164 11.7337C15.703 11.4272 15.2823 11.2553 14.8439 11.2544C14.4055 11.2536 13.9842 11.4239 13.6696 11.7292L13.5016 11.8942L13.3344 11.7292C13.021 11.423 12.6004 11.2511 12.1623 11.2503C11.7241 11.2494 11.3029 11.4197 10.9884 11.7247C10.8342 11.8742 10.7116 12.053 10.6277 12.2507C10.5439 12.4483 10.5004 12.6607 10.5 12.8754C10.4996 13.0901 10.5422 13.3027 10.6253 13.5007C10.7084 13.6986 10.8303 13.8779 10.9839 14.028L13.5001 16.5Z"
                        stroke="#19885A"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2" style={{ color: "#19885A" }}>
                    Public
                  </span>
                </div>
              )}
            </div>
          </TableCell>

          <TableCell>
            <Tooltip title="Edit Location">
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                }}
                onClick={() => {
                  setShowAddEditLocationModal(true);
                  setEditLocationData(row);
                }}
              >
                <svg
                  className="ml-1"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                    stroke="white"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Show Charger">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleCharger(row.id)}
              >
                {openRows[row.id] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        {/* isLoading ? (
          <TableCell
            size="small"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={12}
          >
            <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
              <h1 className="text-center font-lexend text-2xl bg-gray-200 font-bold py-3">
                Loading ....
              </h1>
            </Collapse>
          </TableCell>
        ) :  */}
        {/* {
          showError && (<h1>No Active Charger at this location.</h1>)
        } */}
        {checkLocationHasAnyCharger?.length > 0 && !isLoading ? (
          <TableCell
            size="small"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={12}
          >
            <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
              <TableHead>
               
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                     
                    }}
                  >
                    <p className="px-3">
                      Serial <br /> Number
                    </p>
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingLeft:'33px'
                    }}
                  >
                    Gun ID
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                    
                    }}
                  >
                   
                      Max Power
                   
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingTop:'28px',
                      paddingBottom:'28px',
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingTop:'28px',
                      paddingBottom:'28px',
                    }}
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingTop:'28px',
                      paddingBottom:'28px',
                    }}
                  >
                    Connected
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingTop:'28px',
                      paddingBottom:'28px',
                    }}
                  >
                    Cost
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      width:'80px'
                    }}
                  >
                    Energy Consumed
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingTop:'28px',
                      paddingBottom:'28px',
                    }}
                  >
                    Operator
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingTop:'28px',
                      paddingLeft:'59px',
                      paddingBottom:'28px',
                    }}
                  >
                    User
                  </TableCell>

                  <TableCell
                    style={{
                      fontFamily: "lexend",
                      fontWeight: "600",
                      fontSize: "15px",
                      paddingLeft:'110px',
                      paddingTop:'28px',
                      paddingBottom:'28px',
                    }}
                    align="right"
                  >
                    {" "}
                    Action
                  </TableCell>
               
              </TableHead>

              <Table aria-label="purchases">
                <>
                  {singleGunChargers?.map((item: any) => {
                    return (
                      <TableBody key={item.id}>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'150px' }}
                        >
                          {/* Serial Number */}
                          {item.serial}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'50px' }}
                        >
                          {/* chargingGunId*/}
                          <span className="mb-3">
                            {item?.chargingGunId === "1" ? (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM10.994 5.886C10.911 5.109 9.986 4.726 9.377 5.216L9.293 5.293L7.293 7.293L7.21 7.387C7.07393 7.56237 7.00008 7.77803 7.00008 8C7.00008 8.22197 7.07393 8.43763 7.21 8.613L7.293 8.707L7.387 8.79C7.56237 8.92607 7.77803 8.99992 8 8.99992C8.22197 8.99992 8.43763 8.92607 8.613 8.79L8.707 8.707L9 8.414V14L9.007 14.117C9.03591 14.3601 9.15296 14.5841 9.33597 14.7466C9.51897 14.9091 9.75524 14.9989 10 14.9989C10.2448 14.9989 10.481 14.9091 10.664 14.7466C10.847 14.5841 10.9641 14.3601 10.993 14.117L11 14V6L10.994 5.886Z"
                                  fill="#57585a"
                                />
                              </svg>
                            ) : item.chargingGunId === "2" ? (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM11 5H8L7.883 5.007C7.63995 5.03591 7.41594 5.15296 7.25341 5.33596C7.09088 5.51897 7.00111 5.75524 7.00111 6C7.00111 6.24476 7.09088 6.48103 7.25341 6.66403C7.41594 6.84704 7.63995 6.96409 7.883 6.993L8 7H11V9H9L8.85 9.005C8.37383 9.04078 7.92617 9.24576 7.58797 9.58286C7.24977 9.91996 7.04334 10.3669 7.006 10.843L7 11V13L7.005 13.15C7.04078 13.6262 7.24576 14.0738 7.58286 14.412C7.91996 14.7502 8.36695 14.9567 8.843 14.994L9 15H12L12.117 14.993C12.36 14.9641 12.5841 14.847 12.7466 14.664C12.9091 14.481 12.9989 14.2448 12.9989 14C12.9989 13.7552 12.9091 13.519 12.7466 13.336C12.5841 13.153 12.36 13.0359 12.117 13.007L12 13H9V11H11L11.15 10.995C11.6262 10.9592 12.0738 10.7542 12.412 10.4171C12.7502 10.08 12.9567 9.63305 12.994 9.157L13 9V7L12.995 6.85C12.9592 6.37383 12.7542 5.92617 12.4171 5.58797C12.08 5.24977 11.6331 5.04334 11.157 5.006L11 5Z"
                                  fill="#57585a"
                                />
                              </svg>
                            ) : (
                              "-"
                            )}
                          </span>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'118px' }}
                        >
                          {item.power}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'120px' }}
                        >
                          {item.status === "Available" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                    stroke="#529fec"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span
                                className="font-lexend"
                                style={{ color: "#529fec" }}
                              >
                                Online
                              </span>{" "}
                            </span>
                          )}

                          {item.status === "Charging" && (
                            <>
                              <span className="flex items-center">
                                {" "}
                                <span>
                                  <svg
                                    className="mr-2"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.78906 2.75C1.78906 2.35218 1.9471 1.97064 2.2284 1.68934C2.50971 1.40804 2.89124 1.25 3.28906 1.25H11.5391C11.9369 1.25 12.3184 1.40804 12.5997 1.68934C12.881 1.97064 13.0391 2.35218 13.0391 2.75V3.125C13.0391 3.22446 13.0786 3.31984 13.1489 3.39017C13.2192 3.46049 13.3146 3.5 13.4141 3.5C13.5135 3.5 13.6089 3.53951 13.6792 3.60983C13.7496 3.68016 13.7891 3.77554 13.7891 3.875V6.125C13.7891 6.22446 13.7496 6.31984 13.6792 6.39016C13.6089 6.46049 13.5135 6.5 13.4141 6.5C13.3146 6.5 13.2192 6.53951 13.1489 6.60983C13.0786 6.68016 13.0391 6.77554 13.0391 6.875V7.25C13.0391 7.64782 12.881 8.02935 12.5997 8.31066C12.3184 8.59196 11.9369 8.75 11.5391 8.75H8.16406M3.28906 12.5V10.25M1.78906 7.25V5.375M4.78906 7.25V5.375M1.03906 7.25H5.53906V8.75C5.53906 9.14782 5.38103 9.52935 5.09972 9.81066C4.81842 10.092 4.43689 10.25 4.03906 10.25H2.53906C2.14124 10.25 1.75971 10.092 1.4784 9.81066C1.1971 9.52935 1.03906 9.14782 1.03906 8.75V7.25Z"
                                      stroke="#1AC47D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>{" "}
                                <span
                                  className="font-lexend"
                                  style={{ color: "#1AC47D" }}
                                >
                                  Charging
                                </span>{" "}
                              </span>
                            </>
                          )}

                          {item.status === "Preparing" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-1"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                    stroke="#52ec6c"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span
                                className="font-lexend"
                                style={{ color: "#52ec6c" }}
                              >
                                Connected
                              </span>{" "}
                            </span>
                          )}
                          {item.status === "Finishing" && (
                            <span
                              className="font-lexend"
                              style={{ color: "#fd9610" }}
                            >
                              Finishing
                            </span>
                          )}
                          {item.status === "Offline" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.1625 13.2997C9.6009 13.4765 9.0093 13.5376 8.42344 13.4793C7.83759 13.4209 7.26964 13.2444 6.75395 12.9603C6.23826 12.6763 5.78553 12.2906 5.42313 11.8266C5.06072 11.3626 4.79618 10.8299 4.64549 10.2607C4.44852 9.51812 4.45141 8.73662 4.65385 7.99547C4.8563 7.25432 5.2511 6.57988 5.79824 6.0405L7.33874 4.5L12.7125 9.87375M3 15L5.625 12.375M11.25 3L8.625 5.625M15 6.75L12.375 9.375M12 12L15 15M15 12L12 15"
                                    stroke="#C99456"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span
                                className="font-lexend"
                                style={{ color: "#C99456" }}
                              >
                                Offline
                              </span>{" "}
                            </span>
                          )}
                          {item.status === "Faulted" && (
                            <span className="flex items-center">
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.55 14.733C9.8012 14.9687 9.0124 15.0501 8.23126 14.9724C7.45011 14.8946 6.69286 14.6592 6.00527 14.2804C5.31768 13.9017 4.71404 13.3874 4.23083 12.7688C3.74763 12.1501 3.39491 11.4399 3.19398 10.681C2.93136 9.69083 2.93521 8.64883 3.20514 7.66063C3.47506 6.67243 4.00146 5.77317 4.73098 5.054L6.78498 3L13.95 10.165M1 17L4.5 13.5M12 1L8.5 4.5M17 6L13.5 9.5M13 13L17 17M17 13L13 17"
                                    stroke="#981818"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="text-red-900 font-lexend">
                                Faulted
                              </span>
                            </span>
                          )}
                          {item.status === "SuspendedEV" && (
                            <span className="text-red-900 font-lexend">
                              Suspended EV
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'88px' }}
                        >
                          {item.duration}
                        </TableCell>

                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'100px' }}
                        >
                          {item.status
                            ? item.status === "Charging" ||
                              item.status === "Preparing"
                              ? "Yes"
                              : "No"
                            : "-"}
                        </TableCell>

                        <TableCell
                        align="center"
                          style={{
                            fontFamily: "lexend",
                            fontSize: "15px",
                            width:'60px'
                          
                          }}
                        >
                          {item?.status === "Charging"
                            ? item?.meterStart === "0"
                              ? (item?.cost * item?.energyConsumed).toFixed(2)
                              : (
                                  (item?.energyConsumed -
                                    item?.meterStart / 1000) *
                                  item?.cost
                                ).toFixed(2)
                            : "-"}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px", width:'100px' }}
                        >
                          {item?.status === "Charging"
                            ? item?.meterStart === "0"
                              ? item?.energyConsumed
                              : (
                                  item?.energyConsumed -
                                  item?.meterStart / 1000
                                ).toFixed(2)
                            : "-"}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item.cpo.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: "lexend",
                            fontSize: "15px",
                            width: "80px",
                            textAlign:'center'
                          }}
                        >
                          {item.userName}
                        </TableCell>
                        {item.vendor !== "EO" ? (
                          <TableCell align="center">
                            <span>
                              <Tooltip title="Stop Charger">
                                <button
                                  style={{
                                    background:
                                      item.status !== "Charging"
                                        ? "#c8c2c2"
                                        : "#C41A1A",
                                    width: "35px",
                                    height: "35px",
                                    marginRight: "5px",
                                    borderRadius: "50%",
                                    border:
                                      item.status !== "Charging"
                                        ? "3px solid #858585 "
                                        : "3px solid #9E1212 ",
                                  }}
                                  onClick={() =>
                                    handleStopCloseChargerModal(
                                      item.chargeSessionId,
                                      item.ocppName,
                                      item.ocppTransactionId,
                                      item.ocppUserId,
                                      Math.round(item.ocppCost),
                                      item.energyConsumed,
                                      20,
                                      10
                                    )
                                  }
                                >
                                  <svg
                                    className="ml-2"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.5 3.5L3.5 10.5M3.5 3.5L10.5 10.5"
                                      stroke="white"
                                      stroke-width="1.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                              <Tooltip title="Reboot Charger">
                                <button
                                  style={{
                                    background: "#1A72C4",
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%",
                                    border: "3px solid  #155EA1",
                                  }}
                                  onClick={() =>
                                    handleRebootCloseChargerModal(
                                      item.ocppName,
                                      item?.chargerObjID,
                                      item?.serial
                                    )
                                  }
                                >
                                  <svg
                                    className="ml-2"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.6375 6.4166C11.49 5.2912 10.9374 4.25814 10.0831 3.5108C9.22885 2.76346 8.1315 2.35307 6.99647 2.35647C5.86145 2.35986 4.76657 2.7768 3.91679 3.52924C3.06701 4.28167 2.52058 5.31802 2.3798 6.44428C2.23901 7.57055 2.51353 8.70951 3.15195 9.64798C3.79037 10.5864 4.74893 11.2601 5.84819 11.5427C6.94745 11.8254 8.11206 11.6978 9.124 11.1837C10.1359 10.6696 10.9258 9.8044 11.3458 8.74994M11.6375 11.6666V8.74994H8.72085"
                                      stroke="white"
                                      stroke-width="1.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                            </span>
                          </TableCell>
                        ) : (
                          <TableCell align="center">-</TableCell>
                        )}

                        <TableCell>
                          <Tooltip title="Edit Charger">
                            <button
                              style={{
                                background: "#1AC47D",
                                width: "35px",
                                height: "35px",
                                padding: "5px",
                                borderRadius: "50%",
                                border: "3px solid #1AAD70",
                              }}
                              onClick={() => handleEditCharger(item)}
                            >
                              <svg
                                className="ml-1"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                                  stroke="white"
                                  stroke-width="1.33333"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </TableCell>

                        <AddEditChargerModal
                          show={showAddEditChargerModal}
                          handleClose={() => setShowAddEditChargerModal(false)}
                          data={editChargerData}
                          type="Edit"
                        />

                        {/* Close Charger Modal */}
                        <CloseChargerModal
                          show={showRebootChargerModal}
                          handleClose={() => setShowRebootChargerModal(false)}
                          data={rebootChargerData}
                          type={actionType}
                        />

                        <StopChargerModal
                          show={showStopChargerModal}
                          handleClose={() => setShowStopChargerModal(false)}
                          data={stopChargerData}
                          type={actionType}
                        />
                      </TableBody>
                    );
                  })}

                  {
                    dualGunChargersData?.map((item:any)=>{
                      return(
                        <TableBody key={item?.connector_num}>
                        <TableCell
                          style={{
                            fontFamily: "lexend",
                            fontSize: "15px",
                           width:'150px'
                          }}
                        >
                          {/* Serial Number */}
                          {item?.charge_point_serial_number}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {/* chargingGunId*/}
                          {item?.connector_num === 1 ? (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM10.994 5.886C10.911 5.109 9.986 4.726 9.377 5.216L9.293 5.293L7.293 7.293L7.21 7.387C7.07393 7.56237 7.00008 7.77803 7.00008 8C7.00008 8.22197 7.07393 8.43763 7.21 8.613L7.293 8.707L7.387 8.79C7.56237 8.92607 7.77803 8.99992 8 8.99992C8.22197 8.99992 8.43763 8.92607 8.613 8.79L8.707 8.707L9 8.414V14L9.007 14.117C9.03591 14.3601 9.15296 14.5841 9.33597 14.7466C9.51897 14.9091 9.75524 14.9989 10 14.9989C10.2448 14.9989 10.481 14.9091 10.664 14.7466C10.847 14.5841 10.9641 14.3601 10.993 14.117L11 14V6L10.994 5.886Z"
                                  fill="#57585a"
                                />
                              </svg>
                            ) : item?.connector_num === 2 ? (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM11 5H8L7.883 5.007C7.63995 5.03591 7.41594 5.15296 7.25341 5.33596C7.09088 5.51897 7.00111 5.75524 7.00111 6C7.00111 6.24476 7.09088 6.48103 7.25341 6.66403C7.41594 6.84704 7.63995 6.96409 7.883 6.993L8 7H11V9H9L8.85 9.005C8.37383 9.04078 7.92617 9.24576 7.58797 9.58286C7.24977 9.91996 7.04334 10.3669 7.006 10.843L7 11V13L7.005 13.15C7.04078 13.6262 7.24576 14.0738 7.58286 14.412C7.91996 14.7502 8.36695 14.9567 8.843 14.994L9 15H12L12.117 14.993C12.36 14.9641 12.5841 14.847 12.7466 14.664C12.9091 14.481 12.9989 14.2448 12.9989 14C12.9989 13.7552 12.9091 13.519 12.7466 13.336C12.5841 13.153 12.36 13.0359 12.117 13.007L12 13H9V11H11L11.15 10.995C11.6262 10.9592 12.0738 10.7542 12.412 10.4171C12.7502 10.08 12.9567 9.63305 12.994 9.157L13 9V7L12.995 6.85C12.9592 6.37383 12.7542 5.92617 12.4171 5.58797C12.08 5.24977 11.6331 5.04334 11.157 5.006L11 5Z"
                                  fill="#57585a"
                                />
                              </svg>
                            ) : (
                              "-"
                            )}
                        </TableCell>
                        <TableCell
                         align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item?.power}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'100px' }}
                        >
                          {item?.status ===
                            "Available" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                    stroke="#529fec"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span
                                className="font-lexend"
                                style={{ color: "#529fec" }}
                              >
                                Online
                              </span>{" "}
                            </span>
                          )}
  
                          {item?.status ===
                            "Charging" && (
                            <>
                              <span className="flex items-center">
                                {" "}
                                <span>
                                  <svg
                                    className="mr-2"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.78906 2.75C1.78906 2.35218 1.9471 1.97064 2.2284 1.68934C2.50971 1.40804 2.89124 1.25 3.28906 1.25H11.5391C11.9369 1.25 12.3184 1.40804 12.5997 1.68934C12.881 1.97064 13.0391 2.35218 13.0391 2.75V3.125C13.0391 3.22446 13.0786 3.31984 13.1489 3.39017C13.2192 3.46049 13.3146 3.5 13.4141 3.5C13.5135 3.5 13.6089 3.53951 13.6792 3.60983C13.7496 3.68016 13.7891 3.77554 13.7891 3.875V6.125C13.7891 6.22446 13.7496 6.31984 13.6792 6.39016C13.6089 6.46049 13.5135 6.5 13.4141 6.5C13.3146 6.5 13.2192 6.53951 13.1489 6.60983C13.0786 6.68016 13.0391 6.77554 13.0391 6.875V7.25C13.0391 7.64782 12.881 8.02935 12.5997 8.31066C12.3184 8.59196 11.9369 8.75 11.5391 8.75H8.16406M3.28906 12.5V10.25M1.78906 7.25V5.375M4.78906 7.25V5.375M1.03906 7.25H5.53906V8.75C5.53906 9.14782 5.38103 9.52935 5.09972 9.81066C4.81842 10.092 4.43689 10.25 4.03906 10.25H2.53906C2.14124 10.25 1.75971 10.092 1.4784 9.81066C1.1971 9.52935 1.03906 9.14782 1.03906 8.75V7.25Z"
                                      stroke="#1AC47D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>{" "}
                                <span
                                  className="font-lexend"
                                  style={{ color: "#1AC47D" }}
                                >
                                  Charging
                                </span>{" "}
                              </span>
                            </>
                          )}
  
                          {item?.status ===
                            "Preparing" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                    stroke="#52ec6c"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span style={{ color: "#52ec6c" }}>Connected</span>{" "}
                            </span>
                          )}
                          {item?.status ===
                            "Finishing" && (
                            <span style={{ color: "#fd9610" }}>Finishing</span>
                          )}
                          {item?.status ===
                            "Offline" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.1625 13.2997C9.6009 13.4765 9.0093 13.5376 8.42344 13.4793C7.83759 13.4209 7.26964 13.2444 6.75395 12.9603C6.23826 12.6763 5.78553 12.2906 5.42313 11.8266C5.06072 11.3626 4.79618 10.8299 4.64549 10.2607C4.44852 9.51812 4.45141 8.73662 4.65385 7.99547C4.8563 7.25432 5.2511 6.57988 5.79824 6.0405L7.33874 4.5L12.7125 9.87375M3 15L5.625 12.375M11.25 3L8.625 5.625M15 6.75L12.375 9.375M12 12L15 15M15 12L12 15"
                                    stroke="#C99456"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span style={{ color: "#C99456" }}>Offline</span>{" "}
                            </span>
                          )}
                          {item?.status ===
                            "Faulted" && (
                            <span className="flex items-center">
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.55 14.733C9.8012 14.9687 9.0124 15.0501 8.23126 14.9724C7.45011 14.8946 6.69286 14.6592 6.00527 14.2804C5.31768 13.9017 4.71404 13.3874 4.23083 12.7688C3.74763 12.1501 3.39491 11.4399 3.19398 10.681C2.93136 9.69083 2.93521 8.64883 3.20514 7.66063C3.47506 6.67243 4.00146 5.77317 4.73098 5.054L6.78498 3L13.95 10.165M1 17L4.5 13.5M12 1L8.5 4.5M17 6L13.5 9.5M13 13L17 17M17 13L13 17"
                                    stroke="#981818"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="text-red-900">Faulted</span>
                            </span>
                          )}
                          {item?.status ===
                            "SuspendedEV" && (
                            <span className="text-red-900">Suspended EV</span>
                          )}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item?.duration}
                        </TableCell>
  
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item?.status
                            ? item?.status ===
                                "Charging" ||
                              item?.status ===
                                "Preparing"
                              ? "Yes"
                              : "No"
                            : "-"}
                        </TableCell>
  
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item?.status === "Charging"
                            ? item?.meterStart === 0
                              ? (
                                  item?.cost *
                                  item?.energy
                                ).toFixed(2)
                              : (
                                  (item?.energy -
                                    item?.meterStart / 1000) *
                                    item?.cost
                                ).toFixed(2)
                            : "-"}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px",width:'100px' }}
                        >
                          {item?.status === "Charging"
                            ? item?.meterStart === 0
                              ? item?.energy
                              : (
                                item?.energy -
                                item?.meterStart / 1000
                                ).toFixed(2)
                            : "-"}
                        </TableCell>
                        <TableCell
                        align="center"
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item?.cpo?.name}
                        </TableCell>
  
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px",  width: "80px",
                            textAlign:'center', }}
                        >
                          {item?.user}
                        </TableCell>
                        {item?.vendor !== "EO" ? (
                          <TableCell align="center">
                            <span>
                              <Tooltip title="Close Charger">
                                <button
                                  style={{
                                    background:
                                      item?.status !==
                                      "Charging"
                                        ? "#c8c2c2"
                                        : "#C41A1A",
                                    width: "35px",
                                    height: "35px",
                                    marginRight: "5px",
                                    borderRadius: "50%",
                                    border:
                                    item?.status !== "Charging"
                                        ? "3px solid #858585 "
                                        : "3px solid #9E1212 ",
                                  }}
                                  onClick={() =>
                                    handleStopCloseChargerModal(
                                      item?.chargeSessionId,
                                      item?.ocppName,
                                      item?.ocppTransactionId,
                                      item?.ocppUserId,
                                      Math.round(item?.ocppCost),
                                      item?.energy,
                                      20,
                                      10
                                    )
                                  }
                                >
                                  <svg
                                    className="ml-2"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.5 3.5L3.5 10.5M3.5 3.5L10.5 10.5"
                                      stroke="white"
                                      stroke-width="1.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                              <Tooltip title="Reboot Charger">
                                <button
                                  style={{
                                    background: "#1A72C4",
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%",
                                    border: "3px solid  #155EA1",
                                  }}
                                  onClick={() =>
                                    handleRebootCloseChargerModal(
                                      item?.ocppName,
                                      item?.id,
                                      item?.serial
                                    )
                                  }
                                >
                                  <svg
                                    className="ml-2"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.6375 6.4166C11.49 5.2912 10.9374 4.25814 10.0831 3.5108C9.22885 2.76346 8.1315 2.35307 6.99647 2.35647C5.86145 2.35986 4.76657 2.7768 3.91679 3.52924C3.06701 4.28167 2.52058 5.31802 2.3798 6.44428C2.23901 7.57055 2.51353 8.70951 3.15195 9.64798C3.79037 10.5864 4.74893 11.2601 5.84819 11.5427C6.94745 11.8254 8.11206 11.6978 9.124 11.1837C10.1359 10.6696 10.9258 9.8044 11.3458 8.74994M11.6375 11.6666V8.74994H8.72085"
                                      stroke="white"
                                      stroke-width="1.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                            </span>
                          </TableCell>
                        ) : (
                          <TableCell align="center">-</TableCell>
                        )}
  
                        <TableCell>
                          <Tooltip title="Edit Charger">
                            <button
                              style={{
                                background: "#1AC47D",
                                width: "35px",
                                height: "35px",
                                padding: "5px",
                                borderRadius: "50%",
                                border: "3px solid #1AAD70",
                              }}
                              onClick={() =>
                                handleEditCharger(item)
                              }
                            >
                              <svg
                                className="ml-1"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                                  stroke="white"
                                  stroke-width="1.33333"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </TableCell>
  
                        {showAddEditChargerModal && (
                          <AddEditChargerModal
                            show={showAddEditChargerModal}
                            handleClose={() => setShowAddEditChargerModal(false)}
                            data={editChargerData}
                            type="Edit"
                          />
                        )}
  
                        {/* Close Charger Modal */}
                        <CloseChargerModal
                          show={showRebootChargerModal}
                          handleClose={() => setShowRebootChargerModal(false)}
                          data={rebootChargerData}
                          type={actionType}
                        />
  
                        <StopChargerModal
                          show={showStopChargerModal}
                          handleClose={() => setShowStopChargerModal(false)}
                          data={stopChargerData}
                          type={actionType}
                        />
                      </TableBody>
                      )
                    })
                  }
                  
                </>
              </Table>
            </Collapse>
          </TableCell>
        ) : (
          <TableCell
            size="small"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={12}
          >
            <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
              <h1 className="text-center font-lexend text-2xl bg-gray-200 font-bold py-3">
                {/* No Active Charger Available At This Location */}
                {showError && fetchCharger ? "No Charger" : "Loading"}

                {/* <h1>Loading</h1> */}
              </h1>
            </Collapse>
          </TableCell>
        )}
        <AddEditModal
          show={showAddEditLocationModal}
          handleClose={() => setShowAddEditLocationModal(false)}
          type="Edit"
          liveOcppData={allOcppStatus}
          data={editLocationData}
          refreshLocation={() => console.log("Hey")}
          allChargePointData={allChargePointData}
        />
      </React.Fragment>
    );
  }

  const [collapseID, setCollapseID] = useState("");
  // const handleCharger = (id:any) => {
  //   console.log("clicked ",id);
  //   setCollapseID(id)
  //   // alert("trigger open drop")
  //   setOpen((prev)=> !prev);

  //   // setIsFilterVisible((prev: boolean) => !prev);
  // };

  console.log("allChargePointData", allChargePointData);
  console.log("row props", props);
  return (
    <div className="databaseTable">
      {/* <div className="title">All Locations ({dataRow.length})</div> */}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead className="tHead">
            <TableRow>
              <TableCell className="tCell">ID</TableCell>
              <TableCell className="tCell">Name</TableCell>
              <TableCell className="tCell">Type</TableCell>
              <TableCell className="tCell">City</TableCell>

              <TableCell className="tCell">Access</TableCell>

              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.loading ? (
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="success" />
              </Box>
            ) : (
              dataRow.length &&
              dataRow?.map((row: any) => {
                return <Row key={row.name} row={row} />;
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default DatabaseTable;
