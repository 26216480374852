import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

interface PropTypes {
  show: boolean;
  handleClose: any;
  data: any;
  type: string;
}

export default function StopChargerModal(props: PropTypes) {
  const data = props?.data;
  console.log("Close Data", data);
  const { name, transactionId } = props?.data;
  // Emergency Stop Function to stop charger emergency
  const handleStopOcppCharging = async () => {
    if (
      !window.confirm(
        `Are you sure you want to stop the charging session ${name} ?`
      )
    )
      return;

    try {
      const url = `${
        process.env.REACT_APP_OCPP_BASE_URL
      }/emergency_stop?cp_name=${encodeURIComponent(
        name
      )}&transaction=${encodeURIComponent(transactionId)}`;

      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      alert("Charger Has Been Stop");
    }
  };

  return (
    <div>
      <Modal
        open={props?.show}
        onClose={props?.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "55%" }}>
          <div
            style={{
              background: "#c41a1aac",
              border: "1px solid #9e121254",
              marginBottom: "10px",
            }}
            className="flex justify-between items-center"
          >
            <h1 className="font-lexend text-xl font-semibold py-3 pl-4 text-white">
              Stop Charger
            </h1>

            <span className="mr-4 cursor-pointer" onClick={props?.handleClose}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 8L12 12M12 8L8 12M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <span className="font-lexend text-xl m-4">
            Are you sure you want to Stop the{" "} charge session on 
            <span className="font-bold font-lexend">{props?.data[1]}</span>{" "}
            charger ?{" "}
          </span>
          <div id="parent-modal-description">
            <button
              className="flex justify-around ml-4 mb-4 text-white items-center px-5 py-2 rounded-full mr-3 mt-4"
              style={{ background: "#c63333", border: "2px solid #9e2424" }}
              onClick={() => handleStopOcppCharging()}
            >
              <span className="font-lexend tracking-wide">Stop </span>
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
