// import { configureStore } from "@reduxjs/toolkit";
// import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import { ConnectorTypeSlice } from "./features/connectorTypeSlice";
// import { VendorSlice } from "./features/vendorsSlice";
// import { RoleSlice } from "./features/roleSlice";
// import allCpoSlice from "./features/allCpoSlice";
// import { LoginSlice } from "./features/loginSlice";
// export const store = configureStore({
//   reducer: {
//     connectors: ConnectorTypeSlice.reducer,
//     vendors: VendorSlice.reducer,
//     assginedRoles: RoleSlice.reducer,
//     cpo: allCpoSlice,
//     // login: LoginSlice.reducer, 
//   },
// });

// export const useAppDispatch: () => typeof store.dispatch = useDispatch;
// export const useAppSelector: TypedUseSelectorHook<
//   ReturnType<typeof store.getState>
// > = useSelector;

import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ConnectorTypeSlice } from "./features/connectorTypeSlice";
import { VendorSlice } from "./features/vendorsSlice";
import { RoleSlice } from "./features/roleSlice";
import allCpoSlice from "./features/allCpoSlice";
import { LoginSlice } from "./features/loginSlice"; // Ensure this is included
import storageSession from 'redux-persist/lib/storage/session';

import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
// Redux Persist configuration
const persistConfig = {
  key: "root",
  storage: storageSession, // Change to `sessionStorage` if you want it to persist for the session only
};

// Define the rootReducer with actual reducers
const rootReducer = combineReducers({
  connectors: ConnectorTypeSlice.reducer,
  vendors: VendorSlice.reducer,
  assginedRoles: RoleSlice.reducer,
  cpo: allCpoSlice,
  login: LoginSlice.reducer, // Don't forget to include the login slice
});

// Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
export const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor
export const persistor = persistStore(store);

// Typed hooks for dispatch and selector
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
