import { initializeParse } from "@parse/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Home from "./pages/home";
import Login from "./pages/login"; 
import Users from "./pages/user";
import StationMap from "./pages/stationMap";
import Report from "./pages/reports";
import Payout from "./pages/report";
import Invoice from "./pages/invoice";
import StationList from "./pages/station";
import ChargeSession from "./pages/chargeSession";
import Transaction from "./pages/transaction";
import Revenue from "./pages/revenue";
import RevenueProjection from "./pages/projection analytics";
import EnergyProjection from "./pages/projection consumption";
import Ev from "./pages/electricVehicles";
import { Provider } from "react-redux";
import { store,persistor  } from "./store/store";
import Vendors from "./pages/vendors";
import Energy from "./pages/energyConsumption";
import LoadManagement from "./pages/LoadMangement";
import Bookings from "./pages/bookings";
import ProtectedRoute from "./utils/protectedRoute";
import PromoCode from "./pages/promocode";
import PushNotification from "./pages/pushNotification";
import { PersistGate } from "redux-persist/integration/react";
import Cpo from "./pages/cpo";
import Roaming from "./pages/roaming";
import AssignRoles from "./pages/assignRoles";
import Fleet from "./pages/fleet";
initializeParse(
  "https://parseapi.back4app.com/",
  `${process.env.REACT_APP_APPLICATION_ID}`,
  `${process.env.REACT_APP_JAVASCRIPT_KEY}`
);
function App() { 
  return (
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
       <BrowserRouter>
        <Routes>
          <Route path="" element={<Login />} />

          <Route path="/">
            <Route path="home" element={<ProtectedRoute Component={Home} />} />
           
            <Route
              path="fleet"
              element={<ProtectedRoute Component={Fleet} />}
            />
            <Route
              path="charge-session"
              element={<ProtectedRoute Component={ChargeSession} />}
            />
            <Route
              path="station-list"
              element={<ProtectedRoute Component={StationList} />}
            />

            <Route
              path="station-map"
              element={<ProtectedRoute Component={StationMap} />}
            />
            <Route
              path="transaction"
              element={<ProtectedRoute Component={Transaction} />}
            />
            {/* Reports */}
            <Route
              path="reports"
              element={<ProtectedRoute Component={Report} />}
            />
            {/* PAYOUT */}
            <Route
              path="payout"
              element={<ProtectedRoute Component={Payout} />}
            />
            {/* Invoice */}
            <Route
              path="invoice"
              element={<ProtectedRoute Component={Invoice} />}
            />

            <Route path="Ev's" element={<ProtectedRoute Component={Ev} />} />
            <Route
              path="Vendors"
              element={<ProtectedRoute Component={Vendors} />}
            />
            <Route
              path="revenue"
              element={<ProtectedRoute Component={Revenue} />}
            />

            <Route
              path="revenue-projection"
              element={<ProtectedRoute Component={RevenueProjection} />}
            />

            <Route
              path="energy-consumption-projection"
              element={<ProtectedRoute Component={EnergyProjection} />}
            />

            <Route
              path="energy-consumption"
              element={<ProtectedRoute Component={Energy} />}
            />
            <Route
              path="load-management"
              element={<ProtectedRoute Component={LoadManagement} />}
            />
            <Route
              path="bookings"
              element={<ProtectedRoute Component={Bookings} />}
            />
            <Route
              path="promocode"
              element={<ProtectedRoute Component={PromoCode} />}
            />
            <Route
              path="push-notification"
              element={<ProtectedRoute Component={PushNotification} />}
            />
            <Route
              path="assign-roles"
              element={<ProtectedRoute Component={AssignRoles} />}
            />
            <Route
              path="roaming"
              element={<ProtectedRoute Component={Roaming} />}
            />
            <Route path="cpo" element={<ProtectedRoute Component={Cpo} />} />

            <Route path="users" element={<ProtectedRoute Component={Users} />} />
            {/* <Route path="products">
              <Route index element={<List />} />
              {/* <Route path=":productId" element={<Single />} /> */}
            {/* </Route> */}
          </Route>
        </Routes>
      </BrowserRouter>
       </PersistGate>
     
    </Provider>
  );
}

export default App;
