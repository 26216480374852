import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AddEditChargerModal from "../addEditCharger/AddEditChargerModal";
import {
  FormControl,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogContentText,
  FormHelperText,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { State, City, Country } from "country-state-city";
import "./addEdit.scss";
import Select from "@mui/material/Select";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../store/store";
import { AddLocationSchema } from "../../../ValidationSchema/ValidationSchema";
import { z } from "zod";
interface PropTypes {
  show: boolean;
  handleClose: any;
  type: string;
  data: any;
  liveOcppData: any;
  refreshLocation: any;
  allChargePointData: any;
}

interface Values {
  id: string;
  stationName: string;
  address: string;
  description: string;
  amenities: string[];
  connectorType: string[];
  city: string;
  state: string;
  isoCode: string;
  country: string;
  type: string;
  lat: string;
  long: string;
  openingTime: any;
  closingTime: any;
  daysOpen: string;
  chargingTariff: string;
  electricityTariff: string;
  currency: string;
  tax: string;
  access: string;
  authType: string;
  paymentReq: string;
  modelType: string;
  isEnabled: string;
  revenueAmount: string;
  revenuePercent: string;
  rentalAmount: string;
}

function AddEditModal(props: PropTypes) {
  // Function to get all Countries
  console.log("Edit Location",props);
  
  const data = Country.getAllCountries();

  // Function to get all state list of india
  const allIndianStates = State.getStatesOfCountry("IN");

  const initialValues = {
    id: "",
    stationName: "",
    address: "",
    description: "",
    amenities: [],
    connectorType: [],
    city: "",
    state: "",
    isoCode: "",
    country: "",
    type: "",
    lat: "",
    long: "",
    openingTime: "",
    closingTime: "",
    daysOpen: "",
    chargingTariff: "",
    electricityTariff: "",
    currency: "₹",
    tax: "",
    access: "",
    authType: "",
    paymentReq: "",
    modelType: "",
    isEnabled: "",
    revenueAmount: "",
    revenuePercent: "",
    rentalAmount: "",
  };
  const [values, setValues] = React.useState<Values>(initialValues);
  // Selected state from state list ISO code
  const selectedState = values?.isoCode;
  // Get cities of selected state
  const citiesOfSelectedState = City.getCitiesOfState("IN", selectedState);

  const [addEditChargerData, setAddEditChargerData] = useState({
    loc_id: "",
    id: "",
    serial: "",
    power: "",
    status: "",
    connector: {
      id: "",
      name: "",
    },
    location: "",
    isEnabled: "true",
    disabledReason: "",
    user: "",
    duration: "",
    connected: "",
    energyConsumed: "",
    vendor: {
      id: "",
      name: "",
    },
    chargerId: "",
    tariff: "",
    taxRate: "",
    inclusiveTax: "",
    currentType: "",
    cpo: {
      id: "",
      name: "",
    },
    isOcpp: "true",
    chargingRate: "",
  });

  useEffect(() => {
    if (!props.show) return;
    setValues(props.data);
  }, [props.show]);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showAddEditChargerModal, setShowAddEditChargerModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [LocationID, setLocationId] = useState("");

  // Validation code start
  type FormErrors = Partial<Record<keyof Values, string>>;
  const [amenitiesError, setAmenitiesError] = useState(false);
  const [showConnectorError, setConnectorError] = useState(false);
  const [showLocationTypeError, setLocationTypeConnectorError] =
    useState(false);
  const [errors, setErrors] = useState<FormErrors>({
    id: "",
    stationName: "",
    address: "",
    description: "",
    state: "",
    lat: "",
    long: "",
    openingTime: "",
    closingTime: "",
    access: "",
    modelType: "",
    electricityTariff: "",
  });

  type AddLocationSchemaKeys = keyof typeof AddLocationSchema.shape;

  const validateField = (
    name: AddLocationSchemaKeys,
    value: string
  ): string => {
    const partialSchema = z.object({
      [name]: AddLocationSchema.shape[name],
    });
    try {
      partialSchema.parse({ [name]: value });
      return "";
    } catch (e) {
      if (e instanceof z.ZodError) {
        return e.errors[0]?.message || "Invalid input";
      }
      return "Invalid input";
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name in AddLocationSchema.shape) {
      const error = validateField(name as AddLocationSchemaKeys, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = (): boolean => {
    let valid = true;
    const newErrors: FormErrors = {};
    for (const key in values) {
      const field = key as keyof Values;
      if (field in AddLocationSchema.shape) {
        const error = validateField(
          field as AddLocationSchemaKeys,
          values[field] as string
        );
        if (error) valid = false;
        newErrors[field] = error;
      }
    }
    setErrors(newErrors);
    return valid;
  };

  const submitAddLocation = () => {
    setAmenitiesError(true);
    setConnectorError(true);
    if (values?.type !== "") {
      setLocationTypeConnectorError(false);
    } else {
      setLocationTypeConnectorError(true);
    }
    if (validateForm()) {
      setUpdateLoading(true);
      let myNewObject: Parse.Object = new Parse.Object("Locations");
      myNewObject.set("Name", values.stationName);
      myNewObject.set("Address", values.address);
      myNewObject.set("City", values.city);
      myNewObject.set("State", values.state);
      myNewObject.set("LocationType", values.type);
      myNewObject.set("Description", values.description);
      myNewObject.set("Amenities", values.amenities);
      myNewObject.set(
        "GeoLocation",
        new Parse.GeoPoint({
          latitude: Number(values.lat),
          longitude: Number(values.long),
        })
      );
      myNewObject.set("OpenTime", values.openingTime);
      myNewObject.set("CloseTime", values.closingTime);
      myNewObject.set("ElectricityTariff", parseInt(values.electricityTariff));
      myNewObject.set("isEnabled", values.isEnabled === "true" ? true : false);
      myNewObject.set("RevenueModel", values.modelType);
      myNewObject.set("RevenueSharingType", values.revenuePercent);
      myNewObject.set("RevenueAmount", Number(values.revenueAmount));
      myNewObject.set("RentalAmount", Number(values.rentalAmount));
      myNewObject.set("Description", values.description);
      myNewObject.set("Amenities", values.amenities);
      myNewObject.set(
        "ConnectorType",
        values.connectorType.length ? values.connectorType : []
      );
      myNewObject.set("CurrentType", []);
      myNewObject.set("Client", "Charge City");
      myNewObject.set("Currency", "INR");
      myNewObject.set(
        "hasRestrictedAccess",
        values.access === "Private" ? true : false
      );
      myNewObject.save().then((res) => {
        setConfirmationDialog(true);
        alert("Location added successfully");
        setAddEditChargerData({ ...addEditChargerData, loc_id: res.id });
        setLocationId(res.id);
        props.refreshLocation();

        setUpdateLoading(false);
      });
    }
  };

  // Revenue TYPES

  const revenueType = ["%", "kWH"];
  // Location Type

  const handleChipClick = (type: any) => {
    setLocationTypeConnectorError(false);
    setValues({
      ...values,
      type,
    });
  };

  const locationTypes = [
    "Public Parking",
    "Mall",
    "Office Building",
    "Residential",
    "Hotel",
  ];

  // Amenities
  const handleAmenitiesChipClick = (amenity: string) => {
    // Check if the amenity is already in the list
    // setAmenitiesError(false)
    const newValue = values.amenities.includes(amenity)
      ? values.amenities.filter((item) => item !== amenity)
      : [...values.amenities, amenity];

    setValues({
      ...values,
      amenities: newValue,
    });
  };

  const amenitiesList = [
    "Parking",
    "Vallet",
    "Restaurants",
    "Washrooms",
    "Wheelchair Accessible",
  ];

  // Revenue Modal
  const handleModelTypeChange = (modelType: string) => {
    setValues({
      ...values,
      modelType,
    });
    setErrors({ ...errors, modelType: "" });
  };

  // Access Type
  const handleAccessTypeChange = (access: string) => {
    setValues({
      ...values,
      access,
    });
    setErrors({ ...errors, access: "" });
  };

  // Connector Type Code
  const handleConnectorTypeChipClick = (connectorType: string) => {
    // Check if the connectorType is already in the list
    const newValue = values.connectorType
      ? values.connectorType.includes(connectorType)
        ? values.connectorType.filter((item) => item !== connectorType)
        : [...values.connectorType, connectorType]
      : [connectorType];

    setValues({
      ...values,
      connectorType: newValue,
    });
  };

  const connectorTypesList = ["Type 2", "CCS-2", "AC001", "GB/T", "3 Pin"];

  const submitEditLocation = () => {
    if (
      values.stationName &&
      values.address &&
      values.description &&
      values.amenities?.length &&
      values.city &&
      values.state &&
      values.lat &&
      values.long &&
      values.openingTime &&
      values.closingTime &&
      values.electricityTariff
    ) {
      setUpdateLoading(true);
      let myNewObject: Parse.Object = new Parse.Object("Locations");
      myNewObject.set("objectId", values.id);
      myNewObject.set("Name", values.stationName);
      myNewObject.set("Address", values.address);
      myNewObject.set("City", values.city);
      myNewObject.set("Description", values.description);
      myNewObject.set("Amenities", values.amenities);
      myNewObject.set("State", values.state);
      myNewObject.set("LocationType", values.type);
      myNewObject.set("ConnectorType", values.connectorType);
      myNewObject.set("OpenTime", moment(values.openingTime).format("hh:mm A"));
      myNewObject.set(
        "CloseTime",
        moment(values.closingTime).format("hh:mm A")
      );
      myNewObject.set("ElectricityTariff", parseInt(values.electricityTariff));
      myNewObject.set("isEnabled", values.isEnabled === "true" ? true : false);
      myNewObject.set("RevenueModel", values.modelType);
      myNewObject.set("RevenueSharingType", values.revenuePercent);
      myNewObject.set("RevenueAmount", Number(values.revenueAmount));
      myNewObject.set("RentalAmount", Number(values.rentalAmount));
      myNewObject.set(
        "GeoLocation",
        new Parse.GeoPoint({
          latitude: Number(values.lat),
          longitude: Number(values.long),
        })
      );

      myNewObject.set(
        "hasRestrictedAccess",
        values.access === "Private" ? true : false
      );
      myNewObject.save().then(() => {
        alert(`${values.stationName} Edited successfully`);
        setUpdateLoading(false);
        window.location.reload();
      });
    } else {
      window.alert("Please fill all mandatory fileds");
      setUpdateLoading(false);
    }
  };

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [revenueModelType, setRevenueModelType] = useState("%");
  const allConnectorType = useAppSelector(
    (state) => state.connectors.connectors
  );
  const [allChargePointData, setAllChargePointData] = useState<any>([]);
   const [isLoading, setLoading] = useState(false);
   const loadSessions = () => {
    // setLoading(true);
    const parseQuery = new Parse.Query("ChargeSession");

    parseQuery.equalTo("Live", true);
    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");

    parseQuery.find().then((result) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        console.log("Charging Data", item);

        // if (item.get("Live") && item.get("ChargePoint").get("isOCPP")) {
        // console.log("live session", item);
        newRow.push({
          chargeSessionId: item.id,
          userId: `${item.get("User").id}`,
          serial: `${item.get("ChargePoint").get("Serial")}`,
          transactionId: `${item.get("TransactionId")}`,
          userName: `${item.get("User").get("FullName")}`,
          userNameChargingWithGun_2:
            item?.get("ChargeGunId") == 2 &&
            `${item.get("User").get("FullName")} : "-"`,
          chargingGunId: `${item.get("ChargeGunId")}`,
          createdAt: `${item.get("createdAt")}`,
          MeterStart: `${item.get("MeterStart")}`,
        });
        //  / } else {
        //     return;
        //   }
      });
      getAllChargePoint(newRow);
      // setLoading(false);
    });
  };
   const getOcppData = async (
       id: any,
       item: any,
       userId: any,
       userName: any,
       start: any,
       chargeSessionId: any,
       meterStart: any
     ) => {
       console.log("Live charging using userName", userName);
   
       const transaction_ID = id;
       await fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${id}`)
         .then((response: any) => response.json())
         .then((res: any) => {
           console.log("Live meter Value", res);
           setAllChargePointData((oldArray: any) => [
             ...oldArray,
             {
               ...item,
               energyConsumed: (res.energy_active_import_register / 1000).toFixed(
                 2
               ),
   
               duration:
                 moment.duration(moment(res.timestamp).diff(start)).hours() +
                 "hr" +
                 " " +
                 moment.duration(moment(res.timestamp).diff(start)).minutes() +
                 "min",
   
               ocppCost: (
                 item.tariff *
                 (res.energy_active_import_register / 1000)
               ).toFixed(2),
   
               // ocppTransactionId: res.transaction_id,
               ocppTransactionId: transaction_ID,
               ocppUserId: userId,
               userName: userName,
               chargeSessionId: chargeSessionId,
               meterStart: meterStart,
             },
           ]);
         });
     };

   const getAllChargePoint = (chargerData: any) => {
    console.log("live Charger data");
    // setLoading(true); 
    const ChargePoints = Parse.Object.extend("Chargers");
  
    const parseQuery = new Parse.Query(ChargePoints);
    parseQuery.include("Location");
    parseQuery.include("CP_Vendors");
    parseQuery.include("ConnectorType");
    parseQuery.include("ChargePointOperators");
    parseQuery.limit(1000);
  
    if (values?.id) {
      console.log("chargerData", values?.id);
  
      // Create a subquery to match chargerData with Location objectId
      const Location = Parse.Object.extend("Locations");
      const locationQuery = new Parse.Query(Location);
      locationQuery.equalTo("objectId", values?.id);
  
      // Use matchesQuery to filter Chargers based on Location
      parseQuery.matchesQuery("Location", locationQuery);
    }
  
    // if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
    //   parseQuery.equalTo("CPO", currentUser.get("CPO"));
    // }
    parseQuery.find().then((result) => {
      let chargerArray: any[] = [];
  
      result.forEach((item, index) => {
        console.log("Item of charger:", item);
        console.log("props.liveOcppData", props.liveOcppData);
  
        let connectorType = item.get("ConnectorType");
        let vendor = item.get("Vendor");
        let cpo = item.get("CPO");
        let ab = {
          id: item.id,
          serial: item.get("Serial"),
          power: `${item.get("Power")}`,
          chargingRate: `${item.get("ChargingRate")}`,
          location: `${item.get("Location")?.id}`,
          status: item.get("isOCPP")
            ? props.liveOcppData?.filter(
                (el: any) => el.ocppName === item.get("ChargeId")
              )[0]?.status || "Offline"
            : "",
          connector_1_Status: item.get("isOCPP")
            ? props.liveOcppData?.filter(
                (el: any) => el.ocppName === item.get("ChargeId")
              )[1]?.status || "Offline"
            : "",
          connector_2_Status: item.get("isOCPP")
            ? props.liveOcppData?.filter(
                (el: any) => el.ocppName === item.get("ChargeId")
              )[1]?.status || "Offline"
            : "",
          allConnector: props.liveOcppData?.filter(
            (el: any) => el.ocppName === item.get("ChargeId")
          ),
          ocppName: props.liveOcppData?.filter(
            (el: any) => el.ocppName === item.get("ChargeId")
          )[0]?.ocppName,
          isActive: props.liveOcppData?.filter(
            (el: any) => el.ocppName === item.get("ChargeId")
          )[0]?.active,
          connector: {
            id: connectorType?.id ? connectorType.id : "",
            name: connectorType?.get("Name") ? connectorType.get("Name") : "",
          },
          tariff: `${item.get("Cost")}`,
          taxRate: `${item.get("TaxRate")}`,
          user: "-",
          duration: "-",
          connected: "-",
          energyConsumed: "-",
          vendor: {
            id: vendor?.id,
            name: vendor?.get("Name"),
          },
          chargerId: item.get("ChargeId"),
          inclusiveTax: item.get("inclusiveTax") ? "true" : "false",
          cost: item.get("Cost"),
          isEnabled: item.get("isEnabled") ? "true" : "false",
          disabledReason: item.get("DisableReason"),
          cpo: {
            id: cpo?.id,
            name: cpo?.get("Name"),
          },
          isOcpp: item.get("isOCPP") ? "true" : "false",
          // meterStart: liveOcppsChargesession?.map((ele:any)=> ele?.MeterStart)
        };
  
        // Trasaction id to get meter values and userId to Stop charging
        // const liveCC = chargerData
        //   .map((item: any) => {
        //     console.log("Item in liveOcppsChargesession:", item); // Log the item here
        //     return item.serial;
        //   })
        //   .includes(ab.serial);
  
        // if (
        //   liveOcppsChargesession
        //     .map((item: any) => item.serial)
        //     .includes(ab.serial)
        // ) {
        //   getOcppData(
        //     liveOcppsChargesession.filter(
        //       (item: any) => item.serial === ab.serial
        //     )[0].transactionId,
        //     ab,
        //     liveOcppsChargesession.filter(
        //       (item: any) => item.serial === ab.serial
        //     )[0].userId,
  
        //     liveOcppsChargesession.filter(
        //       (item: any) => item.serial === ab.serial
        //     )[0].userName,
        //     liveOcppsChargesession.filter(
        //       (item: any) => item.serial === ab.serial
        //     )[0].createdAt,
        //     liveOcppsChargesession.filter(
        //       (item: any) => item.serial === ab.serial
        //     )[0].chargeSessionId,
        //     liveOcppsChargesession.filter(
        //       (item: any) => item.serial === ab.serial
        //     )[0].MeterStart
        //   );
        // } else {
        //   chargerArray.push(ab);
        // }
  
        const matchingSessions = chargerData.filter(
          (item: any) => item.serial === ab.serial
        );
  
        if (matchingSessions.length > 0) {
          matchingSessions.forEach((session: any) => {
            getOcppData(
              session.transactionId,
              { ...ab, chargingGunId: session.chargingGunId }, // Attach chargingGunId to `ab` for clarity
              session.userId,
              session.userName,
              session.createdAt,
              session.chargeSessionId,
              session.MeterStart
            );
          });
        } else {
          chargerArray.push(ab);
        }
      });
  
      setAllChargePointData(chargerArray);
      setLoading(false);
    }).catch(() => setLoading(false));
  };
 useEffect(() => {
    if (values?.id) {
      loadSessions();
    }
  }, [values?.id]);

  console.log("allChargePointData",allChargePointData);
  
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: 1500,
          height: 1000,
        },
      }}
      maxWidth={"xl"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="head1">
            {" "}
            {props.type == "add" ? (
              <span className="font-lexend">Add Location</span>
            ) : (
              <span>Edit Location</span>
            )}
          </span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>

      <div className="Location_Modal_Container flex">
        <div className="left_Side_Form m-5 w-1/2">
          <div className="form_Container">
            {/* Station Name Form Code */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label className="flex items-center mb-2" htmlFor="station name">
                <span>
                  <svg
                    className="mr-2"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.75 14.75V9.5L5 5.75L1.25 9.5V14.75H5M8.75 14.75H5M8.75 14.75H14.75V2C14.75 1.80109 14.671 1.61032 14.5303 1.46967C14.3897 1.32902 14.1989 1.25 14 1.25H6.5C6.30109 1.25 6.11032 1.32902 5.96967 1.46967C5.82902 1.61032 5.75 1.80109 5.75 2V6.5M5 14.75V11.75M8.75 4.25V4.2575M11.75 4.25V4.2575M11.75 7.25V7.2575M11.75 10.25V10.2575"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Station Name
                  <span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Name"
                value={values.stationName}
                name="stationName"
                onChange={handleInputChange}
                error={!!errors.stationName}
                helperText={errors.stationName}
              />
            </div>
            {/* Description Form Code */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className="flex items-center mt-7 mb-2"
                htmlFor="Description"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 4.75H11M5 7.75H9.5M5.75 11.5H3.5C2.90326 11.5 2.33097 11.2629 1.90901 10.841C1.48705 10.419 1.25 9.84674 1.25 9.25V3.25C1.25 2.65326 1.48705 2.08097 1.90901 1.65901C2.33097 1.23705 2.90326 1 3.5 1H12.5C13.0967 1 13.669 1.23705 14.091 1.65901C14.5129 2.08097 14.75 2.65326 14.75 3.25V9.25C14.75 9.84674 14.5129 10.419 14.091 10.841C13.669 11.2629 13.0967 11.5 12.5 11.5H10.25L8 13.75L5.75 11.5Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend">
                  Description<span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Description"
                value={values.description}
                name="description"
                onChange={handleInputChange}
                error={!!errors.description}
                helperText={errors.description}
              />
            </div>
            {/* Address Form Code */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className="flex items-center mt-7 mb-2"
                htmlFor="Description"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 14.75H4M2.5 14.75V1.25M2.5 2L9.25 5L2.5 8"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend">
                  Address<span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Address"
                value={values.address}
                name="address"
                onChange={handleInputChange}
                error={!!errors.address}
                helperText={errors.address}
              />
            </div>

            {/* Country Form Code */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className="flex items-center mt-7 mb-2"
                htmlFor="Description"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.7 5.75H14.3M1.7 10.25H14.3M7.625 1.25C6.36151 3.27471 5.69165 5.6134 5.69165 8C5.69165 10.3866 6.36151 12.7253 7.625 14.75M8.375 1.25C9.63849 3.27471 10.3083 5.6134 10.3083 8C10.3083 10.3866 9.63849 12.7253 8.375 14.75M1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8C14.75 6.20979 14.0388 4.4929 12.773 3.22703C11.5071 1.96116 9.79021 1.25 8 1.25C6.20979 1.25 4.4929 1.96116 3.22703 3.22703C1.96116 4.4929 1.25 6.20979 1.25 8Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend">Country</span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                value={"India"}
                onChange={(e: any) =>
                  setValues({
                    ...values,
                    country: e.target.value,
                  })
                }
              />
            </div>

            {/* State Form Code */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className="flex items-center mt-7 mb-2"
                htmlFor="Description"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.75 1V5.5L1.25 8.5V10H8.75V8.5L7.25 5.5V1M5 10V13.75M2 1H8"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend">
                  State<span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              {/* </FormControl> */}
              <FormControl
                variant="standard"
                sx={{ marginTop: "14px" }}
                error={!!errors.state}
              >
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  value={values.state}
                  onChange={(e) => {
                    const selectedState = allIndianStates.find(
                      (item) => item.name === e.target.value
                    );
                    setValues({
                      ...values,
                      state: selectedState?.name || "",
                      isoCode: selectedState?.isoCode || "",
                    });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      state: validateField("state", selectedState?.name || ""),
                    }));
                  }}
                >
                  <MenuItem value="">
                    <em className="font-lexend">State</em>
                  </MenuItem>
                  {allIndianStates.map((item, idx) => (
                    <MenuItem key={idx} value={item.name}>
                      <span className="font-lexend">{item.name}</span>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.state}</FormHelperText>
              </FormControl>
            </div>

            {/* City Form Code */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className="flex items-center mt-7 mb-2"
                htmlFor="Description"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.75 7.24978C4.75 7.84652 4.98705 8.41882 5.40901 8.84077C5.83097 9.26273 6.40326 9.49978 7 9.49978C7.59674 9.49978 8.16903 9.26273 8.59099 8.84077C9.01295 8.41882 9.25 7.84652 9.25 7.24978C9.25 6.65305 9.01295 6.08075 8.59099 5.65879C8.16903 5.23684 7.59674 4.99978 7 4.99978C6.40326 4.99978 5.83097 5.23684 5.40901 5.65879C4.98705 6.08075 4.75 6.65305 4.75 7.24978Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.2428 11.4925L8.0605 14.6748C7.77924 14.9558 7.39794 15.1136 7.00038 15.1136C6.60282 15.1136 6.22151 14.9558 5.94025 14.6748L2.75725 11.4925C1.91817 10.6534 1.34675 9.58431 1.11527 8.42043C0.883782 7.25655 1.00262 6.05017 1.45676 4.95383C1.91089 3.85749 2.67993 2.92044 3.66661 2.26116C4.6533 1.60189 5.81333 1.25 7 1.25C8.18668 1.25 9.3467 1.60189 10.3334 2.26116C11.3201 2.92044 12.0891 3.85749 12.5433 4.95383C12.9974 6.05017 13.1162 7.25655 12.8847 8.42043C12.6533 9.58431 12.0818 10.6534 11.2428 11.4925Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend">
                  City<span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter City"
                name="city"
                value={values.city}
                onChange={handleInputChange}
                error={!!errors.city}
                helperText={errors.city}
              />
              {/* <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={citiesOfSelectedState.map((option) => option.name)}
                value={values.city}
                onChange={(event: any, newValue: string | null) => {
                  const selectedCity = citiesOfSelectedState.find(
                    (item) => item.name === newValue
                  );
                  setValues({
                    ...values,
                    city: selectedCity?.name || "",
                  });
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    city: validateField("city", selectedCity?.name || ""),
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      autoComplete: "off",
                    }}
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                )}
              /> */}

              {/* <FormControl variant="standard" sx={{ marginTop: "14px" }} error={!!errors.city}>
                {/* <InputLabel shrink htmlFor="bootstrap-input">
              State <small>*</small>
            </InputLabel> */}
              {/* <Select
                  inputProps={{ "aria-label": "Without label" }}
                  value={values.city}
                  onChange={(e) => {
                    const selectedCity = citiesOfSelectedState.find(item => item.name === e.target.value);
                    setValues({
                      ...values,
                      city: selectedCity?.name || "",
                      
                    });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      city: validateField('city', selectedCity?.name || ""),
                    }));
                  }}
                >
                  <MenuItem value="">
                    <em className="font-lexend">{values.state ? "City" : "Please select state first"}</em>
                  </MenuItem>
                  {citiesOfSelectedState.map((item, idx) => (
                    <MenuItem
                      key={idx}
                      value={item?.name}
                      // onClick={() =>
                      //   setValues({
                      //     ...values,
                      //     city: item?.name
                      //   })
                      // }
                    >
                      <span className="font-lexend">{item?.name}</span> 
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText> <span className="font-lexend">{errors.city}</span> </FormHelperText> */}
              {/* </FormControl> */}
            </div>

            <div className="flex">
              {/* Longitude Form Code */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                  marginRight: "30px",
                }}
              >
                <label
                  className="flex items-center mt-7 mb-2"
                  htmlFor="Description"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 15.75C8.11358 15.75 7.23583 15.5754 6.41689 15.2362C5.59794 14.897 4.85382 14.3998 4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 8.11358 2.42459 7.23583 2.76381 6.41689C3.10303 5.59794 3.60023 4.85382 4.22703 4.22703C4.85382 3.60023 5.59794 3.10303 6.41689 2.76381C7.23583 2.42459 8.11358 2.25 9 2.25M9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25M9 15.75V2.25M8.625 2.25C7.57028 3.03123 6.71331 4.04876 6.12282 5.22097C5.53233 6.39318 5.22475 7.68746 5.22475 9C5.22475 10.3125 5.53233 11.6068 6.12282 12.779C6.71331 13.9512 7.57028 14.9688 8.625 15.75M9.375 2.25C10.4297 3.03123 11.2867 4.04876 11.8772 5.22097C12.4677 6.39318 12.7752 7.68746 12.7752 9C12.7752 10.3125 12.4677 11.6068 11.8772 12.779C11.2867 13.9512 10.4297 14.9688 9.375 15.75"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Longitude<span className="text-red-500 text-l ml-1">*</span>
                  </span>
                </label>
                <TextField
                  id="input-with-sx"
                  variant="standard"
                  placeholder="Enter Longitude"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  type="number"
                  value={values.long}
                  name="long"
                  onChange={handleInputChange}
                  error={!!errors.long}
                  helperText={errors.long}
                />
              </div>
              {/* Latitude Form Code */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <label
                  className="flex items-center mt-7 mb-2"
                  htmlFor="Latitude"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8M1.25 8C1.25 7.11358 1.42459 6.23583 1.76381 5.41689C2.10303 4.59794 2.60023 3.85382 3.22703 3.22703C3.85382 2.60023 4.59794 2.10303 5.41689 1.76381C6.23583 1.42459 7.11358 1.25 8 1.25C8.88642 1.25 9.76417 1.42459 10.5831 1.76381C11.4021 2.10303 12.1462 2.60023 12.773 3.22703C13.3998 3.85382 13.897 4.59794 14.2362 5.41689C14.5754 6.23583 14.75 7.11358 14.75 8M1.25 8H14.75M2.45 4.25H13.55M2.45 11.75H13.55"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Latitude<span className="text-red-500 text-l ml-1">*</span>
                  </span>
                </label>
                <TextField
                  id="input-with-sx"
                  variant="standard"
                  placeholder="Enter Latitude"
                  type="number"
                  value={values.lat}
                  name="lat"
                  onChange={handleInputChange}
                  error={!!errors.lat}
                  helperText={errors.lat}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </div>
            </div>

            {/* Enable Location */}
            <div className="mt-6 flex">
              <input
                className="custom-checkbox"
                type="checkbox"
                style={{ width: "20px", height: "20px" }}
                checked={values.isEnabled === "true"}
                onChange={() =>
                  setValues({
                    ...values,
                    isEnabled: values.isEnabled === "true" ? "false" : "true",
                  })
                }
              />
              <label htmlFor="" className="font-lexend ml-2">
                Enable Location
              </label>
            </div>
          </div>
        </div>
        {/* Right Side Form */}
        <div className="right_Side_Form m-5 w-1/2">
          <div className="form_Container">
            {/* Aminities */}
            <div
              style={{ display: "flex", flexDirection: "column", width: "40%" }}
            >
              <label className="flex items-center mb-2" htmlFor="station name">
                <span>
                  <svg
                    className="mr-2"
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.12544 3.125C4.12544 3.62228 4.32299 4.09919 4.67462 4.45083C5.02625 4.80246 5.50316 5 6.00044 5C6.49772 5 6.97464 4.80246 7.32627 4.45083C7.6779 4.09919 7.87544 3.62228 7.87544 3.125C7.87544 2.62772 7.6779 2.15081 7.32627 1.79917C6.97464 1.44754 6.49772 1.25 6.00044 1.25C5.50316 1.25 5.02625 1.44754 4.67462 1.79917C4.32299 2.15081 4.12544 2.62772 4.12544 3.125Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.00046 15.026L9.82171 11.204C10.0391 10.9894 10.2118 10.734 10.3301 10.4524C10.4483 10.1709 10.5097 9.86867 10.5107 9.56327C10.5117 9.25787 10.4522 8.95529 10.3358 8.67294C10.2194 8.3906 10.0483 8.13407 9.83234 7.91812C9.61639 7.70216 9.35986 7.53105 9.07751 7.41464C8.79517 7.29822 8.49259 7.2388 8.18719 7.23979C7.88179 7.24078 7.5796 7.30216 7.29801 7.4204C7.01643 7.53864 6.76101 7.7114 6.54646 7.92875L6.00046 8.474L5.45446 7.92875C5.23991 7.7114 4.98449 7.53864 4.70291 7.4204C4.42132 7.30216 4.11913 7.24078 3.81373 7.23979C3.50833 7.2388 3.20575 7.29822 2.9234 7.41464C2.64106 7.53105 2.38453 7.70216 2.16857 7.91812C1.95262 8.13407 1.78151 8.3906 1.6651 8.67294C1.54868 8.95529 1.48926 9.25787 1.49025 9.56327C1.49123 9.86867 1.55262 10.1709 1.67086 10.4524C1.7891 10.734 1.96186 10.9894 2.17921 11.204L6.00046 15.026Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Amenities<span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              {/* Amenities Chips */}
              <div className="flex gap-2">
                {amenitiesList.map((amenity, idx) => (
                  <Chip
                    key={idx}
                    label={amenity}
                    variant="outlined"
                    color={
                      values?.amenities?.includes(amenity) ? "success" : "default"
                    }
                    onClick={() => handleAmenitiesChipClick(amenity)}
                  />
                ))}
              </div>
              <span>
                {amenitiesError && values.amenities.length <= 0 && (
                  <span
                    style={{
                      fontSize: "12px",
                      letterSpacing: "1px",
                      wordSpacing: "5px",
                    }}
                    className=" text-red-600 ml-5"
                  >
                    Please select amenities
                  </span>
                )}
              </span>
            </div>
            {/* Connector Types */}
            <div
              style={{ display: "flex", flexDirection: "column", width: "40%" }}
            >
              <label
                className="flex items-center mb-2 mt-6"
                htmlFor="station name"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Connector Types
                  <span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              {/* Connector Types Chips */}
              <div className="flex gap-2">
                {connectorTypesList.map((connectorType, idx) => (
                  <Chip
                    key={idx}
                    label={connectorType}
                    variant="outlined"
                    color={
                      values?.connectorType?.includes(connectorType)
                        ? "success"
                        : "default"
                    }
                    onClick={() => handleConnectorTypeChipClick(connectorType)}
                  />
                ))}
              </div>
              <span>
                {showConnectorError && values?.connectorType?.length <= 0 && (
                  <span
                    style={{
                      fontSize: "12px",
                      letterSpacing: "1px",
                      wordSpacing: "5px",
                    }}
                    className=" text-red-600 ml-5"
                  >
                    Please select Connector Type
                  </span>
                )}
              </span>
            </div>
            {/* Location Types */}
            <div
              style={{ display: "flex", flexDirection: "column", width: "40%" }}
            >
              <label
                className="flex items-center mb-2 mt-6"
                htmlFor="station name"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7494 1.25L9.87444 14.75C9.84154 14.8218 9.78871 14.8827 9.72223 14.9253C9.65576 14.968 9.57843 14.9907 9.49944 14.9907C9.42046 14.9907 9.34313 14.968 9.27665 14.9253C9.21018 14.8827 9.15735 14.8218 9.12444 14.75L6.49944 9.5L1.24944 6.875C1.17764 6.84209 1.11679 6.78926 1.07413 6.72279C1.03147 6.65631 1.00879 6.57899 1.00879 6.5C1.00879 6.42101 1.03147 6.34369 1.07413 6.27721C1.11679 6.21074 1.17764 6.15791 1.24944 6.125L14.7494 1.25Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Location Types
                  <span className="text-red-500 text-l ml-1">*</span>
                </span>
              </label>
              {/* Location Types Chips */}
              <div className="flex gap-2">
                {locationTypes.map((item, idx) => (
                  <Chip
                    key={idx}
                    label={item}
                    variant="outlined"
                    color={values.type === item ? "success" : "default"}
                    onClick={() => handleChipClick(item)}
                  />
                ))}
              </div>
              <span>
                {showLocationTypeError && (
                  <span
                    style={{
                      fontSize: "12px",
                      letterSpacing: "1px",
                      wordSpacing: "5px",
                    }}
                    className=" text-red-600 ml-5"
                  >
                    Please select Location Type
                  </span>
                )}
              </span>
            </div>
            <div className="flex">
              {/* Electricity Tariff */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "38%",
                  marginRight: "19px",
                }}
              >
                <label
                  className="flex items-center mt-7 mb-2"
                  htmlFor="Electricity Tariff"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 1.25V6.5H11.25L5.25 14.75V9.5H0.75L6.75 1.25Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Electricity Tariff
                    <span className="text-red-500 text-l ml-1">*</span>
                  </span>
                </label>
                <TextField
                  id="input-with-sx"
                  variant="standard"
                  placeholder="Enter Amount              / kWh"
                  type="number"
                  value={values.electricityTariff}
                  name="electricityTariff"
                  onChange={handleInputChange}
                  error={!!errors.electricityTariff}
                  helperText={errors.electricityTariff}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "38%",
                }}
              >
                <label
                  className="flex items-center mt-7 mb-2"
                  htmlFor="Currency"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0.75H1.25H3.5C4.29565 0.75 5.05871 1.06607 5.62132 1.62868C6.18393 2.19129 6.5 2.95435 6.5 3.75C6.5 4.54565 6.18393 5.30871 5.62132 5.87132C5.05871 6.43393 4.29565 6.75 3.5 6.75H1.25L5.75 11.25M1.25 3.75H9.5"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">Currency</span>
                </label>
                <TextField
                  id="input-with-sx"
                  variant="standard"
                  placeholder="Indian Rupee (₹)"
                  value={"INR"}
                />
              </div>
            </div>
            {/* Open TIme and CLose Time */}
            <div className="flex">
              {/* Open Time */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "38%",
                  marginRight: "19px",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="flex items-center mt-7 mb-2"
                  htmlFor="Opening Time"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.25 8H2M8 1.25V2M14 8H14.75M8 14V14.75M3.2 3.2L3.725 3.725M12.8 3.2L12.275 3.725M12.275 12.275L12.8 12.8M3.725 12.275L3.2 12.8M5 8C5 8.79565 5.31607 9.55871 5.87868 10.1213C6.44129 10.6839 7.20435 11 8 11C8.79565 11 9.55871 10.6839 10.1213 10.1213C10.6839 9.55871 11 8.79565 11 8C11 7.20435 10.6839 6.44129 10.1213 5.87868C9.55871 5.31607 8.79565 5 8 5C7.20435 5 6.44129 5.31607 5.87868 5.87868C5.31607 6.44129 5 7.20435 5 8Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Opening Time
                    <span className="text-red-500 text-l ml-1">*</span>
                  </span>
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    value={values.openingTime}
                    label={values.openingTime}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        openingTime: String(moment(e).format("hh:mm A"))
                          ? String(moment(e).format("hh:mm A"))
                          : "",
                      });
                      if (e) {
                        setErrors({
                          ...errors,
                          openingTime: "", // Clear the error when a valid date is selected
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{
                          "& .MuiInputBase-input": {
                            fontFamily: "lexend, sans-serif", // Apply Lexend font family
                            fontWeight: "400",
                          },
                          "& .MuiFormHelperText-root": {
                            fontFamily: "lexend, sans-serif",
                            fontWeight: "400", // Apply Lexend font family to helper text
                          },
                        }}
                        {...params}
                        value={values.openingTime}
                        error={!!errors.openingTime}
                      />
                    )}
                  />{" "}
                </LocalizationProvider>
                {errors.openingTime && (
                  <span
                    style={{ fontSize: "12px" }}
                    className=" text-red-600 ml-2 mt-2"
                  >
                    {errors.openingTime}
                  </span>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "38%",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="flex items-center mt-7 mb-2"
                  htmlFor="Currency"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00033 1.25014C8.09933 1.25014 8.19758 1.25014 8.29508 1.25014C7.33158 2.14542 6.71227 3.35002 6.54479 4.65456C6.37732 5.95909 6.67227 7.28107 7.37838 8.39069C8.08449 9.50031 9.1571 10.3274 10.4098 10.7282C11.6625 11.1291 13.016 11.0783 14.2351 10.5846C13.7661 11.713 12.9997 12.6929 12.0175 13.4198C11.0353 14.1468 9.87416 14.5934 8.65801 14.7122C7.44185 14.8309 6.21627 14.6174 5.11199 14.0942C4.00771 13.571 3.06616 12.7579 2.38777 11.7416C1.70937 10.7253 1.31959 9.54385 1.25999 8.32337C1.20039 7.10289 1.47321 5.88911 2.04935 4.81153C2.62549 3.73394 3.48335 2.83296 4.5314 2.20469C5.57945 1.57642 6.77839 1.24444 8.00033 1.24414V1.25014Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Closing Time
                    <span className="text-red-500 text-l ml-1">*</span>
                  </span>
                </label>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    value={values.closingTime}
                    label={values.closingTime}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        closingTime: String(moment(e).format("hh:mm A"))
                          ? String(moment(e).format("hh:mm A"))
                          : "",
                      });
                      if (e) {
                        setErrors({
                          ...errors,
                          closingTime: "", // Clear the error when a valid date is selected
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{
                          "& .MuiInputBase-input": {
                            fontFamily: "lexend, sans-serif",
                            fontWeight: "400",
                          },
                          "& .MuiFormHelperText-root": {
                            fontFamily: "lexend, sans-serif",
                            fontWeight: "400",
                          },
                        }}
                        {...params}
                        error={!!errors.closingTime}
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors.closingTime && (
                  <span
                    style={{ fontSize: "12px" }}
                    className=" text-red-600 ml-2 mt-2"
                  >
                    {errors.closingTime}
                  </span>
                )}
              </div>
            </div>
            {/* Revenue Model */} {/* Access */}
            <div className="flex">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <label
                  className="flex items-center mt-7 mb-2"
                  htmlFor="Revenue Model"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.25 5H5.75H6.5C7.09674 5 7.66903 5.23705 8.09099 5.65901C8.51295 6.08097 8.75 6.65326 8.75 7.25C8.75 7.84674 8.51295 8.41903 8.09099 8.84099C7.66903 9.26295 7.09674 9.5 6.5 9.5H5.75L8 11.75M5.75 7.25H10.25M1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8C14.75 7.11358 14.5754 6.23583 14.2362 5.41689C13.897 4.59794 13.3998 3.85382 12.773 3.22703C12.1462 2.60023 11.4021 2.10303 10.5831 1.76381C9.76417 1.42459 8.88642 1.25 8 1.25C7.11358 1.25 6.23583 1.42459 5.41689 1.76381C4.59794 2.10303 3.85382 2.60023 3.22703 3.22703C2.60023 3.85382 2.10303 4.59794 1.76381 5.41689C1.42459 6.23583 1.25 7.11358 1.25 8Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Revenue Model
                    <span className="text-red-500 text-l ml-1">*</span>
                  </span>
                </label>
                <div className="flex items-center mt-3">
                  <input
                    type="radio"
                    className="mr-3 font-lexend"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    value="Shared"
                    checked={values.modelType === "Shared"}
                    onChange={() => handleModelTypeChange("Shared")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Shared
                  </label>
                  {values.modelType === "Shared" ? (
                    <div className="-mt-5">
                      <TextField
                        variant="standard"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                          marginTop: "16px",
                          marginLeft: "5px",
                          width: "70px",
                        }}
                        id="standard-basic"
                        type="number"
                        value={
                          revenueModelType === "%"
                            ? values.revenuePercent
                            : values.revenueAmount
                        }
                        onChange={(e: any) => {
                          revenueModelType === "%"
                            ? setValues({
                                ...values,
                                revenuePercent: e.target.value,
                              })
                            : setValues({
                                ...values,
                                revenueAmount: e.target.value,
                              });
                        }}
                      />
                      <TextField
                        sx={{ marginTop: "16px" }}
                        id="standard-select-currency"
                        select
                        variant="standard"
                        defaultValue="%"
                      >
                        {revenueType.map((option: any) => (
                          <MenuItem
                            key={option}
                            value={option}
                            onClick={() => {
                              setRevenueModelType(option);
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="flex mt-3">
                  <input
                    type="radio"
                    className="mr-3 font-lexend"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    value="Rental"
                    checked={values.modelType === "Rental"}
                    onChange={() => handleModelTypeChange("Rental")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Rental
                  </label>
                  {values.modelType === "Rental" ? (
                    <TextField
                      id="input-with-sx"
                      variant="standard"
                      placeholder="Enter Amount      (₹)"
                      type="number"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        marginLeft: "5px",
                        width: "170px",
                      }}
                      value={values.rentalAmount}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          rentalAmount: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {errors.modelType && (
                  <span
                    style={{ fontSize: "12px" }}
                    className=" text-red-600 ml-2 mt-2"
                  >
                    {errors.modelType}
                  </span>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <label className="flex items-center mt-7 mb-2" htmlFor="Public">
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.25 5H5.75H6.5C7.09674 5 7.66903 5.23705 8.09099 5.65901C8.51295 6.08097 8.75 6.65326 8.75 7.25C8.75 7.84674 8.51295 8.41903 8.09099 8.84099C7.66903 9.26295 7.09674 9.5 6.5 9.5H5.75L8 11.75M5.75 7.25H10.25M1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8C14.75 7.11358 14.5754 6.23583 14.2362 5.41689C13.897 4.59794 13.3998 3.85382 12.773 3.22703C12.1462 2.60023 11.4021 2.10303 10.5831 1.76381C9.76417 1.42459 8.88642 1.25 8 1.25C7.11358 1.25 6.23583 1.42459 5.41689 1.76381C4.59794 2.10303 3.85382 2.60023 3.22703 3.22703C2.60023 3.85382 2.10303 4.59794 1.76381 5.41689C1.42459 6.23583 1.25 7.11358 1.25 8Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Access<span className="text-red-500 text-l ml-1">*</span>
                  </span>
                </label>
                <div className="flex mt-3">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    checked={values.access === "Public"}
                    onChange={() => handleAccessTypeChange("Public")}
                  />
                  <label htmlFor="" className="font-lexend  ml-2">
                    Public
                  </label>
                </div>

                <div className="flex mt-3">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    checked={values.access === "Restricted"}
                    onChange={() => handleAccessTypeChange("Restricted")}
                  />
                  <label htmlFor="" className="font-lexend ml-2">
                    Restricted
                  </label>
                </div>
                {errors.access && (
                  <span
                    style={{ fontSize: "12px" }}
                    className=" text-red-600 ml-2 mt-2"
                  >
                    {errors.access}
                  </span>
                )}
              </div>
            </div>
            {/* Submit Button For Edit and Create Loaction */}
            {props.type == "add" && (
              <div className="flex justify-end mt-10">
                <button
                  className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
                  style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                  onClick={() => {
                    props.type === "add"
                      ? submitAddLocation()
                      : submitEditLocation();
                  }}
                >
                  <span className="mr-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75 9L7.5 12.75L15 5.25"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span style={{ color: "white" }}>Submit</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <AddEditChargerModal
        show={showAddEditChargerModal}
        handleClose={() => {
          setShowAddEditChargerModal(false);
          props.handleClose();
          setAddEditChargerData({
            loc_id: LocationID,
            id: "",
            serial: "",
            power: "",
            status: "",
            connector: {
              id: "",
              name: "",
            },
            location: "",
            isEnabled: "true",
            disabledReason: "",
            user: "",
            duration: "",
            connected: "",
            energyConsumed: "",
            vendor: {
              id: "",
              name: "",
            },
            chargerId: "",
            tariff: "",
            taxRate: "",
            inclusiveTax: "",
            currentType: "",
            chargingRate: "",
            cpo: {
              id: "",
              name: "",
            },
            isOcpp: "true",
          });
          // props.refreshLocation()
        }}
        data={addEditChargerData}
        type="add"
      />

      {props.type !== "add" && (
        <div className="mt-1 mx-auto w-11/12">
          <Table size="small" aria-label="purchases">
            <TableHead style={{ background: "##EAF0F5" }}>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: "lexend",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  Serial Number
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "lexend",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  Max Power
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "lexend",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  Status
                </TableCell>
                <TableCell className="innerTable" align="center"></TableCell>
              </TableRow>
            </TableHead>

            {allChargePointData
              .filter((item: { location: any }) => item.location === values.id)
              .map((item: any) => (
                <>
                  <TableBody>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      {item.serial}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      {item.power}
                    </TableCell>
                    <TableCell
                      style={{ fontFamily: "lexend", fontSize: "15px" }}
                    >
                      {item.status === "Available" && (
                        <span className="flex items-center">
                          {" "}
                          <span>
                            <svg
                              className="mr-2"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                stroke="#529fec"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>{" "}
                          <span style={{ color: "#529fec" }}>Online</span>{" "}
                        </span>
                      )}

                      {item.status === "Charging" && (
                        <>
                          <span className="flex items-center">
                            {" "}
                            <span>
                              <svg
                                className="mr-2"
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.78906 2.75C1.78906 2.35218 1.9471 1.97064 2.2284 1.68934C2.50971 1.40804 2.89124 1.25 3.28906 1.25H11.5391C11.9369 1.25 12.3184 1.40804 12.5997 1.68934C12.881 1.97064 13.0391 2.35218 13.0391 2.75V3.125C13.0391 3.22446 13.0786 3.31984 13.1489 3.39017C13.2192 3.46049 13.3146 3.5 13.4141 3.5C13.5135 3.5 13.6089 3.53951 13.6792 3.60983C13.7496 3.68016 13.7891 3.77554 13.7891 3.875V6.125C13.7891 6.22446 13.7496 6.31984 13.6792 6.39016C13.6089 6.46049 13.5135 6.5 13.4141 6.5C13.3146 6.5 13.2192 6.53951 13.1489 6.60983C13.0786 6.68016 13.0391 6.77554 13.0391 6.875V7.25C13.0391 7.64782 12.881 8.02935 12.5997 8.31066C12.3184 8.59196 11.9369 8.75 11.5391 8.75H8.16406M3.28906 12.5V10.25M1.78906 7.25V5.375M4.78906 7.25V5.375M1.03906 7.25H5.53906V8.75C5.53906 9.14782 5.38103 9.52935 5.09972 9.81066C4.81842 10.092 4.43689 10.25 4.03906 10.25H2.53906C2.14124 10.25 1.75971 10.092 1.4784 9.81066C1.1971 9.52935 1.03906 9.14782 1.03906 8.75V7.25Z"
                                  stroke="#1AC47D"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>{" "}
                            <span style={{ color: "#1AC47D" }}>Charging</span>{" "}
                          </span>
                        </>
                      )}

                      {item.status === "Preparing" && (
                        <span className="label-connected">Connected</span>
                      )}
                      {item.status === "Finishing" && (
                        <span style={{ color: "#fd9610" }}>Finishing</span>
                      )}
                      {item.status === "Offline" && (
                        <span className="flex items-center">
                          {" "}
                          <span>
                            <svg
                              className="mr-2"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.1625 13.2997C9.6009 13.4765 9.0093 13.5376 8.42344 13.4793C7.83759 13.4209 7.26964 13.2444 6.75395 12.9603C6.23826 12.6763 5.78553 12.2906 5.42313 11.8266C5.06072 11.3626 4.79618 10.8299 4.64549 10.2607C4.44852 9.51812 4.45141 8.73662 4.65385 7.99547C4.8563 7.25432 5.2511 6.57988 5.79824 6.0405L7.33874 4.5L12.7125 9.87375M3 15L5.625 12.375M11.25 3L8.625 5.625M15 6.75L12.375 9.375M12 12L15 15M15 12L12 15"
                                stroke="#C99456"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>{" "}
                          <span style={{ color: "#C99456" }}>Offline</span>{" "}
                        </span>
                      )}
                      {item.status === "Faulted" && (
                        <span className="text-red-900">Faulted</span>
                      )}
                      {item.status === "SuspendedEV" && (
                        <span className="text-red-900">SuspendedEV</span>
                      )}
                    </TableCell>
                  </TableBody>
                </>
              ))}
          </Table>
        </div>
      )}
      <br />

      {/* Add Charger Button */}
      {props.type !== "add" && values.id ? (
        <div className="flex justify-end mt-4 mb-10">
          {/* Submit Button */}
          <button
            className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
            style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
            onClick={() => {
              props.type === "add" ? submitAddLocation() : submitEditLocation();
            }}
          >
            <span className="mr-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 9L7.5 12.75L15 5.25"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span style={{ color: "white" }}>Submit</span>
          </button>

          <button
            className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
            style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
            onClick={async () => {
              setShowAddEditChargerModal(true);
              await setAddEditChargerData({
                ...addEditChargerData,
                loc_id: values.id,
              });
            }}
          >
            <span className="mr-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 8H10M7.75 5.75V10.25M1 8C1 8.88642 1.17459 9.76417 1.51381 10.5831C1.85303 11.4021 2.35023 12.1462 2.97703 12.773C3.60382 13.3998 4.34794 13.897 5.16689 14.2362C5.98583 14.5754 6.86358 14.75 7.75 14.75C8.63642 14.75 9.51417 14.5754 10.3331 14.2362C11.1521 13.897 11.8962 13.3998 12.523 12.773C13.1498 12.1462 13.647 11.4021 13.9862 10.5831C14.3254 9.76417 14.5 8.88642 14.5 8C14.5 7.11358 14.3254 6.23583 13.9862 5.41689C13.647 4.59794 13.1498 3.85382 12.523 3.22703C11.8962 2.60023 11.1521 2.10303 10.3331 1.76381C9.51417 1.42459 8.63642 1.25 7.75 1.25C6.86358 1.25 5.98583 1.42459 5.16689 1.76381C4.34794 2.10303 3.60382 2.60023 2.97703 3.22703C2.35023 3.85382 1.85303 4.59794 1.51381 5.41689C1.17459 6.23583 1 7.11358 1 8Z"
                  stroke="white"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span style={{ color: "white" }}>Add Charger</span>
          </button>
        </div>
      ) : (
        ""
      )}

      <Dialog
        open={confirmationDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {" "}
          <h1 className="font-lexend">
            Add Charger for the location? {values.stationName}
          </h1>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              props.handleClose();
              setConfirmationDialog(false);
            }}
            autoFocus
            fullWidth
          >
            Close
          </Button>
          <Button
            variant="contained"
            autoFocus
            fullWidth
            onClick={() => {
              setConfirmationDialog(false);
              setShowAddEditChargerModal(true);
            }}
          >
            Add Charger
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default AddEditModal;