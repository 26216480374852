// import { Autocomplete, Checkbox, Chip, TextField, Typography } from "@mui/material";
// import moment from "moment";
// import Popover from "@mui/material/Popover";
// import { memo, useEffect, useMemo, useCallback, useState } from "react";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import DatabaseTable from "./databasetable";
// import Widget from "./widget";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import "./transaction.scss";
// import Revenue from "../revenue";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
// interface optionObj {
//   id: string;
//   label: string;
// }
// interface UserFilter {
//   id: string;
//   label: string;
// }

// const Transaction = memo(() => {
//   const [tableLoading, setTableLoading] = useState(false);
//   const [isFilterVisible, setIsFilterVisible] = useState(false);
//   const [userFilterOn, setUserFilterOn] = useState(false);
//   const [userFilter, setUserFilter] = useState<UserFilter[]>([]);
//   const [typeFilter, setTypeFilter] = useState("");
//   const [filterData, setFilterData] = useState<any>([]);
//   const [tableData, setTableData] = useState<any>([]);
//   const [filterChips, setFilterChips] = useState<
//     { id: string; label: string }[]
//   >([]);

//   const [typeFilterChips, setTypeFilterChips] = useState<any[]>([]);
//   const [dateChips, setDateFilterChips] = useState<any[]>([]);
//   const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
//     null
//   );
//   const [userIds, setUserIds] = useState<any[]>([]);
//   const [TypeIds, setTypeIds] = useState<string>("");
//   const [dateFilter, setDateFilter] = useState("This Week");
//   const [selectedType, setSelectedType] = useState("");
//   const [startDateFilter, setStartDateFilter] = useState<any>("");
//   const [endDateFilter, setEndDateFilter] = useState<any>("");
//   const [filter, setFilter] = useState("");
//   const [dateRangeChipVisible, setDateRangeChipVisible] =
//     useState<boolean>(false);
//   const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
//   const [openCustom, setOpenCustom] = useState<boolean>(false);
//   const [singleStartDate, setStartDate] = useState<any>("");

//   // Pagination Code
//   const [pageNumber, setPageNumber] = useState(1);
//   const [itemsPerPage] = useState(100); // You can set the items per page here or pass it as a prop

//   const handlePreviousPage = () => {
//     if (pageNumber > 1) {
//       setPageNumber(pageNumber - 1);
//       getAllTransaction(pageNumber - 1, itemsPerPage, dateFilter);
//     }
//   };
  
//   const handleNextPage = () => {
//     if (pageNumber * itemsPerPage < totalTransactions) {
//       setPageNumber(pageNumber + 1);
//       getAllTransaction(pageNumber + 1, itemsPerPage, dateFilter);
//     }
//   };

//   // const handlePreviousPage = () => {
//   //   if (pageNumber > 1) {
//   //     setPageNumber(pageNumber - 1);
//   //   }
//   // };

//   // const handleNextPage = () => {
//   //   // In a real scenario, you would need to know the total number of pages
//   //   // to prevent going beyond the last page. For simplicity, I'll omit it here.
//   //   setPageNumber(pageNumber + 1);
//   // };

//   // Widget Data
//   const [walletBalance, setWalletBalance] = useState({
//     total: 0,
//   });
//   const [billedRevenue, setBilledRevenue] = useState<any>({
//     total: null,
//   });
//   const [transaction, setTransaction] = useState({
//     total: 0,
//   });

//   // ! ||--------------------------------------------------------------------------------||
//   // ! ||                         State to manage Custom checkbox                        ||
//   // ! ||--------------------------------------------------------------------------------||
//   const [isSelected, setIsSelected] = useState(false);

// // ! ||--------------------------------------------------------------------------------||
// // ! ||                        Filter Code For Mobile and Tablet                       ||
// // ! ||--------------------------------------------------------------------------------||
//   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

//   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "simple-popover" : undefined;

//   const handleCustomChange = () => {
//     setOpenCustom(true);
//   };

// // ! ||--------------------------------------------------------------------------------||
// // ! ||                                Filter Code Start                               ||
// // ! ||--------------------------------------------------------------------------------||

//   const handleUserChange = useCallback((event: any, newValue: any) => {
//     setFilterChips((prevChips) => {
//       const newChips = newValue.map((user: any) => ({
//         id: user.id,
//         label: `User: ${user.label}`,
//       }));

//       const filteredChips = newChips.filter(
//         (chip: any) => !prevChips.some((prevChip) => prevChip.id === chip.id)
//       );

//       return [...prevChips, ...filteredChips];
//     });

//     setUserFilter(newValue);
//     setUserFilterOn(true);
//   }, []);

//   const handleFilterButtonClick = () => {
//     // Toggle filter visibility
//     setIsFilterVisible((prev) => !prev);
//   };

  
//   const handleDeleteChip = (chipId: string) => {
//     // console.log("Deleting chip with id:", chipId);

//     // Remove the corresponding chip from the state
//     setFilterChips((prevChips) =>
//       prevChips.filter((chip) => chip.id !== chipId)
//     );

//     // Remove the user filter
//     setUserIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

//     setUserFilter((prevUserFilter: any) =>
//       prevUserFilter.filter((filter: any) => filter.id !== chipId)
//     );
//     // console.log("userFilter in delete", userFilter);
//   };

//   const handleDeleteTypeChip = (chipId: string) => {
//     // console.log("Deleting type chip with id:", chipId);

//     // Remove the corresponding chip from the state
//     setTypeFilterChips((prevChips) =>
//       prevChips.filter((chip) => chip.id !== chipId)
//     );

//     // Uncheck the radio button when deleting the chip
//     setSelectedType("");
//   };
//   const handleDateRangeDelete = () => {
//     setStartDateFilter(null);
//     setEndDateFilter(null);
//     setDateRangeChipVisible(false);
//   };
//   const handleDeleteDateChip = (chipId: string) => {
//     // console.log("Deleting type chip with id:", chipId);
//     setDateFilterChips((prevChips) =>
//       prevChips.filter((chip) => chip.id !== chipId)
//     );
//     setDateFilter("");

//     // Uncheck the radio button when deleting the chip
//     setSelectedType("");
//   };
//   const handleSingleDeleteDateChip = (chipId: string) => {
//     setStartDateFilter(null);
//     setEndDateFilter(null);
//     setSingleDateFilterChips(null);
//   };

//   const handleTypeChange = (value: string) => {
//     // Find if the selected type is already in the chips
//     const existingChip = typeFilterChips.find((chip) => chip.label === value);

//     // If the selected type is not in the chips, update the chips array
//     if (!existingChip) {
//       // Remove the previous type chip if it exists
//       const updatedChips = typeFilterChips.filter(
//         (chip) => chip.id !== selectedType
//       );

//       // Add the selected type to the chips array
//       setTypeFilterChips([...updatedChips, { id: value, label: value }]);

//       // Update the selectedType state
//       setSelectedType(value);
//     }
//   };

//   // const handleDateChange = (value: string) => {
//   //   if (value === "Custom") {
//   //     setIsSelected(!isSelected);
//   //     setOpenCustom((prevOpenCustom) => !prevOpenCustom);
//   //     setDateFilterChips([]);
//   //     setDateFilter("");
//   //   } else {
//   //     setOpenCustom(false);
//   //     // Update the dateFilter state
//   //     setDateFilter(value);
//   //     // Update the dateFilterChips array based on the selected value
//   //     const selectedChip = {
//   //       id: value,
//   //       label:
//   //         value === "Today"
//   //           ? "Today"
//   //           : value === "This Month"
//   //           ? "This Month"
//   //           : "This Year",
//   //     };
//   //     setDateFilterChips([selectedChip]);
//   //   }
//   // };
//   const handleDateChange = (value: string) => {
//     if (value === "Custom") {
//       setIsSelected(!isSelected);
//       setOpenCustom((prevOpenCustom) => !prevOpenCustom);
//       setDateFilterChips([]);
//       setDateFilter("");
//       // Clear custom date range when selecting "Custom"
//       setStartDateFilter(null);
//       setEndDateFilter(null);
//       setDateRangeChipVisible(false);
//     } else {
//       setOpenCustom(false);
//       // Update the dateFilter state
//       setDateFilter(value);
//       // Update the dateFilterChips array based on the selected value
//       const selectedChip = {
//         id: value,
//         label:
//           value === "Today"
//             ? "Today"
//             : value === "This Month"
//             ? "This Month"
//             : "This Year",
//       };
//       setDateFilterChips([selectedChip]);
//       // Clear custom date range when selecting predefined filters
//       setStartDateFilter(null);
//       setEndDateFilter(null);
//       setDateRangeChipVisible(false);
//       setIsSelected(false);
//     }
//   };
  


  
  
//   const dateRangeChange = (dates: any) => {
//     const [start, end] = dates;
//     setStartDateFilter(start);
//     setEndDateFilter(end);
//     setDateRangeChipVisible(!!start && !!end);
//     setSingleDateFilterChips(start);
//   };

//   const [totalTransactions, setTotalTransactions] = useState(0);

// // ! ||--------------------------------------------------------------------------------||
// // ! ||                               Revenue Widget Code                              ||
// // ! ||--------------------------------------------------------------------------------||

//   const getRevenueData = async () => {
//     const ChargeSession = Parse.Object.extend("ChargeSession");
//     const parseQuery = new Parse.Query(ChargeSession);

//     // parseQuery.limit(1000);
//     if (userIds.length > 0) {
//       parseQuery.containedIn("User", userIds);
//     }

//     // if (dateFilter) {
//     //   if (dateFilter === "Today") {
//     //     parseQuery.greaterThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().startOf("day").toString())
//     //     );
//     //     parseQuery.lessThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().add(1, "day").startOf("day").toString())
//     //     );
//     //   } else if (dateFilter === "This Month") {
//     //     parseQuery.greaterThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().startOf("month").toString())
//     //     );
//     //     parseQuery.lessThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().endOf("month").toString())
//     //     );
//     //   } else if (dateFilter === "This Week") {
//     //     parseQuery.greaterThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().startOf("week").toString())
//     //     );
//     //     parseQuery.lessThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().endOf("week").toString())
//     //     );
//     //   } else if (dateFilter === "This Year") {
//     //     parseQuery.greaterThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().startOf("year").toString())
//     //     );
//     //     parseQuery.lessThanOrEqualTo(
//     //       "createdAt",
//     //       new Date(moment().endOf("year").toString())
//     //     );
//     //   }
//     // }
//     if (dateFilter === "This Week") {
//       const startOfCurrentWeek = moment().startOf("week").add(1, "day"); // Start from Monday
//       const endOfCurrentWeek = moment().endOf("week");

//       parseQuery.greaterThanOrEqualTo("createdAt", startOfCurrentWeek.toDate());
//       parseQuery.lessThanOrEqualTo("createdAt", endOfCurrentWeek.toDate());
//       const count = await parseQuery?.count();

//       // setTotalTransactions(count);
//       // setTransaction({
//       //   total: count,
//       // });
//     } else if (dateFilter === "Today") {
//       parseQuery.greaterThanOrEqualTo(
//         "createdAt",
//         new Date(moment().startOf("day").toString())
//       );
//       parseQuery.lessThanOrEqualTo(
//         "createdAt",
//         new Date(moment().add(1, "day").startOf("day").toString())
//       );
//     } else if (dateFilter === "This Month") {
//       parseQuery.greaterThanOrEqualTo(
//         "createdAt",
//         new Date(moment().startOf("month").toString())
//       );
//       parseQuery.lessThanOrEqualTo(
//         "createdAt",
//         new Date(moment().endOf("month").toString())
//       );
//     } else if (dateFilter === "This Year") {
//       parseQuery.greaterThanOrEqualTo(
//         "createdAt",
//         new Date(moment().startOf("year").toString())
//       );
//       parseQuery.lessThanOrEqualTo(
//         "createdAt",
//         new Date(moment().endOf("year").toString())
//       );
//     }

//     if (startDateFilter) {
//       // Apply single date filter
//       parseQuery.greaterThanOrEqualTo(
//         "createdAt",
//         new Date(moment(startDateFilter).startOf("day").toString())
//       );
//       parseQuery.lessThanOrEqualTo(
//         "createdAt",
//         new Date(moment(startDateFilter).endOf("day").toString())
//       );
//       const result = await parseQuery.find();
//       result.map((item) => ({
//         id: item.id,
//         customer: `${item.get("User")?.get("FullName")}`,
//         type: `${item.get("Type")}`,
//         razorPayId: item.get("RazorPayId"),
//         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//         detail: `${item.get("Detail")}`,
//         createdAt: `${
//           moment(item.get("createdAt")).format("lll")
//             ? moment(item.get("createdAt")).format("lll")
//             : "-"
//         }`,
//       }));
//     }

//     if (startDateFilter && endDateFilter) {
//       parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
//       parseQuery.lessThanOrEqualTo("createdAt", new Date(endDateFilter));
//       const result = await parseQuery.find();
//       result.map((item) => ({
//         id: item.id,
//         customer: `${item.get("User")?.get("FullName")}`,
//         type: `${item.get("Type")}`,
//         razorPayId: item.get("RazorPayId"),
//         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//         detail: `${item.get("Detail")}`,
//         createdAt: `${
//           moment(item.get("createdAt")).format("lll")
//             ? moment(item.get("createdAt")).format("lll")
//             : "-"
//         }`,
//       }));
//     }
//     parseQuery.limit(5000);

//     parseQuery.find().then((result) => {
//       let totalEnergy = 0;

//       let totalMoney = 0;

//       let totalSession = 0;

//       result.forEach((item, index) => {
//         totalEnergy = item.get("TotalEnergyConsumed")
//           ? item.get("TotalEnergyConsumed") + totalEnergy
//           : 0 + totalEnergy;
//         totalMoney = item.get("TotalCost")
//           ? item.get("TotalCost") + totalMoney
//           : 0 + totalMoney;
//         totalSession = totalSession + 1;
//       });
//       if (selectedType === "Credit") {
//         setBilledRevenue({
//           total: null,
//         });
//       } else {
//         setBilledRevenue({
//           total: totalMoney,
//         });
//       }
//     });
//   };

  

// // ! ||--------------------------------------------------------------------------------||
// // ! ||                               Wallet Widget Code                               ||
// // ! ||--------------------------------------------------------------------------------||

//     const userWalletData = async () => {
//       const parseQuery = new Parse.Query("_User");
//       parseQuery.notEqualTo("UserType", "Cloud");

//       parseQuery.limit(5000);
//       // parseQuery.containedIn("objectId", userIds);
//       if (userIds.length > 0) {
//         // parseQuery.containedIn("objectId", userIds);
//         parseQuery.containedIn("objectId", userIds);
//       }

//       let totalCredited = 0;

//       parseQuery.find().then((result: any[]) => {
//         result.forEach((item) => {
//           // console.log("item credit",item)
//           totalCredited = item.get("Credit")
//             ? totalCredited + item.get("Credit")
//             : totalCredited + 0;
//         });

//         setWalletBalance({
//           total: totalCredited,
//         });
//       });
//     };

// // ! ||--------------------------------------------------------------------------------||
// // ! ||                  Both Get All Transaction and Filter Data Code                 ||
// // ! ||--------------------------------------------------------------------------------||

// const capitalizeFirstLetterOfEachWord = (str:string) => {
//   // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
//   return str
//   .toLowerCase()
//   .replace(/\b\w/g, (char:any) => char.toUpperCase());
// };

//   // const getAllTransaction = async (
//   //   pageNumber: number,
//   //   itemsPerPage: number,
//   //   dateFilter: string
//   // ) => {
//   //   try {
//   //     let filteredData = [];
//   //     setTableLoading(true);
//   //     if (dateFilter === "This Week") {
//   //       setDateFilterChips([{ id: "This Week", label: "This Week" }]);
//   //     }
//   //     const parseQuery = new Parse.Query("Transactions");
//   //     parseQuery.include("User");
//   //     parseQuery.descending("createdAt");
//   //     // let totalTransactions = 0;

//   //     let totalTransactions = await parseQuery.count();
//   //     setTotalTransactions(totalTransactions);
//   //     setTransaction({
//   //       total: totalTransactions,
//   //     });

//   //     // Calculate skip value

//   //     // parseQuery.limit(5500);
//   //     // Apply user filter

//   //     if (dateFilter !== "This Week") {
//   //       parseQuery.limit(1000);
//   //       // setDateFilterChips([]);
//   //     }

//   //     if (userIds && userIds.length > 0) {
//   //       parseQuery.containedIn("User", userIds);
//   //       const count = await parseQuery?.count();

//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //     }
//   //     if (filter) {
//   //       parseQuery.limit(50000); // Apply filter to entire dataset without pagination
//   //       parseQuery.matches("RazorPayId", new RegExp(filter, "i"));
//   //       const count = await parseQuery?.count();

//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //     } else if (typeFilterChips && typeFilterChips.length > 0) {
//   //       parseQuery.limit(50000);
//   //     } else if (dateFilter) {
//   //       parseQuery.limit(50000);
//   //     } else if (startDateFilter) {
//   //       parseQuery.limit(50000);
//   //     } else if (endDateFilter) {
//   //       parseQuery.limit(50000);
//   //     } else {
//   //       const skip = (pageNumber - 1) * itemsPerPage;
//   //       // Set skip and limit for pagination
//   //       parseQuery.skip(skip);
//   //       parseQuery.limit(itemsPerPage);
//   //     }
//   //     let result = await parseQuery.find();
//   //     if (typeFilterChips && typeFilterChips.length > 0) {
//   //       parseQuery.limit(50000);
//   //       parseQuery.equalTo("Type", selectedType);
//   //       // const count = await parseQuery.count();

//   //       const typeIds = typeFilterChips.map((chip) => chip.label);
//   //       filteredData = result
//   //         .map((item) => ({
//   //           id: item.id,
//   //           customer: `${item.get("User")?.get("FullName")}`,
//   //           type: `${item.get("Type")}`,
//   //           razorPayId: item.get("RazorPayId"),
//   //           amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //           detail: `${item.get("Detail")}`,
//   //           createdAt: `${
//   //             moment(item.get("createdAt")).format("lll")
//   //               ? moment(item.get("createdAt")).format("lll")
//   //               : "-"
//   //           }`,
//   //         }))
//   //         .filter((item) => typeIds.includes(item.type));
//   //       setTotalTransactions(filteredData.length);
//   //       setTransaction({
//   //         total: filteredData.length,
//   //       });
//   //     } else {
//   //       let result = await parseQuery.find();

//   //       // If no type filter or no user filter, set the data without type filter
//   //       filteredData = result.map((item) => ({
//   //         id: item.id,
//   //         customer: `${item.get("User")?.get("FullName")}`,
//   //         type: `${item.get("Type")}`,
//   //         razorPayId: item.get("RazorPayId"),
//   //         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //         detail: `${item.get("Detail")}`,
//   //         createdAt: `${
//   //           moment(item.get("createdAt")).format("lll")
//   //             ? moment(item.get("createdAt")).format("lll")
//   //             : "-"
//   //         }`,
//   //       }));
//   //     }
//   //     if (dateFilter === "This Week") {
//   //       const startOfCurrentWeek = moment().startOf("week").add(1, "day"); // Start from Monday
//   //       const endOfCurrentWeek = moment().endOf("week");

//   //       parseQuery.greaterThanOrEqualTo(
//   //         "createdAt",
//   //         startOfCurrentWeek.toDate()
//   //       );
//   //       parseQuery.lessThanOrEqualTo("createdAt", endOfCurrentWeek.toDate());
//   //       const count = await parseQuery?.count();

//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //     } else if (dateFilter === "Today") {
//   //       parseQuery.greaterThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment().startOf("day").toString())
//   //       );
//   //       parseQuery.lessThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment().add(1, "day").startOf("day").toString())
//   //       );
//   //       const count = await parseQuery?.count();

//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //     } else if (dateFilter === "This Month") {
//   //       parseQuery.greaterThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment().startOf("month").toString())
//   //       );
//   //       parseQuery.lessThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment().endOf("month").toString())
//   //       );
//   //       const count = await parseQuery?.count();

//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //     } else if (dateFilter === "This Year") {
//   //       parseQuery.greaterThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment().startOf("year").toString())
//   //       );
//   //       parseQuery.lessThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment().endOf("year").toString())
//   //       );
//   //       const count = await parseQuery?.count();

//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //     }
//   //     if (dateFilter && userIds && userIds.length > 0) {
//   //       const dateQuery = new Parse.Query("Transactions");
//   //       dateQuery.include("User");
//   //       dateQuery.descending("createdAt");
//   //       parseQuery.limit(4500);
//   //       if (dateFilter === "Today") {
//   //         // console.log("today", dateFilter);

//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           new Date(moment().startOf("day").toString())
//   //         );
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           new Date(moment().add(1, "day").startOf("day").toString())
//   //         );
//   //       } else if (dateFilter === "This Week") {
//   //         const startOfPreviousWeek = moment()
//   //           .subtract(1, "week")
//   //           .startOf("week");
//   //         const endOfCurrentWeek = moment().endOf("week");

//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           startOfPreviousWeek.toDate()
//   //         );
//   //         dateQuery.lessThanOrEqualTo("createdAt", endOfCurrentWeek.toDate());
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           new Date(moment().endOf("week").toString())
//   //         );
//   //       } else if (dateFilter === "This Month") {
//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           new Date(moment().startOf("month").toString())
//   //         );
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           new Date(moment().endOf("month").toString())
//   //         );
//   //       } else if (dateFilter === "This Year") {
//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           new Date(moment().startOf("year").toString())
//   //         );
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           new Date(moment().endOf("year").toString())
//   //         );
//   //       }

//   //       if (userIds && userIds.length > 0) {
//   //         dateQuery.containedIn("User", userIds);
//   //       }

//   //       const dateFilteredResult = await dateQuery.find();
//   //       filteredData = dateFilteredResult.map((item) => ({
//   //         id: item.id,
//   //         customer: `${item.get("User")?.get("FullName")}`,
//   //         type: `${item.get("Type")}`,
//   //         razorPayId: item.get("RazorPayId"),
//   //         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //         detail: `${item.get("Detail")}`,
//   //         createdAt: `${
//   //           moment(item.get("createdAt")).format("lll")
//   //             ? moment(item.get("createdAt")).format("lll")
//   //             : "-"
//   //         }`,
//   //       }));
//   //     }
//   //     if (dateFilter && typeFilterChips && typeFilterChips.length > 0) {
//   //       const dateQuery = new Parse.Query("Transactions");
//   //       dateQuery.include("User");
//   //       dateQuery.descending("createdAt");
//   //       dateQuery.equalTo("Type", selectedType);
//   //       dateQuery.limit(5000);
//   //       // Apply date filter
//   //       if (dateFilter === "Today") {
//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           moment().startOf("day").toDate()
//   //         );
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           moment().endOf("day").toDate()
//   //         );
//   //         const count = await dateQuery?.count();

//   //         setTotalTransactions(count);
//   //         setTransaction({
//   //           total: count,
//   //         });
//   //       } else if (dateFilter === "This Week") {
//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           moment().startOf("week").toDate()
//   //         );
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           moment().endOf("week").toDate()
//   //         );
//   //         const count = await dateQuery?.count();

//   //         setTotalTransactions(count);
//   //         setTransaction({
//   //           total: count,
//   //         });
//   //       } else if (dateFilter === "This Month") {
//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           moment().startOf("month").toDate()
//   //         );
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           moment().endOf("month").toDate()
//   //         );
//   //         const count = await dateQuery?.count();

//   //         setTotalTransactions(count);
//   //         setTransaction({
//   //           total: count,
//   //         });
//   //       } else if (dateFilter === "This Year") {
//   //         dateQuery.greaterThanOrEqualTo(
//   //           "createdAt",
//   //           moment().startOf("year").toDate()
//   //         );
//   //         dateQuery.lessThanOrEqualTo(
//   //           "createdAt",
//   //           moment().endOf("year").toDate()
//   //         );
//   //         const count = await dateQuery?.count();

//   //         setTotalTransactions(count);
//   //         setTransaction({
//   //           total: count,
//   //         });
//   //       }

//   //       // Apply type filter
//   //       const typeIds = typeFilterChips.map((chip) => chip.label);

//   //       // Apply userIds filter
//   //       if (userIds && userIds.length > 0) {
//   //         dateQuery.containedIn("User", userIds);
//   //       }

//   //       const dateTypeFilteredResult = await dateQuery.find();

//   //       filteredData = dateTypeFilteredResult.map((item) => ({
//   //         id: item.id,
//   //         customer: `${item.get("User")?.get("FullName")}`,
//   //         type: `${item.get("Type")}`,
//   //         razorPayId: item.get("RazorPayId"),
//   //         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //         detail: `${item.get("Detail")}`,
//   //         createdAt: moment(item.get("createdAt")).format("lll") || "-",
//   //       }));

//   //       setTotalTransactions(filteredData.length);
//   //       setTransaction({
//   //         total: filteredData.length,
//   //       });
//   //     }

//   //     if (startDateFilter) {
//   //       // Apply single date filter
//   //       parseQuery.greaterThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment(startDateFilter).startOf("day").toString())
//   //       );
//   //       parseQuery.lessThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment(startDateFilter).endOf("day").toString())
//   //       );
//   //       const count = await parseQuery.count();
//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //       const singleDateResult = await parseQuery.find();
//   //       filteredData = singleDateResult.map((item) => ({
//   //         id: item.id,
//   //         customer: `${item.get("User")?.get("FullName")}`,
//   //         type: `${item.get("Type")}`,
//   //         razorPayId: item.get("RazorPayId"),
//   //         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //         detail: `${item.get("Detail")}`,
//   //         createdAt: `${
//   //           moment(item.get("createdAt")).format("lll")
//   //             ? moment(item.get("createdAt")).format("lll")
//   //             : "-"
//   //         }`,
//   //       }));
//   //     }
//   //     if (startDateFilter && typeFilterChips && typeFilterChips.length > 0) {
//   //       // Construct a query to filter by startDateFilter
//   //       const dateQuery = new Parse.Query("Transactions");
//   //       dateQuery.include("User");
//   //       dateQuery.descending("createdAt");
//   //       dateQuery.limit(5000);
//   //       // Apply startDateFilter
//   //       dateQuery.greaterThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment(startDateFilter).startOf("day").toString())
//   //       );
//   //       dateQuery.lessThanOrEqualTo(
//   //         "createdAt",
//   //         new Date(moment(startDateFilter).endOf("day").toString())
//   //       );

//   //       // Apply type filter
//   //       const typeIds = typeFilterChips.map((chip) => chip.label);
//   //       dateQuery.containedIn("Type", typeIds);

//   //       // Apply userIds filter if needed
//   //       if (userIds && userIds.length > 0) {
//   //         dateQuery.containedIn("User", userIds);
//   //       }

//   //       const count = await dateQuery.count();
//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });

//   //       const filteredResult = await dateQuery.find();
//   //       filteredData = filteredResult.map((item) => ({
//   //         id: item.id,
//   //         customer: `${item.get("User")?.get("FullName")}`,
//   //         type: `${item.get("Type")}`,
//   //         razorPayId: item.get("RazorPayId"),
//   //         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //         detail: `${item.get("Detail")}`,
//   //         createdAt: moment(item.get("createdAt")).format("lll") || "-",
//   //       }));
//   //     }

//   //     if (startDateFilter && endDateFilter) {
//   //       parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
//   //       const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
//   //       parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
//   //       parseQuery.limit(5000);
//   //       const count = await parseQuery?.count();

//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });
//   //       // parseQuery.lessThanOrEqualTo("createdAt", new Date(endDateFilter));
//   //       const finalResult = await parseQuery.find();
//   //       filteredData = finalResult.map((item) => ({
//   //         id: item.id,
//   //         customer: `${item.get("User")?.get("FullName")}`,
//   //         type: `${item.get("Type")}`,
//   //         razorPayId: item.get("RazorPayId"),
//   //         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //         detail: `${item.get("Detail")}`,
//   //         createdAt: `${
//   //           moment(item.get("createdAt")).format("lll")
//   //             ? moment(item.get("createdAt")).format("lll")
//   //             : "-"
//   //         }`,
//   //       }));
//   //     }
//   //     //  Filter - (Date Range With Type)
//   //     if (
//   //       startDateFilter &&
//   //       endDateFilter &&
//   //       typeFilterChips &&
//   //       typeFilterChips.length > 0
//   //     ) {
//   //       // Construct a query to filter by startDateFilter and endDateFilter
//   //       const dateQuery = new Parse.Query("Transactions");
//   //       dateQuery.include("User");
//   //       dateQuery.descending("createdAt");
//   //       dateQuery.limit(5000);

//   //       // Apply startDateFilter and endDateFilter
//   //       dateQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
//   //       dateQuery.lessThanOrEqualTo("createdAt", new Date(endDateFilter));

//   //       // Apply type filter
//   //       const typeIds = typeFilterChips.map((chip) => chip.label);
//   //       dateQuery.containedIn("Type", typeIds);

//   //       // Apply userIds filter if needed
//   //       if (userIds && userIds.length > 0) {
//   //         dateQuery.containedIn("User", userIds);
//   //       }

//   //       const count = await dateQuery.count();
//   //       setTotalTransactions(count);
//   //       setTransaction({
//   //         total: count,
//   //       });

//   //       // Fetch filtered results
//   //       const filteredResult = await dateQuery.find();

//   //       // Map and filter the results based on typeIds
//   //       filteredData = filteredResult
//   //         .map((item) => ({
//   //           id: item.id,
//   //           customer: `${item.get("User")?.get("FullName")}`,
//   //           type: `${item.get("Type")}`,
//   //           razorPayId: item.get("RazorPayId"),
//   //           amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //           detail: `${item.get("Detail")}`,
//   //           createdAt: moment(item.get("createdAt")).format("lll") || "-",
//   //         }))
//   //         .filter((item) => typeIds.includes(item.type));
//   //     }

//   //     // new pagination code
//   //     // Calculate skip value for pagination
//   //     const skip = (pageNumber - 1) * itemsPerPage;
//   //     parseQuery.skip(skip);
//   //     parseQuery.limit(itemsPerPage);

//   //     // Fetch filtered results
//   //     result = await parseQuery.find();
//   //     const startIndex = (pageNumber - 1) * itemsPerPage + 1;
//   //     // Map the results
//   //     filteredData = result.map((item, index) => ({
//   //       sno: startIndex + index,
//   //       id: item.id,
//   //       customer: `${item.get("User")?.get("FullName")}`,
//   //       type: `${item.get("Type")}`,
//   //       razorPayId: item.get("RazorPayId"),
//   //       amount: `₹${item.get("Amount")?.toFixed(2)}`,
//   //       detail: `${item.get("Detail")}`,
//   //       createdAt: moment(item.get("createdAt")).format("lll") || "-",
//   //     }));

//   //     const totalTransaction = filteredData.length;

//   //     setTableLoading(false);

//   //     // setTransaction({
//   //     //   total: totalTransactions,
//   //     // });

//   //     console.log("filteredData", filteredData);

//   //     setTableData(filteredData);
//   //   } catch (error) {
//   //     console.error("Error fetching data:", error);
//   //   }
//   // };
  
//   const getAllTransaction = async (
//     pageNumber: number,
//     itemsPerPage: number,
//     dateFilter: string
//   ) => {
//     try {
//       setTableLoading(true);
//       if (dateFilter === "This Week") {
//               setDateFilterChips([{ id: "This Week", label: "This Week" }]);
//             }
//       // Initialize base query
//       const parseQuery = new Parse.Query("Transactions");
//       parseQuery.include("User");
//       parseQuery.descending("createdAt");
  
//       // Apply user filter if any
//       if (userIds && userIds.length > 0) {
//         parseQuery.containedIn("User", userIds);
//       }
  
//       // Apply type filter if any
//       if (selectedType) {
//         parseQuery.equalTo("Type", selectedType);
//       }
  
//       // Apply search filter if any
//       if (filter) {
//         parseQuery.matches("RazorPayId", new RegExp(filter, "i"));
//       }
  
//       // Apply date filters
//       if (dateFilter === "Today") {
//         parseQuery.greaterThanOrEqualTo("createdAt", moment().startOf("day").toDate());
//         parseQuery.lessThanOrEqualTo("createdAt", moment().endOf("day").toDate());
//       } else if (dateFilter === "This Week") {
//         parseQuery.greaterThanOrEqualTo("createdAt", moment().startOf("week").toDate());
//         parseQuery.lessThanOrEqualTo("createdAt", moment().endOf("week").toDate());
//       } else if (dateFilter === "This Month") {
//         parseQuery.greaterThanOrEqualTo("createdAt", moment().startOf("month").toDate());
//         parseQuery.lessThanOrEqualTo("createdAt", moment().endOf("month").toDate());
//       } else if (dateFilter === "This Year") {
//         parseQuery.greaterThanOrEqualTo("createdAt", moment().startOf("year").toDate());
//         parseQuery.lessThanOrEqualTo("createdAt", moment().endOf("year").toDate());
//       } else if (startDateFilter && endDateFilter) {
//         parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
//         parseQuery.lessThanOrEqualTo("createdAt", moment(endDateFilter).endOf("day").toDate());
//       } else if (startDateFilter) {
//         parseQuery.greaterThanOrEqualTo("createdAt", new Date(moment(startDateFilter).startOf("day").toString()));
//         parseQuery.lessThanOrEqualTo("createdAt", new Date(moment(startDateFilter).endOf("day").toString()));
//       }
  
//       // First get the total count
//       const totalTransactions = await parseQuery.count();
//       setTotalTransactions(totalTransactions);
//       setTransaction({ total: totalTransactions });
  
//       // Apply pagination
//       const skip = (pageNumber - 1) * itemsPerPage;
//       parseQuery.skip(skip);
//       parseQuery.limit(itemsPerPage);
  
//       // Execute query
//       const result = await parseQuery.find();
  
//       // Format results
//       const filteredData = result.map((item, index) => ({
//         sno: skip + index + 1,
//         id: item.id,
//         customer: `${item.get("User")?.get("FullName")}`,
//         type: `${item.get("Type")}`,
//         razorPayId: item.get("RazorPayId"),
//         amount: `₹${item.get("Amount")?.toFixed(2)}`,
//         detail: `${item.get("Detail")}`,
//         createdAt: moment(item.get("createdAt")).format("lll") || "-",
//       }));
  
//       setTableData(filteredData);
//       setTableLoading(false);
    
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setTableLoading(false);
//     }
//   };

//   useEffect(() => {
//     getAllTransaction(1, itemsPerPage, dateFilter);
//     userWalletData();
//     getRevenueData();
//   }, [
//     userIds,
//     typeFilterChips,
//     filter,
//     dateFilter,
//     startDateFilter,
//     endDateFilter,
//     // pageNumber,
//   ]);


// // ! ||--------------------------------------------------------------------------------||
// // ! ||                             Get All User For Filter                            ||
// // ! ||--------------------------------------------------------------------------------||

//   const [allUsers, setAllUsers] = useState<any | null>([]);
//   // const getAllUsers = useMemo(
//   //   () => async () => {
//   //     setLoadingUsers(true);
//   //     try {
//   //       const parseQuery = new Parse.Query("ChargeSession");
//   //       parseQuery.include("User");
//   //       parseQuery.limit(5000);
//   //       const result = await parseQuery.find();

//   //       let usersMap = new Map();
//   //       const ocppPromises = result.map(async (item) => {
//   //           const user = item.get("User");
//   //           if (user && !usersMap.has(user.id)) {
//   //               usersMap.set(user.id, {
//   //                   id: user.id,
//   //                   label: user.get("FullName")
//   //               });
//   //           }
//   //       });

//   //       await Promise.all(ocppPromises);

//   //       setAllUsers(Array.from(usersMap.values()));
//   //     } catch (error) {
//   //       console.error("Error fetching users:", error);
//   //     } finally {
//   //       setLoadingUsers(false);
//   //     }
//   //   },
//   //   []
//   // );
//   const getAllUsers = async () => {
//     try {
//       const User = Parse.Object.extend("User");
//       const parseQuery = new Parse.Query(User);
//       parseQuery.notEqualTo("UserType", "Cloud");
//       parseQuery.limit(4500);
//       const result = await parseQuery.find();
      
//       let usersArray: optionObj[] = [];
//       result.forEach((item) => {
//         usersArray.push({
//           id: item.id,
//           label: item.get("FullName"),
//         });
//       });
//       console.log("usersArray",usersArray);
      
//       setAllUsers(usersArray);
//     } catch (error) {
//       console.error("Error fetching users:", error);
//     } 
//   };
//   useEffect(() => {
//     getAllUsers();
//   }, []);

//   useEffect(() => {
//     if (Array.isArray(userFilter)) {
//       const userIds = userFilter.map((user: any) => user.id);
//       // console.log("users Id", userIds);
//       setUserIds(userIds);

//       if (userIds.length > 0) {
//         // Your logic when userFilter is an array
//       }
//     } else if (userFilter && typeof userFilter === "object") {
//       const userId = (userFilter as { id?: string }).id;
//       console.log("user Id", userId);
//       setUserIds(userId ? [userId] : []);

//       // Your logic when userFilter is an object
//     } else {
//       // Handle other cases when userFilter is not an array or object
//     }
//   }, [userFilter, typeFilter, dateFilter, startDateFilter, endDateFilter]);



//   return (
//     <div className="transaction-container">
//       <div className="c flex">
//         <div style={{ width: "100%" }}>
//           <div className="flex justify-between mx-6 mt-5 ">
//             <h2 className="transaction_heading mt-2">Transaction</h2>
//             <button
//               className="filter_button rounded-full p-3 border-2 border-black-600"
//               onClick={handleFilterButtonClick}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//               >
//                 <path
//                   d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
//                   stroke="#111111"
//                   stroke-width="1.3"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                 />
//               </svg>
//             </button>
//             {/* Modal Button */}
//             <button
//               aria-describedby={id}
//               onClick={handleClick}
//               className="modal_button_filter rounded-full p-3 border-2 border-black-600"
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//               >
//                 <path
//                   d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
//                   stroke="#111111"
//                   stroke-width="1.3"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                 />
//               </svg>
//             </button>
//             <Popover
//               id={id}
//               open={open}
//               anchorEl={anchorEl}
//               onClose={handleClose}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//             >
//               <div className="filters flex flex-col  border-2 border-black-400">
//                 <div className="filter_header sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
//                   <h1 className="font-semibold font-lexend text-lg">Filters</h1>
//                   <button
//                     style={{
//                       background: "#1AC47D",
//                       width: "35px",
//                       height: "35px",
//                       padding: "5px",
//                       borderRadius: "50%",
//                       border: "3px solid #1AAD70",
//                     }}
//                     disabled
//                   >
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="16"
//                       height="16"
//                       viewBox="0 0 16 16"
//                       fill="none"
//                     >
//                       <path
//                         d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
//                         stroke="white"
//                         stroke-width="1.33333"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                       />
//                     </svg>
//                   </button>
//                 </div>

//                 <div className="filter_main_container px-4">
//                   <h1 className="font-semibold font-lexend text-lg mb-2">
//                     Users
//                   </h1>

//                   <Autocomplete
//                     className="mb-4"
//                     freeSolo
//                     id="checkboxes-tags-demo"
//                     disableClearable
//                     multiple
//                     limitTags={-1}
//                     options={allUsers}
//                     loading={loadingUsers}
//                     autoHighlight
//                     size="small"
//                     value={userFilter} // Set the value to control the selected users
//                     onChange={(event: any, newValue: any) => {
//                       setFilterChips(
//                         (prevChips: { id: string; label: string }[]) => {
//                           // Ensure that newValue is an array of objects with 'id' property
//                           const newChips: { id: string; label: string }[] =
//                             newValue.map((user: any) => ({
//                               id: user.id,
//                               label: `User: ${user.label}`,
//                             }));

//                           const filteredChips = newChips.filter(
//                             (chip) =>
//                               !prevChips.some(
//                                 (prevChip) => prevChip.id === chip.id
//                               )
//                           );

//                           return [...prevChips, ...filteredChips];
//                         }
//                       );

//                       // Update the userFilter state with the array of selected users
//                       setUserFilter(newValue);

//                       setUserFilterOn(true);
//                     }}
//                     isOptionEqualToValue={(option, value) =>
//                       option.id === value.id
//                     }
//                     getOptionLabel={(option: any) => option.label}
//                     getOptionDisabled={(option) =>
//                       filterChips.some((chip) => chip.id === option.id)
//                     }
//                     renderOption={(props, option) => {
//                       let counter = 0;
//                       const uniqueKey = `${option.id}_${
//                         option.label
//                       }_${counter++}`;
//                       const selected = filterChips.some(
//                         (chip) => chip.id === option.id
//                       );
//                       // console.log("selected", selected);

//                       return (
//                         <MenuItem key={uniqueKey} {...props}>
//                           <Checkbox
//                             icon={icon}
//                             checkedIcon={checkedIcon}
//                             checked={
//                               selected ||
//                               filterChips.some((chip) => chip.id === option.id)
//                             }
//                           />
//                           <ListItemText primary={option.label} />
//                         </MenuItem>
//                       );
//                     }}
//                     renderInput={(params: any) => (
//                       <TextField {...params} label="Search" />
//                     )}
//                   />

//                   <h1 className="font-semibold font-lexend text-lg">Type</h1>
//                   <div className="flex">
//                     <input
//                       type="radio"
//                       className="mr-3 font-lexend "
//                       onChange={() => handleTypeChange("Credit")}
//                       checked={selectedType === "Credit"}
//                     />
//                     <label htmlFor="" className="font-lexend">
//                       Credit
//                     </label>
//                   </div>

//                   <div className="flex">
//                     <input
//                       type="radio"
//                       className="mr-3 font-lexend"
//                       onChange={() => handleTypeChange("Debit")}
//                       checked={selectedType === "Debit"}
//                     />
//                     <label htmlFor="" className="font-lexend">
//                       Debit
//                     </label>
//                   </div>

//                   <h1 className="font-semibold mt-5 mb-2 font-lexend text-lg">
//                     Transaction ID
//                   </h1>
//                   <TextField
//                     id="outlined-search"
//                     label="Search Transaction ID"
//                     type="text"
//                     size="small"
//                     sx={{ width: "100%", marginBottom: 5 }}
//                     onChange={(e) => setFilter(e.target.value)}
//                   />
//                   {/* <div className="Payment_Mode_container">
//                       <h1 className="font-semibold mt-5 font-lexend">Payment Modes</h1>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">UPI</label>
//                       </div>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">Debit Card</label>
//                       </div>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">Credit Card</label>
//                       </div>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">Internet Banking</label>
//                       </div>
          
//                       </div> */}
//                   <div className="date_filter_container -mt-5">
//                     <h1 className="font-semibold font-lexend text-lg">Date</h1>
//                     <div className="flex">
//                       <input
//                         type="radio"
//                         className="mr-3"
//                         checked={dateFilter === "Today"}
//                         onChange={() => handleDateChange("Today")}
//                       />
//                       <label htmlFor="" className="font-lexend">
//                         Today
//                       </label>
//                     </div>
//                     <div className="flex">
//                       <input
//                         type="radio"
//                         className="mr-3"
//                         checked={dateFilter === "This Week"}
//                         onChange={() => handleDateChange("This Week")}
//                       />
//                       <label htmlFor="" className="font-lexend">
//                         This Week
//                       </label>
//                     </div>
//                     <div className="flex">
//                       <input
//                         type="radio"
//                         className="mr-3"
//                         checked={dateFilter === "This Month"}
//                         onChange={() => handleDateChange("This Month")}
//                       />
//                       <label htmlFor="" className="font-lexend">
//                         This Month
//                       </label>
//                     </div>
//                     <div className="flex">
//                       <input
//                         type="radio"
//                         className="mr-3"
//                         checked={dateFilter === "This Year"}
//                         onChange={() => handleDateChange("This Year")}
//                       />
//                       <label htmlFor="" className="font-lexend">
//                         This Year
//                       </label>
//                     </div>
//                     <div className="flex">
//                       <input
//                         type="radio"
//                         className="mr-3"
//                         checked={dateFilter === "Custom"}
//                         onChange={handleCustomChange}
//                       />
//                       <label htmlFor="" className="font-lexend">
//                         Custom Date
//                       </label>
//                     </div>
//                   </div>

//                   {openCustom && (
//                     <div className="dateSec mb-4">
//                       <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
//                       <div className="start_dateContainer mt-4">
//                         <LocalizationProvider dateAdapter={AdapterMoment}>
//                           <MuiDatePicker
//                             label="Start Date"
//                             value={startDateFilter}
//                             onChange={dateRangeChange}
//                             disabled
//                             className="mt-4"
//                             renderInput={(params) => (
//                               <TextField {...params} error={false} />
//                             )}
//                             inputFormat="DD-MM-YYYY"
//                           />
//                         </LocalizationProvider>
//                       </div>
//                       <div className="endDate_container mt-4">
//                         <LocalizationProvider dateAdapter={AdapterMoment}>
//                           <MuiDatePicker
//                             label="End Date"
//                             // value={endDateFilter}
//                             disabled
//                             value={endDateFilter}
//                             // onChange={(item) => setEndDateFilter(item || "")}
//                             onChange={(item) => {
//                               setEndDateFilter(item || "");
//                               setDateRangeChipVisible(
//                                 !!startDateFilter && !!item
//                               );
//                             }}
//                             renderInput={(params) => (
//                               <TextField {...params} error={false} />
//                             )}
//                             inputFormat="DD-MM-YYYY"
//                           />
//                         </LocalizationProvider>
//                       </div>

//                       <div className="rangePicker_container mt-4">
//                         <DatePicker
//                           selected={startDateFilter}
//                           onChange={dateRangeChange}
//                           startDate={startDateFilter}
//                           endDate={endDateFilter}
//                           selectsRange
//                           inline
//                         />
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </Popover>
//           </div>
//           <div className="filter-chips-container flex flex-wrap ml-4">
//             {/* User Filter */}
//             {filterChips.map((chip) => (
//               <Chip
//                 key={chip.id}
//                 label={capitalizeFirstLetterOfEachWord(chip.label)}
//                 onDelete={() => handleDeleteChip(chip.id)}
//                 variant="outlined"
//                 sx={{ marginLeft: "5px", marginBottom: "10px" }}
//               />
//             ))}

//             {typeFilterChips.map((chip) => (
//               <Chip
//                 key={chip.id}
//                 label={chip.label}
//                 onDelete={() => handleDeleteTypeChip(chip.id)}
//                 variant="outlined"
//                 color={chip.label == "Debit" ? "error" : "success"}
//                 sx={{ marginLeft: "5px" }}
//               />
//             ))}
//             {dateChips.map((chip) => (
//               <Chip
//                 key={chip.id}
//                 label={chip.label}
//                 onDelete={() => handleDeleteDateChip(chip.id)}
//                 variant="outlined"
//                 sx={{ marginLeft: "5px" }}
//               />
//             ))}

//             {/* {dateRangeChipVisible && (
//               <Chip
//                 label={`Date Range: ${startDateFilter.format(
//                   "DD-MM-YYYY"
//                 )} - ${endDateFilter.format("DD-MM-YYYY")}`}
//                 onDelete={handleDateRangeDelete}
//                 variant="outlined"
//                 sx={{ marginLeft: "5px" }}
//               />
//             )} */}
//             {!dateRangeChipVisible && SingleDateChips && startDateFilter && (
//               <Chip
//                 label={`Date : ${moment(startDateFilter).format("DD-MM-YYYY")}`}
//                 onDelete={handleSingleDeleteDateChip}
//                 variant="outlined"
//                 sx={{ marginLeft: "5px" }}
//               />
//             )}

//             {dateRangeChipVisible && (
//               <Chip
//                 label={`Date Between : ${moment(startDateFilter).format(
//                   "DD-MM-YYYY"
//                 )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
//                 onDelete={handleDateRangeDelete}
//                 variant="outlined"
//                 sx={{ marginLeft: "5px" }}
//               />
//             )}

//             {/* {!dateRangeChipVisible && SingleDateChips && (
//               <Chip
//                 // label='single date'
//                 // label={`Date : ${singleStartDate}`}
//                 label={`Date : ${moment(startDateFilter).format("DD-MM-YYYY")}`}
//                 onDelete={() => handleSingleDeleteDateChip(startDateFilter)}
//                 variant="outlined"
//                 sx={{ marginLeft: "5px" }}
//               />
//             )} */}
//           </div>

//           <div className="topRow">
//             <div className="dwidgets">
//               <Widget type="wallet" data={walletBalance} />
//               <Widget type="revenue" data={billedRevenue} />
//               <Widget type="transaction" data={transaction} />
//             </div>
//           </div>

//           <DatabaseTable dataRow={tableData} loading={tableLoading} />

//           <div className="flex justify-end mr-5 ml-5 mb-6 px-4 py-5 border border-gray-300">
//             <span className="bold font-lexend mr-5">
           
//               {pageNumber * 100 - 99}–
//               {Math.min(pageNumber * 100, totalTransactions)} of{" "}
//               {totalTransactions}
//             </span>
//             <button
//               disabled={pageNumber == 1}
//               style={{ color: `${pageNumber == 1 ? "lightgray" : "black"}` }}
//               onClick={handlePreviousPage}
//             >
//               {" "}
//               <KeyboardArrowLeftIcon />{" "}
//             </button>
//             <button
//               style={{
//                 color: `${totalTransactions <= 100 ? "lightgray" : "black"}`,
//               }}
//               disabled={pageNumber * itemsPerPage >= totalTransactions || totalTransactions === 0}
//               className="ml-8"
//               onClick={handleNextPage}
//             >
//               <ChevronRightIcon />
//             </button>
//           </div>
      
//         </div>
//         {isFilterVisible && (
//           <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
//             <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
//               <h1 className="font-semibold font-lexend text-lg">Filters</h1>
//               <button
//                 style={{
//                   background: "#1AC47D",
//                   width: "35px",
//                   height: "35px",
//                   padding: "5px",
//                   borderRadius: "50%",
//                   border: "3px solid #1AAD70",
//                 }}
//                 onClick={handleFilterButtonClick}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="16"
//                   height="16"
//                   viewBox="0 0 16 16"
//                   fill="none"
//                 >
//                   <path
//                     d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
//                     stroke="white"
//                     stroke-width="1.33333"
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                   />
//                 </svg>
//               </button>
//             </div>

//             <div className="filter_main_container px-4">
//               <h1 className="font-semibold font-lexend text-lg mb-2">Users</h1>

//               {/* <Autocomplete
//                 className="mb-4"
//                 freeSolo
//                 id="checkboxes-tags-demo"
//                 disableClearable
//                 multiple
//                 limitTags={-1}
//                 options={allUsers}
//                 loading={loadingUsers}
//                 autoHighlight
//                 size="small"
//                 value={userFilter} // Set the value to control the selected users
//                 onChange={handleUserChange}
//                 isOptionEqualToValue={(option, value) => option.id === value.id}
//                 getOptionLabel={(option: any) => option.label}
//                 getOptionDisabled={(option) =>
//                   filterChips.some((chip) => chip.id === option.id)
//                 }
//                 renderOption={(props, option:any,index) => {
//                   const key = `listItem-${index}-${option.id}`;
//                   // let counter = 0;
//                   // const uniqueKey = `${option.id}_${option.label}_${counter++}`;
//                   const selected = filterChips.some(
//                     (chip) => chip.id === option.id
//                   );

//                   return (
//                     <MenuItem key={key} {...props}>
//                       <Checkbox
//                         icon={icon}
//                         checkedIcon={checkedIcon}
//                         checked={
//                           selected ||
//                           filterChips.some((chip) => chip.id === option.id)
//                         }
//                       />
//                       <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
//                         {/* Formatted name function call */}
//                      {/* { option.label}
                       
//                       </Typography>
//                     </MenuItem>
//                   );
//                 }}
//                 renderInput={(params: any) => (
//                   <TextField {...params} label="Search" />
//                 )}
//               />  */}

//               <Autocomplete
//                 className="mb-4"
//                 ListboxProps={{ style: { maxHeight: 210 } }}
//                 id="checkboxes-tags-demo"
//                 disableClearable
//                 multiple
//                 limitTags={-1}
//                 options={allUsers}
//                 autoHighlight
//                 size="small"
//                 value={userFilter}
//                 filterOptions={(options, { inputValue }) => {
//                   const inputPattern = new RegExp(`^${inputValue.split(' ').map(word => `(?=.*\\b${word})`).join('')}`, 'i'); 
          
//                   // Filter options based on the inputValue and ensure selected options are handled properly
//                   return options.filter(option => {
                 
//                     const label = option.label.toLowerCase();
//                     const matchesInput = inputPattern.test(label);
//                     const isDisabled = filterChips.some(chip => chip.id === option.id);
          
//                     return matchesInput && !isDisabled;
//                   });
//                 }}
//                 onChange={(event: any, newValue: any) => {
//                   setFilterChips(
//                     (prevChips: { id: string; label: string }[]) => {
//                       const newChips: { id: string; label: string }[] =
//                         newValue.map((user: any) => ({
//                           id: user.id,
//                           label: `User: ${user.label}`,
//                         }));

//                       const filteredChips = newChips.filter(
//                         (chip) =>
//                           !prevChips.some((prevChip) => prevChip.id === chip.id)
//                       );

//                       return [...prevChips, ...filteredChips];
//                     }
//                   );
//                   setUserFilter(newValue);
//                 }}
                
//                 isOptionEqualToValue={(option, value) => option.id === value.id}
//                 getOptionLabel={(option: any) => option.label}
//                 getOptionDisabled={(option) =>
//                   filterChips.some((chip) => chip.id === option.id)
//                 }

//                 renderOption={(props, option:any,index) => {
//                  const key = `listItem-${index}-${option.id}`;
//                   const selected = filterChips.some(
//                     (chip) => chip.id === option.id
//                   );
//                   return (
//                     <MenuItem {...props} key={key}>
//                       <Checkbox
//                         icon={icon}
//                         checkedIcon={checkedIcon}
//                         checked={
//                           selected ||
//                           filterChips.some((chip) => chip.id === option.id)
//                         }
//                       />
//                       <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
//                         {/* Formatted name function call */}
//                       {option.label}
                       
//                       </Typography>
//                     </MenuItem>
//                   );
//                 }}
                
//                 renderInput={(params: any) => (
//                   <TextField {...params} placeholder="Type to search...." />
//                 )}
//               />
      

//               <h1 className="font-semibold font-lexend text-lg">Type</h1>
//               <div className="flex">
//                 <input
//                   type="radio"
//                   className="mr-3 font-lexend "
//                   onChange={() => handleTypeChange("Credit")}
//                   checked={selectedType === "Credit"}
//                 />
//                 <label htmlFor="" className="font-lexend">
//                   Credit
//                 </label>
//               </div>

//               <div className="flex">
//                 <input
//                   type="radio"
//                   className="mr-3 font-lexend"
//                   onChange={() => handleTypeChange("Debit")}
//                   checked={selectedType === "Debit"}
//                 />
//                 <label htmlFor="" className="font-lexend">
//                   Debit
//                 </label>
//               </div>

//               <h1 className="font-semibold mt-5 mb-2 font-lexend text-lg">
//                 Transaction ID
//               </h1>
//               <TextField
//                 id="outlined-search"
//                 label="Search Transaction ID"
//                 type="text"
//                 size="small"
//                 sx={{ width: "100%", marginBottom: 5 }}
//                 onChange={(e) => setFilter(e.target.value)}
//               />
//               {/* <div className="Payment_Mode_container">
//                       <h1 className="font-semibold mt-5 font-lexend">Payment Modes</h1>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">UPI</label>
//                       </div>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">Debit Card</label>
//                       </div>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">Credit Card</label>
//                       </div>
//                       <div className="flex">
//                         <input type="checkbox" className="mr-3" />
//                         <label htmlFor="">Internet Banking</label>
//                       </div>
          
//                       </div> */}
//               <div className="date_filter_container -mt-5">
//                 <h1 className="font-semibold font-lexend text-lg">Date</h1>
//                 <div className="flex">
//                   <input
//                     type="radio"
//                     className="mr-3"
//                     checked={dateFilter === "Today"}
//                     onChange={() => handleDateChange("Today")}
//                   />
//                   <label htmlFor="" className="font-lexend">
//                     Today
//                   </label>
//                 </div>
//                 <div className="flex">
//                   <input
//                     type="radio"
//                     className="mr-3"
//                     checked={dateFilter === "This Week"}
//                     onChange={() => handleDateChange("This Week")}
//                   />
//                   <label htmlFor="" className="font-lexend">
//                     This Week
//                   </label>
//                 </div>
//                 <div className="flex">
//                   <input
//                     type="radio"
//                     className="mr-3"
//                     checked={dateFilter === "This Month"}
//                     onChange={() => handleDateChange("This Month")}
//                   />
//                   <label htmlFor="" className="font-lexend">
//                     This Month
//                   </label>
//                 </div>
//                 <div className="flex">
//                   <input
//                     type="radio"
//                     className="mr-3"
//                     checked={dateFilter === "This Year"}
//                     onChange={() => handleDateChange("This Year")}
//                   />
//                   <label htmlFor="" className="font-lexend">
//                     This Year
//                   </label>
//                 </div>
//                 <div className="flex items-center">
//                   <input
//                     type="checkbox"
//                     className="rounded-checkbox accent-black-500 mr-3"
//                     checked={isSelected}
//                     onChange={() => handleDateChange("Custom")}
//                   />
//                   <label htmlFor="" className="font-lexend">
//                     Custom Date
//                   </label>
//                 </div>
//               </div>

//               {isSelected && (
//                 <div className="dateSec mb-4">
//                   <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
//                   <div className="start_dateContainer mt-4">
//                     <LocalizationProvider dateAdapter={AdapterMoment}>
//                       <MuiDatePicker
//                         label="Start Date"
//                         value={startDateFilter}
//                         onChange={dateRangeChange}
//                         disabled
//                         className="mt-4"
//                         renderInput={(params) => (
//                           <TextField {...params} error={false} />
//                         )}
//                         inputFormat="DD-MM-YYYY"
//                       />
//                     </LocalizationProvider>
//                   </div>
//                   <div className="endDate_container mt-4">
//                     <LocalizationProvider dateAdapter={AdapterMoment}>
//                       <MuiDatePicker
//                         label="End Date"
//                         // value={endDateFilter}
//                         disabled
//                         value={endDateFilter}
//                         // onChange={(item) => setEndDateFilter(item || "")}
//                         onChange={(item) => {
//                           setEndDateFilter(item || "");
//                           setDateRangeChipVisible(!!startDateFilter && !!item);
//                         }}
//                         renderInput={(params) => (
//                           <TextField {...params} error={false} />
//                         )}
//                         inputFormat="DD-MM-YYYY"
//                       />
//                     </LocalizationProvider>
//                   </div>

//                   <div className="rangePicker_container mt-4">
//                     <DatePicker
//                       selected={startDateFilter}
//                       onChange={dateRangeChange}
//                       startDate={startDateFilter}
//                       endDate={endDateFilter}
//                       showMonthDropdown
//                       showYearDropdown
//                       dropdownMode="select"
//                       maxDate={new Date()} // Disabled Future Date
//                       minDate={new Date(2022, 0, 1)} 
//                       selectsRange
//                       inline
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// });

// export default Transaction;

import { Autocomplete, Checkbox, Chip, TextField, Typography } from "@mui/material";
import moment from "moment";
import Popover from "@mui/material/Popover";
import { memo, useEffect, useMemo, useCallback, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import DatabaseTable from "./databasetable";
import Widget from "./widget";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "./transaction.scss";
import Revenue from "../revenue";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface optionObj {
  id: string;
  label: string;
}
interface UserFilter {
  id: string;
  label: string;
}

const Transaction = memo(() => {
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [userFilterOn, setUserFilterOn] = useState(false);
  const [userFilter, setUserFilter] = useState<UserFilter[]>([]);
  const [typeFilter, setTypeFilter] = useState("");
  const [filterData, setFilterData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);

  const [typeFilterChips, setTypeFilterChips] = useState<any[]>([]);
  const [dateChips, setDateFilterChips] = useState<any[]>([]);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );
  const [userIds, setUserIds] = useState<any[]>([]);
  const [TypeIds, setTypeIds] = useState<string>("");
  const [dateFilter, setDateFilter] = useState("This Week");
  const [selectedType, setSelectedType] = useState("");
  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [filter, setFilter] = useState("");
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [singleStartDate, setStartDate] = useState<any>("");

  // Pagination Code
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage] = useState(100); // You can set the items per page here or pass it as a prop

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    // In a real scenario, you would need to know the total number of pages
    // to prevent going beyond the last page. For simplicity, I'll omit it here.
    setPageNumber(pageNumber + 1);
  };

  // Widget Data
  const [walletBalance, setWalletBalance] = useState({
    total: 0,
  });
  const [billedRevenue, setBilledRevenue] = useState<any>({
    total: null,
  });
  const [transaction, setTransaction] = useState({
    total: 0,
  });

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                         State to manage Custom checkbox                        ||
  // ! ||--------------------------------------------------------------------------------||
  const [isSelected, setIsSelected] = useState(false);

// ! ||--------------------------------------------------------------------------------||
// ! ||                        Filter Code For Mobile and Tablet                       ||
// ! ||--------------------------------------------------------------------------------||
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCustomChange = () => {
    setOpenCustom(true);
  };

// ! ||--------------------------------------------------------------------------------||
// ! ||                                Filter Code Start                               ||
// ! ||--------------------------------------------------------------------------------||

  const handleUserChange = useCallback((event: any, newValue: any) => {
    setFilterChips((prevChips) => {
      const newChips = newValue.map((user: any) => ({
        id: user.id,
        label: `User: ${user.label}`,
      }));

      const filteredChips = newChips.filter(
        (chip: any) => !prevChips.some((prevChip) => prevChip.id === chip.id)
      );

      return [...prevChips, ...filteredChips];
    });

    setUserFilter(newValue);
    setUserFilterOn(true);
  }, []);

  const handleFilterButtonClick = () => {
    // Toggle filter visibility
    setIsFilterVisible((prev) => !prev);
  };

  
  const handleDeleteChip = (chipId: string) => {
    // console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the user filter
    setUserIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setUserFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
    // console.log("userFilter in delete", userFilter);
  };

  const handleDeleteTypeChip = (chipId: string) => {
    // console.log("Deleting type chip with id:", chipId);

    // Remove the corresponding chip from the state
    setTypeFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Uncheck the radio button when deleting the chip
    setSelectedType("");
  };
  const handleDateRangeDelete = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDateRangeChipVisible(false);
  };
  const handleDeleteDateChip = (chipId: string) => {
    // console.log("Deleting type chip with id:", chipId);
    setDateFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );
    setDateFilter("");

    // Uncheck the radio button when deleting the chip
    setSelectedType("");
  };
  const handleSingleDeleteDateChip = (chipId: string) => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSingleDateFilterChips(null);
  };

  const handleTypeChange = (value: string) => {
    // Find if the selected type is already in the chips
    const existingChip = typeFilterChips.find((chip) => chip.label === value);

    // If the selected type is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous type chip if it exists
      const updatedChips = typeFilterChips.filter(
        (chip) => chip.id !== selectedType
      );

      // Add the selected type to the chips array
      setTypeFilterChips([...updatedChips, { id: value, label: value }]);

      // Update the selectedType state
      setSelectedType(value);
    }
  };

  // const handleDateChange = (value: string) => {
  //   if (value === "Custom") {
  //     setIsSelected(!isSelected);
  //     setOpenCustom((prevOpenCustom) => !prevOpenCustom);
  //     setDateFilterChips([]);
  //     setDateFilter("");
  //   } else {
  //     setOpenCustom(false);
  //     // Update the dateFilter state
  //     setDateFilter(value);
  //     // Update the dateFilterChips array based on the selected value
  //     const selectedChip = {
  //       id: value,
  //       label:
  //         value === "Today"
  //           ? "Today"
  //           : value === "This Month"
  //           ? "This Month"
  //           : "This Year",
  //     };
  //     setDateFilterChips([selectedChip]);
  //   }
  // };
  const handleDateChange = (value: string) => {
    if (value === "Custom") {
      setIsSelected(!isSelected);
      setOpenCustom((prevOpenCustom) => !prevOpenCustom);
      setDateFilterChips([]);
      setDateFilter("");
      // Clear custom date range when selecting "Custom"
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
    } else {
      setOpenCustom(false);
      // Update the dateFilter state
      setDateFilter(value);
      // Update the dateFilterChips array based on the selected value
      const selectedChip = {
        id: value,
        label:
          value === "Today"
            ? "Today"
            : value === "This Month"
            ? "This Month"
            : "This Year",
      };
      setDateFilterChips([selectedChip]);
      // Clear custom date range when selecting predefined filters
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
      setIsSelected(false);
    }
  };
  
  
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  const [totalTransactions, setTotalTransactions] = useState(0);

// ! ||--------------------------------------------------------------------------------||
// ! ||                               Revenue Widget Code                              ||
// ! ||--------------------------------------------------------------------------------||

  const getRevenueData = async () => {
    const ChargeSession = Parse.Object.extend("ChargeSession");
    const parseQuery = new Parse.Query(ChargeSession);

    // parseQuery.limit(1000);
    if (userIds.length > 0) {
      parseQuery.containedIn("User", userIds);
    }

    // if (dateFilter) {
    //   if (dateFilter === "Today") {
    //     parseQuery.greaterThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().startOf("day").toString())
    //     );
    //     parseQuery.lessThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().add(1, "day").startOf("day").toString())
    //     );
    //   } else if (dateFilter === "This Month") {
    //     parseQuery.greaterThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().startOf("month").toString())
    //     );
    //     parseQuery.lessThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().endOf("month").toString())
    //     );
    //   } else if (dateFilter === "This Week") {
    //     parseQuery.greaterThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().startOf("week").toString())
    //     );
    //     parseQuery.lessThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().endOf("week").toString())
    //     );
    //   } else if (dateFilter === "This Year") {
    //     parseQuery.greaterThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().startOf("year").toString())
    //     );
    //     parseQuery.lessThanOrEqualTo(
    //       "createdAt",
    //       new Date(moment().endOf("year").toString())
    //     );
    //   }
    // }
    if (dateFilter === "This Week") {
      const startOfCurrentWeek = moment().startOf("week").add(1, "day"); // Start from Monday
      const endOfCurrentWeek = moment().endOf("week");

      parseQuery.greaterThanOrEqualTo("createdAt", startOfCurrentWeek.toDate());
      parseQuery.lessThanOrEqualTo("createdAt", endOfCurrentWeek.toDate());
      const count = await parseQuery?.count();

      setTotalTransactions(count);
      setTransaction({
        total: count,
      });
    } else if (dateFilter === "Today") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().add(1, "day").startOf("day").toString())
      );
    } else if (dateFilter === "This Month") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("month").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("month").toString())
      );
    } else if (dateFilter === "This Year") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("year").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("year").toString())
      );
    }

    if (startDateFilter) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
      const result = await parseQuery.find();
      result.map((item) => ({
        id: item.id,
        customer: `${item.get("User")?.get("FullName")}`,
        type: `${item.get("Type")}`,
        razorPayId: item.get("RazorPayId"),
        amount: `₹${item.get("Amount")?.toFixed(2)}`,
        detail: `${item.get("Detail")}`,
        createdAt: `${
          moment(item.get("createdAt")).format("lll")
            ? moment(item.get("createdAt")).format("lll")
            : "-"
        }`,
      }));
    }

    if (startDateFilter && endDateFilter) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.lessThanOrEqualTo("createdAt", new Date(endDateFilter));
      const result = await parseQuery.find();
      result.map((item) => ({
        id: item.id,
        customer: `${item.get("User")?.get("FullName")}`,
        type: `${item.get("Type")}`,
        razorPayId: item.get("RazorPayId"),
        amount: `₹${item.get("Amount")?.toFixed(2)}`,
        detail: `${item.get("Detail")}`,
        createdAt: `${
          moment(item.get("createdAt")).format("lll")
            ? moment(item.get("createdAt")).format("lll")
            : "-"
        }`,
      }));
    }
    parseQuery.limit(5000);

    parseQuery.find().then((result) => {
      let totalEnergy = 0;

      let totalMoney = 0;

      let totalSession = 0;

      result.forEach((item, index) => {
        totalEnergy = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") + totalEnergy
          : 0 + totalEnergy;
        totalMoney = item.get("TotalCost")
          ? item.get("TotalCost") + totalMoney
          : 0 + totalMoney;
        totalSession = totalSession + 1;
      });
      if (selectedType === "Credit") {
        setBilledRevenue({
          total: null,
        });
      } else {
        setBilledRevenue({
          total: totalMoney,
        });
      }
    });
  };

  

// ! ||--------------------------------------------------------------------------------||
// ! ||                               Wallet Widget Code                               ||
// ! ||--------------------------------------------------------------------------------||

    const userWalletData = async () => {
      const parseQuery = new Parse.Query("_User");
      parseQuery.notEqualTo("UserType", "Cloud");

      parseQuery.limit(5000);
      // parseQuery.containedIn("objectId", userIds);
      if (userIds.length > 0) {
        // parseQuery.containedIn("objectId", userIds);
        parseQuery.containedIn("objectId", userIds);
      }

      let totalCredited = 0;

      parseQuery.find().then((result: any[]) => {
        result.forEach((item) => {
          // console.log("item credit",item)
          totalCredited = item.get("Credit")
            ? totalCredited + item.get("Credit")
            : totalCredited + 0;
        });

        setWalletBalance({
          total: totalCredited,
        });
      });
    };

// ! ||--------------------------------------------------------------------------------||
// ! ||                  Both Get All Transaction and Filter Data Code                 ||
// ! ||--------------------------------------------------------------------------------||

const capitalizeFirstLetterOfEachWord = (str:string) => {
  // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
  return str
  .toLowerCase()
  .replace(/\b\w/g, (char:any) => char.toUpperCase());
};

  const getAllTransaction = async (
    pageNumber: number,
    itemsPerPage: number,
    dateFilter: string
  ) => {
    try {
      let filteredData = [];
      setTableLoading(true);
      if (dateFilter === "This Week") {
        setDateFilterChips([{ id: "This Week", label: "This Week" }]);
      }
      const parseQuery = new Parse.Query("Transactions");
      parseQuery.include("User");
      parseQuery.descending("createdAt");
      // let totalTransactions = 0;

      let totalTransactions = await parseQuery.count();
      setTotalTransactions(totalTransactions);
      setTransaction({
        total: totalTransactions,
      });

      // Calculate skip value

      // parseQuery.limit(5500);
      // Apply user filter

      if (dateFilter !== "This Week") {
        parseQuery.limit(1000);
        // setDateFilterChips([]);
      }

      if (userIds && userIds.length > 0) {
        parseQuery.containedIn("User", userIds);
        const count = await parseQuery?.count();

        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
      }
      if (filter) {
        parseQuery.limit(50000); // Apply filter to entire dataset without pagination
        parseQuery.matches("RazorPayId", new RegExp(filter, "i"));
        const count = await parseQuery?.count();

        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
      } else if (typeFilterChips && typeFilterChips.length > 0) {
        parseQuery.limit(50000);
      } else if (dateFilter) {
        parseQuery.limit(50000);
      } else if (startDateFilter) {
        parseQuery.limit(50000);
      } else if (endDateFilter) {
        parseQuery.limit(50000);
      } else {
        const skip = (pageNumber - 1) * itemsPerPage;
        // Set skip and limit for pagination
        parseQuery.skip(skip);
        parseQuery.limit(itemsPerPage);
      }
      let result = await parseQuery.find();
      if (typeFilterChips && typeFilterChips.length > 0) {
        parseQuery.limit(50000);
        parseQuery.equalTo("Type", selectedType);
        // const count = await parseQuery.count();

        const typeIds = typeFilterChips.map((chip) => chip.label);
        filteredData = result
          .map((item) => ({
            id: item.id,
            customer: `${item.get("User")?.get("FullName")}`,
            type: `${item.get("Type")}`,
            razorPayId: item.get("RazorPayId"),
            amount: `₹${item.get("Amount")?.toFixed(2)}`,
            detail: `${item.get("Detail")}`,
            createdAt: `${
              moment(item.get("createdAt")).format("lll")
                ? moment(item.get("createdAt")).format("lll")
                : "-"
            }`,
          }))
          .filter((item) => typeIds.includes(item.type));
        setTotalTransactions(filteredData.length);
        setTransaction({
          total: filteredData.length,
        });
      } else {
        let result = await parseQuery.find();

        // If no type filter or no user filter, set the data without type filter
        filteredData = result.map((item) => ({
          id: item.id,
          customer: `${item.get("User")?.get("FullName")}`,
          type: `${item.get("Type")}`,
          razorPayId: item.get("RazorPayId"),
          amount: `₹${item.get("Amount")?.toFixed(2)}`,
          detail: `${item.get("Detail")}`,
          createdAt: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
        }));
      }
      if (dateFilter === "This Week") {
        const startOfCurrentWeek = moment().startOf("week").add(1, "day"); // Start from Monday
        const endOfCurrentWeek = moment().endOf("week");

        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          startOfCurrentWeek.toDate()
        );
        parseQuery.lessThanOrEqualTo("createdAt", endOfCurrentWeek.toDate());
        const count = await parseQuery?.count();

        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
      } else if (dateFilter === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
        const count = await parseQuery?.count();

        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
      } else if (dateFilter === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("month").toString())
        );
        const count = await parseQuery?.count();

        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
      } else if (dateFilter === "This Year") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("year").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("year").toString())
        );
        const count = await parseQuery?.count();

        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
      }
      if (dateFilter && userIds && userIds.length > 0) {
        const dateQuery = new Parse.Query("Transactions");
        dateQuery.include("User");
        dateQuery.descending("createdAt");
        parseQuery.limit(4500);
        if (dateFilter === "Today") {
          // console.log("today", dateFilter);

          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            new Date(moment().startOf("day").toString())
          );
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            new Date(moment().add(1, "day").startOf("day").toString())
          );
        } else if (dateFilter === "This Week") {
          const startOfPreviousWeek = moment()
            .subtract(1, "week")
            .startOf("week");
          const endOfCurrentWeek = moment().endOf("week");

          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            startOfPreviousWeek.toDate()
          );
          dateQuery.lessThanOrEqualTo("createdAt", endOfCurrentWeek.toDate());
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            new Date(moment().endOf("week").toString())
          );
        } else if (dateFilter === "This Month") {
          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            new Date(moment().startOf("month").toString())
          );
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            new Date(moment().endOf("month").toString())
          );
        } else if (dateFilter === "This Year") {
          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            new Date(moment().startOf("year").toString())
          );
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            new Date(moment().endOf("year").toString())
          );
        }

        if (userIds && userIds.length > 0) {
          dateQuery.containedIn("User", userIds);
        }

        const dateFilteredResult = await dateQuery.find();
        filteredData = dateFilteredResult.map((item) => ({
          id: item.id,
          customer: `${item.get("User")?.get("FullName")}`,
          type: `${item.get("Type")}`,
          razorPayId: item.get("RazorPayId"),
          amount: `₹${item.get("Amount")?.toFixed(2)}`,
          detail: `${item.get("Detail")}`,
          createdAt: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
        }));
      }
      if (dateFilter && typeFilterChips && typeFilterChips.length > 0) {
        const dateQuery = new Parse.Query("Transactions");
        dateQuery.include("User");
        dateQuery.descending("createdAt");
        dateQuery.equalTo("Type", selectedType);
        dateQuery.limit(5000);
        // Apply date filter
        if (dateFilter === "Today") {
          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            moment().startOf("day").toDate()
          );
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            moment().endOf("day").toDate()
          );
          const count = await dateQuery?.count();

          setTotalTransactions(count);
          setTransaction({
            total: count,
          });
        } else if (dateFilter === "This Week") {
          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            moment().startOf("week").toDate()
          );
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            moment().endOf("week").toDate()
          );
          const count = await dateQuery?.count();

          setTotalTransactions(count);
          setTransaction({
            total: count,
          });
        } else if (dateFilter === "This Month") {
          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            moment().startOf("month").toDate()
          );
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            moment().endOf("month").toDate()
          );
          const count = await dateQuery?.count();

          setTotalTransactions(count);
          setTransaction({
            total: count,
          });
        } else if (dateFilter === "This Year") {
          dateQuery.greaterThanOrEqualTo(
            "createdAt",
            moment().startOf("year").toDate()
          );
          dateQuery.lessThanOrEqualTo(
            "createdAt",
            moment().endOf("year").toDate()
          );
          const count = await dateQuery?.count();

          setTotalTransactions(count);
          setTransaction({
            total: count,
          });
        }

        // Apply type filter
        const typeIds = typeFilterChips.map((chip) => chip.label);

        // Apply userIds filter
        if (userIds && userIds.length > 0) {
          dateQuery.containedIn("User", userIds);
        }

        const dateTypeFilteredResult = await dateQuery.find();

        filteredData = dateTypeFilteredResult.map((item) => ({
          id: item.id,
          customer: `${item.get("User")?.get("FullName")}`,
          type: `${item.get("Type")}`,
          razorPayId: item.get("RazorPayId"),
          amount: `₹${item.get("Amount")?.toFixed(2)}`,
          detail: `${item.get("Detail")}`,
          createdAt: moment(item.get("createdAt")).format("lll") || "-",
        }));

        setTotalTransactions(filteredData.length);
        setTransaction({
          total: filteredData.length,
        });
      }

      if (startDateFilter) {
        // Apply single date filter
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment(startDateFilter).startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment(startDateFilter).endOf("day").toString())
        );
        const count = await parseQuery.count();
        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
        const singleDateResult = await parseQuery.find();
        filteredData = singleDateResult.map((item) => ({
          id: item.id,
          customer: `${item.get("User")?.get("FullName")}`,
          type: `${item.get("Type")}`,
          razorPayId: item.get("RazorPayId"),
          amount: `₹${item.get("Amount")?.toFixed(2)}`,
          detail: `${item.get("Detail")}`,
          createdAt: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
        }));
      }
      if (startDateFilter && typeFilterChips && typeFilterChips.length > 0) {
        // Construct a query to filter by startDateFilter
        const dateQuery = new Parse.Query("Transactions");
        dateQuery.include("User");
        dateQuery.descending("createdAt");
        dateQuery.limit(5000);
        // Apply startDateFilter
        dateQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment(startDateFilter).startOf("day").toString())
        );
        dateQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment(startDateFilter).endOf("day").toString())
        );

        // Apply type filter
        const typeIds = typeFilterChips.map((chip) => chip.label);
        dateQuery.containedIn("Type", typeIds);

        // Apply userIds filter if needed
        if (userIds && userIds.length > 0) {
          dateQuery.containedIn("User", userIds);
        }

        const count = await dateQuery.count();
        setTotalTransactions(count);
        setTransaction({
          total: count,
        });

        const filteredResult = await dateQuery.find();
        filteredData = filteredResult.map((item) => ({
          id: item.id,
          customer: `${item.get("User")?.get("FullName")}`,
          type: `${item.get("Type")}`,
          razorPayId: item.get("RazorPayId"),
          amount: `₹${item.get("Amount")?.toFixed(2)}`,
          detail: `${item.get("Detail")}`,
          createdAt: moment(item.get("createdAt")).format("lll") || "-",
        }));
      }

      if (startDateFilter && endDateFilter) {
        parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
        const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
        parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
        parseQuery.limit(5000);
        const count = await parseQuery?.count();

        setTotalTransactions(count);
        setTransaction({
          total: count,
        });
        // parseQuery.lessThanOrEqualTo("createdAt", new Date(endDateFilter));
        const finalResult = await parseQuery.find();
        filteredData = finalResult.map((item) => ({
          id: item.id,
          customer: `${item.get("User")?.get("FullName")}`,
          type: `${item.get("Type")}`,
          razorPayId: item.get("RazorPayId"),
          amount: `₹${item.get("Amount")?.toFixed(2)}`,
          detail: `${item.get("Detail")}`,
          createdAt: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
        }));
      }
      //  Filter - (Date Range With Type)
      if (
        startDateFilter &&
        endDateFilter &&
        typeFilterChips &&
        typeFilterChips.length > 0
      ) {
        // Construct a query to filter by startDateFilter and endDateFilter
        const dateQuery = new Parse.Query("Transactions");
        dateQuery.include("User");
        dateQuery.descending("createdAt");
        dateQuery.limit(5000);

        // Apply startDateFilter and endDateFilter
        dateQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
        dateQuery.lessThanOrEqualTo("createdAt", new Date(endDateFilter));

        // Apply type filter
        const typeIds = typeFilterChips.map((chip) => chip.label);
        dateQuery.containedIn("Type", typeIds);

        // Apply userIds filter if needed
        if (userIds && userIds.length > 0) {
          dateQuery.containedIn("User", userIds);
        }

        const count = await dateQuery.count();
        setTotalTransactions(count);
        setTransaction({
          total: count,
        });

        // Fetch filtered results
        const filteredResult = await dateQuery.find();

        // Map and filter the results based on typeIds
        filteredData = filteredResult
          .map((item) => ({
            id: item.id,
            customer: `${item.get("User")?.get("FullName")}`,
            type: `${item.get("Type")}`,
            razorPayId: item.get("RazorPayId"),
            amount: `₹${item.get("Amount")?.toFixed(2)}`,
            detail: `${item.get("Detail")}`,
            createdAt: moment(item.get("createdAt")).format("lll") || "-",
          }))
          .filter((item) => typeIds.includes(item.type));
      }

      // new pagination code
      // Calculate skip value for pagination
      const skip = (pageNumber - 1) * itemsPerPage;
      parseQuery.skip(skip);
      parseQuery.limit(itemsPerPage);

      // Fetch filtered results
      result = await parseQuery.find();
      const startIndex = (pageNumber - 1) * itemsPerPage + 1;
      // Map the results
      filteredData = result.map((item, index) => ({
        sno: startIndex + index,
        id: item.id,
        customer: `${item.get("User")?.get("FullName")}`,
        type: `${item.get("Type")}`,
        razorPayId: item.get("RazorPayId"),
        amount: `₹${item.get("Amount")?.toFixed(2)}`,
        detail: `${item.get("Detail")}`,
        createdAt: moment(item.get("createdAt")).format("lll") || "-",
      }));

      const totalTransaction = filteredData.length;

      setTableLoading(false);

      // setTransaction({
      //   total: totalTransactions,
      // });

      console.log("filteredData", filteredData);

      setTableData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    getAllTransaction(pageNumber, itemsPerPage, dateFilter);
    userWalletData();
    getRevenueData();
  }, [
    userIds,
    typeFilterChips,
    filter,
    dateFilter,
    startDateFilter,
    endDateFilter,
    pageNumber,
  ]);


// ! ||--------------------------------------------------------------------------------||
// ! ||                             Get All User For Filter                            ||
// ! ||--------------------------------------------------------------------------------||

  const [allUsers, setAllUsers] = useState<any | null>([]);
  // const getAllUsers = useMemo(
  //   () => async () => {
  //     setLoadingUsers(true);
  //     try {
  //       const parseQuery = new Parse.Query("ChargeSession");
  //       parseQuery.include("User");
  //       parseQuery.limit(5000);
  //       const result = await parseQuery.find();

  //       let usersMap = new Map();
  //       const ocppPromises = result.map(async (item) => {
  //           const user = item.get("User");
  //           if (user && !usersMap.has(user.id)) {
  //               usersMap.set(user.id, {
  //                   id: user.id,
  //                   label: user.get("FullName")
  //               });
  //           }
  //       });

  //       await Promise.all(ocppPromises);

  //       setAllUsers(Array.from(usersMap.values()));
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     } finally {
  //       setLoadingUsers(false);
  //     }
  //   },
  //   []
  // );
  const getAllUsers = async () => {
    try {
      const User = Parse.Object.extend("User");
      const parseQuery = new Parse.Query(User);
      parseQuery.notEqualTo("UserType", "Cloud");
      parseQuery.limit(4500);
      const result = await parseQuery.find();
      
      let usersArray: optionObj[] = [];
      result.forEach((item) => {
        usersArray.push({
          id: item.id,
          label: item.get("FullName"),
        });
      });
      console.log("usersArray",usersArray);
      
      setAllUsers(usersArray);
    } catch (error) {
      console.error("Error fetching users:", error);
    } 
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (Array.isArray(userFilter)) {
      const userIds = userFilter.map((user: any) => user.id);
      // console.log("users Id", userIds);
      setUserIds(userIds);

      if (userIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (userFilter && typeof userFilter === "object") {
      const userId = (userFilter as { id?: string }).id;
      console.log("user Id", userId);
      setUserIds(userId ? [userId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [userFilter, typeFilter, dateFilter, startDateFilter, endDateFilter]);



  return (
    <div className="transaction-container">
      <div className="c flex">
        <div style={{ width: "100%" }}>
          <div className="flex justify-between mx-6 mt-5 ">
            <h2 className="transaction_heading mt-2">Transaction</h2>
            <button
              className="filter_button rounded-full p-3 border-2 border-black-600"
              onClick={handleFilterButtonClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                  stroke="#111111"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            {/* Modal Button */}
            <button
              aria-describedby={id}
              onClick={handleClick}
              className="modal_button_filter rounded-full p-3 border-2 border-black-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                  stroke="#111111"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="filters flex flex-col  border-2 border-black-400">
                <div className="filter_header sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
                  <h1 className="font-semibold font-lexend text-lg">Filters</h1>
                  <button
                    style={{
                      background: "#1AC47D",
                      width: "35px",
                      height: "35px",
                      padding: "5px",
                      borderRadius: "50%",
                      border: "3px solid #1AAD70",
                    }}
                    disabled
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                        stroke="white"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>

                <div className="filter_main_container px-4">
                  <h1 className="font-semibold font-lexend text-lg mb-2">
                    Users
                  </h1>

                  <Autocomplete
                    className="mb-4"
                    freeSolo
                    id="checkboxes-tags-demo"
                    disableClearable
                    multiple
                    limitTags={-1}
                    options={allUsers}
                    loading={loadingUsers}
                    autoHighlight
                    size="small"
                    value={userFilter} // Set the value to control the selected users
                    onChange={(event: any, newValue: any) => {
                      setFilterChips(
                        (prevChips: { id: string; label: string }[]) => {
                          // Ensure that newValue is an array of objects with 'id' property
                          const newChips: { id: string; label: string }[] =
                            newValue.map((user: any) => ({
                              id: user.id,
                              label: `User: ${user.label}`,
                            }));

                          const filteredChips = newChips.filter(
                            (chip) =>
                              !prevChips.some(
                                (prevChip) => prevChip.id === chip.id
                              )
                          );

                          return [...prevChips, ...filteredChips];
                        }
                      );

                      // Update the userFilter state with the array of selected users
                      setUserFilter(newValue);

                      setUserFilterOn(true);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionDisabled={(option) =>
                      filterChips.some((chip) => chip.id === option.id)
                    }
                    renderOption={(props, option) => {
                      let counter = 0;
                      const uniqueKey = `${option.id}_${
                        option.label
                      }_${counter++}`;
                      const selected = filterChips.some(
                        (chip) => chip.id === option.id
                      );
                      // console.log("selected", selected);

                      return (
                        <MenuItem key={uniqueKey} {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={
                              selected ||
                              filterChips.some((chip) => chip.id === option.id)
                            }
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} label="Search" />
                    )}
                  />

                  <h1 className="font-semibold font-lexend text-lg">Type</h1>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3 font-lexend "
                      onChange={() => handleTypeChange("Credit")}
                      checked={selectedType === "Credit"}
                    />
                    <label htmlFor="" className="font-lexend">
                      Credit
                    </label>
                  </div>

                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3 font-lexend"
                      onChange={() => handleTypeChange("Debit")}
                      checked={selectedType === "Debit"}
                    />
                    <label htmlFor="" className="font-lexend">
                      Debit
                    </label>
                  </div>

                  <h1 className="font-semibold mt-5 mb-2 font-lexend text-lg">
                    Transaction ID
                  </h1>
                  <TextField
                    id="outlined-search"
                    label="Search Transaction ID"
                    type="text"
                    size="small"
                    sx={{ width: "100%", marginBottom: 5 }}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  {/* <div className="Payment_Mode_container">
                      <h1 className="font-semibold mt-5 font-lexend">Payment Modes</h1>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">UPI</label>
                      </div>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">Debit Card</label>
                      </div>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">Credit Card</label>
                      </div>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">Internet Banking</label>
                      </div>
          
                      </div> */}
                  <div className="date_filter_container -mt-5">
                    <h1 className="font-semibold font-lexend text-lg">Date</h1>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "Today"}
                        onChange={() => handleDateChange("Today")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Today
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Week"}
                        onChange={() => handleDateChange("This Week")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Week
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Month"}
                        onChange={() => handleDateChange("This Month")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Month
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Year"}
                        onChange={() => handleDateChange("This Year")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Year
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "Custom"}
                        onChange={handleCustomChange}
                      />
                      <label htmlFor="" className="font-lexend">
                        Custom Date
                      </label>
                    </div>
                  </div>

                  {openCustom && (
                    <div className="dateSec mb-4">
                      <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                      <div className="start_dateContainer mt-4">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MuiDatePicker
                            label="Start Date"
                            value={startDateFilter}
                            onChange={dateRangeChange}
                            disabled
                            className="mt-4"
                            renderInput={(params) => (
                              <TextField {...params} error={false} />
                            )}
                            inputFormat="DD-MM-YYYY"
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="endDate_container mt-4">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MuiDatePicker
                            label="End Date"
                            // value={endDateFilter}
                            disabled
                            value={endDateFilter}
                            // onChange={(item) => setEndDateFilter(item || "")}
                            onChange={(item) => {
                              setEndDateFilter(item || "");
                              setDateRangeChipVisible(
                                !!startDateFilter && !!item
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} error={false} />
                            )}
                            inputFormat="DD-MM-YYYY"
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="rangePicker_container mt-4">
                        <DatePicker
                          selected={startDateFilter}
                          onChange={dateRangeChange}
                          startDate={startDateFilter}
                          endDate={endDateFilter}
                          selectsRange
                          inline
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Popover>
          </div>
          <div className="filter-chips-container flex flex-wrap ml-4">
            {/* User Filter */}
            {filterChips.map((chip) => (
              <Chip
                key={chip.id}
                label={capitalizeFirstLetterOfEachWord(chip.label)}
                onDelete={() => handleDeleteChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}

            {typeFilterChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleDeleteTypeChip(chip.id)}
                variant="outlined"
                color={chip.label == "Debit" ? "error" : "success"}
                sx={{ marginLeft: "5px" }}
              />
            ))}
            {dateChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleDeleteDateChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px" }}
              />
            ))}

            {/* {dateRangeChipVisible && (
              <Chip
                label={`Date Range: ${startDateFilter.format(
                  "DD-MM-YYYY"
                )} - ${endDateFilter.format("DD-MM-YYYY")}`}
                onDelete={handleDateRangeDelete}
                variant="outlined"
                sx={{ marginLeft: "5px" }}
              />
            )} */}
            {!dateRangeChipVisible && SingleDateChips && startDateFilter && (
              <Chip
                label={`Date : ${moment(startDateFilter).format("DD-MM-YYYY")}`}
                onDelete={handleSingleDeleteDateChip}
                variant="outlined"
                sx={{ marginLeft: "5px" }}
              />
            )}

            {dateRangeChipVisible && (
              <Chip
                label={`Date Between : ${moment(startDateFilter).format(
                  "DD-MM-YYYY"
                )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
                onDelete={handleDateRangeDelete}
                variant="outlined"
                sx={{ marginLeft: "5px" }}
              />
            )}

            {/* {!dateRangeChipVisible && SingleDateChips && (
              <Chip
                // label='single date'
                // label={`Date : ${singleStartDate}`}
                label={`Date : ${moment(startDateFilter).format("DD-MM-YYYY")}`}
                onDelete={() => handleSingleDeleteDateChip(startDateFilter)}
                variant="outlined"
                sx={{ marginLeft: "5px" }}
              />
            )} */}
          </div>

          <div className="topRow">
            <div className="dwidgets">
              <Widget type="wallet" data={walletBalance} />
              <Widget type="revenue" data={billedRevenue} />
              <Widget type="transaction" data={transaction} />
            </div>
          </div>

          <DatabaseTable dataRow={tableData} loading={tableLoading} />

          <div className="flex justify-end mr-5 ml-5 mb-6 px-4 py-5 border border-gray-300">
            <span className="bold font-lexend mr-5">
              {" "}
              {/* {pageNumber * 100 - 99}–
              {Math.min(pageNumber * 100, tableData.length)} of{" "}
              {totalTransactions} */}
              {pageNumber * 100 - 99}–
              {Math.min(pageNumber * 100, totalTransactions)} of{" "}
              {totalTransactions}
            </span>
            <button
              disabled={pageNumber == 1}
              style={{ color: `${pageNumber == 1 ? "lightgray" : "black"}` }}
              onClick={handlePreviousPage}
            >
              {" "}
              <KeyboardArrowLeftIcon />{" "}
            </button>
            <button
              style={{
                color: `${totalTransactions <= 100 ? "lightgray" : "black"}`,
              }}
              disabled={totalTransactions <= 100}
              className="ml-8"
              onClick={handleNextPage}
            >
              <ChevronRightIcon />
            </button>
          </div>
        </div>
        {isFilterVisible && (
          <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
            <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
              <h1 className="font-semibold font-lexend text-lg">Filters</h1>
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                }}
                onClick={handleFilterButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                    stroke="white"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="filter_main_container px-4">
              <h1 className="font-semibold font-lexend text-lg mb-2">Users</h1>

              {/* <Autocomplete
                className="mb-4"
                freeSolo
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allUsers}
                loading={loadingUsers}
                autoHighlight
                size="small"
                value={userFilter} // Set the value to control the selected users
                onChange={handleUserChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  filterChips.some((chip) => chip.id === option.id)
                }
                renderOption={(props, option:any,index) => {
                  const key = `listItem-${index}-${option.id}`;
                  // let counter = 0;
                  // const uniqueKey = `${option.id}_${option.label}_${counter++}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );

                  return (
                    <MenuItem key={key} {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          filterChips.some((chip) => chip.id === option.id)
                        }
                      />
                      <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
                        {/* Formatted name function call */}
                     {/* { option.label}
                       
                      </Typography>
                    </MenuItem>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField {...params} label="Search" />
                )}
              />  */}

              <Autocomplete
                className="mb-4"
                ListboxProps={{ style: { maxHeight: 210 } }}
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allUsers}
                autoHighlight
                size="small"
                value={userFilter}
                filterOptions={(options, { inputValue }) => {
                  const inputPattern = new RegExp(`^${inputValue.split(' ').map(word => `(?=.*\\b${word})`).join('')}`, 'i'); 
          
                  // Filter options based on the inputValue and ensure selected options are handled properly
                  return options.filter(option => {
                 
                    const label = option.label.toLowerCase();
                    const matchesInput = inputPattern.test(label);
                    const isDisabled = filterChips.some(chip => chip.id === option.id);
          
                    return matchesInput && !isDisabled;
                  });
                }}
                onChange={(event: any, newValue: any) => {
                  setFilterChips(
                    (prevChips: { id: string; label: string }[]) => {
                      const newChips: { id: string; label: string }[] =
                        newValue.map((user: any) => ({
                          id: user.id,
                          label: `User: ${user.label}`,
                        }));

                      const filteredChips = newChips.filter(
                        (chip) =>
                          !prevChips.some((prevChip) => prevChip.id === chip.id)
                      );

                      return [...prevChips, ...filteredChips];
                    }
                  );
                  setUserFilter(newValue);
                }}
                
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  filterChips.some((chip) => chip.id === option.id)
                }

                renderOption={(props, option:any,index) => {
                 const key = `listItem-${index}-${option.id}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );
                  return (
                    <MenuItem {...props} key={key}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          filterChips.some((chip) => chip.id === option.id)
                        }
                      />
                      <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
                        {/* Formatted name function call */}
                      {option.label}
                       
                      </Typography>
                    </MenuItem>
                  );
                }}
                
                renderInput={(params: any) => (
                  <TextField {...params} placeholder="Type to search...." />
                )}
              />
      

              <h1 className="font-semibold font-lexend text-lg">Type</h1>
              <div className="flex">
                <input
                  type="radio"
                  className="mr-3 font-lexend "
                  onChange={() => handleTypeChange("Credit")}
                  checked={selectedType === "Credit"}
                />
                <label htmlFor="" className="font-lexend">
                  Credit
                </label>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  className="mr-3 font-lexend"
                  onChange={() => handleTypeChange("Debit")}
                  checked={selectedType === "Debit"}
                />
                <label htmlFor="" className="font-lexend">
                  Debit
                </label>
              </div>

              <h1 className="font-semibold mt-5 mb-2 font-lexend text-lg">
                Transaction ID
              </h1>
              <TextField
                id="outlined-search"
                label="Search Transaction ID"
                type="text"
                size="small"
                sx={{ width: "100%", marginBottom: 5 }}
                onChange={(e) => setFilter(e.target.value)}
              />
              {/* <div className="Payment_Mode_container">
                      <h1 className="font-semibold mt-5 font-lexend">Payment Modes</h1>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">UPI</label>
                      </div>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">Debit Card</label>
                      </div>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">Credit Card</label>
                      </div>
                      <div className="flex">
                        <input type="checkbox" className="mr-3" />
                        <label htmlFor="">Internet Banking</label>
                      </div>
          
                      </div> */}
              <div className="date_filter_container -mt-5">
                <h1 className="font-semibold font-lexend text-lg">Date</h1>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "Today"}
                    onChange={() => handleDateChange("Today")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Today
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "This Week"}
                    onChange={() => handleDateChange("This Week")}
                  />
                  <label htmlFor="" className="font-lexend">
                    This Week
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "This Month"}
                    onChange={() => handleDateChange("This Month")}
                  />
                  <label htmlFor="" className="font-lexend">
                    This Month
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "This Year"}
                    onChange={() => handleDateChange("This Year")}
                  />
                  <label htmlFor="" className="font-lexend">
                    This Year
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="rounded-checkbox accent-black-500 mr-3"
                    checked={isSelected}
                    onChange={() => handleDateChange("Custom")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Custom Date
                  </label>
                </div>
              </div>

              {isSelected && (
                <div className="dateSec mb-4">
                  <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                  <div className="start_dateContainer mt-4">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MuiDatePicker
                        label="Start Date"
                        value={startDateFilter}
                        onChange={dateRangeChange}
                        disabled
                        className="mt-4"
                        renderInput={(params) => (
                          <TextField {...params} error={false} />
                        )}
                        inputFormat="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="endDate_container mt-4">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MuiDatePicker
                        label="End Date"
                        // value={endDateFilter}
                        disabled
                        value={endDateFilter}
                        // onChange={(item) => setEndDateFilter(item || "")}
                        onChange={(item) => {
                          setEndDateFilter(item || "");
                          setDateRangeChipVisible(!!startDateFilter && !!item);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} error={false} />
                        )}
                        inputFormat="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="rangePicker_container mt-4">
                    <DatePicker
                      selected={startDateFilter}
                      onChange={dateRangeChange}
                      startDate={startDateFilter}
                      endDate={endDateFilter}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()} // Disabled Future Date
                      minDate={new Date(2022, 0, 1)} 
                      selectsRange
                      inline
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Transaction;

