export {};  // This makes the file a module

// Your format function remains the same
// export const formatNumberWithCommas = (num: any) => {
//   if (num === undefined || num === null) return "-";
  
//   const [integer, decimal] = num.toString().split('.');
  
//   // Handle numbers less than 1000 (no commas needed)
//   if (integer.length <= 3) {
//       return decimal ? `${integer}.${decimal}` : integer;
//   }
  
//   // Format the integer part with commas according to the Indian numbering system
//   const lastThree = integer.slice(-3);
//   const otherNumbers = integer.slice(0, -3);
//   const formattedInteger = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree;
  
//   return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
// };

// export const formatNumberWithCommas = (num: any) => {
//   if (num === undefined || num === null) return "-";
  
//   // Convert to string and handle negative numbers
//   const numStr = num.toString();
//   const isNegative = numStr.startsWith('-');
//   const absoluteNumStr = isNegative ? numStr.slice(1) : numStr;
  
//   const [integer, decimal] = absoluteNumStr.split('.');
  
//   // Handle numbers less than 1000 (no commas needed)
//   if (integer.length <= 3) {
//     const result = decimal ? `${integer}.${decimal}` : integer;
//     return isNegative ? `-${result}` : result;
//   }
  
//   // Format the integer part with commas according to Indian numbering system
//   const lastThree = integer.slice(-3);
//   const otherNumbers = integer.slice(0, -3);
  
//   let formattedInteger = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
//   formattedInteger = formattedInteger ? `${formattedInteger},${lastThree}` : lastThree;
  
//   // Handle edge case where we might get leading comma
//   if (formattedInteger.startsWith(',')) {
//     formattedInteger = formattedInteger.slice(1);
//   }
  
//   const result = decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
//   return isNegative ? `-${result}` : result;
// };

export const formatNumberWithCommas = (num: any) => {
 
  
  if (num === undefined || num === null) return "-";

  const numStr = num.toString();
  const isNegative = numStr.startsWith('-');
  const absoluteNumStr = isNegative ? numStr.slice(1) : numStr;
  
  const [integer, decimal] = absoluteNumStr.split('.');
  console.log("The Length of num",integer.length);
  if (integer.length <= 5) {
    const result = decimal ? `${integer}.${decimal}` : integer;
    return isNegative ? `-${result}` : result;
  }

  // Format as per Indian numbering system
  const lastThree = integer.slice(-3);
  let otherNumbers = integer.slice(0, -3);

  let formattedInteger = "";
  if (otherNumbers.length > 0) {
    formattedInteger = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ",";
  }

  formattedInteger += lastThree;

  const result = decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  return isNegative ? `-${result}` : result;
};
