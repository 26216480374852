import React, { memo, useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const setLoadStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const loadManagement = memo(() => {
  let userDetail: any = sessionStorage.getItem("user-details");
  const currentUser: any = Parse.User.current();
  const [allLocations, setAllLocations] = useState<any | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Location Loader State
  const [isChargerLoading, setIsChargerLoading] = useState(false); // Charger Loader state
  const [isOpenModal, setOpenModal] = React.useState(false);
  const [isOpenSetLoadManagementModal, setOpenLoadManagementModal] =
    React.useState(false);
  const [dualGunChargersData, setDualGunChargers] = useState<any[]>([]);
  const [singleGunChargersData, setSingleGunChargers] = useState<any[]>([]);
  const handleOpen = (locationId: string, locationName: string) => {
    setOpenModal(true);
    setSelectedLocationId(locationId);
    setSelectedLocationName(locationName);
  };
  const handleSetLoadManagementModal = () => {
    setOpenLoadManagementModal(true);
    // setSelectedLocationId(locationId);
    // setSelectedLocationName(locationName);
  };
  const handleClose = () => {
    setOpenModal(false);
    setSelectedLocationId("");
    setDualGunChargers([]);
    setSingleGunChargers([]);
  };
  const handleCloseSetLoadManagementModal = () => {
    setOpenLoadManagementModal(false);
    // setSelectedLocationId("");
    // setDualGunChargers([]);
    // setSingleGunChargers([]);
  };

  const getAllLocations = () => {
    setIsLoading(true);
    const chargersQuery = new Parse.Query("Chargers");
    chargersQuery.include("Location");
    chargersQuery.equalTo("isEnabled", true);
    if (currentUser && !currentUser.get("isSuperAdmin")) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      const locationData: Record<
        string,
        { count: number; totalPower: number }
      > = {};

      chargeResult.forEach((chargePoint) => {
        const location = chargePoint.get("Location");
        if (location && location.id) {
          const locationId = location.id;
          const powerStr = chargePoint.get("Power"); // Assuming "Power" is the field name

          // Extract numeric value from power string (e.g., "24 KW" → 24)
          const powerValue = parseFloat(powerStr?.split(" ")[0]) || 0;

          if (!locationData[locationId]) {
            locationData[locationId] = { count: 0, totalPower: 0 };
          }

          locationData[locationId].count++;
          locationData[locationId].totalPower += powerValue;
        }
      });

      const locArray = Object.keys(locationData);
      console.log("Location Data", locationData);

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);

      locationQuery.find().then((result) => {
        const addressArray = result.map((item) => ({
          id: item.id,
          label: item.get("Name"),
          isPrivateAccess: item?.get("accessType"),
          chargerCount: locationData[item.id]?.count || 0,
          totalPower: `${(locationData[item.id]?.totalPower || 0).toFixed(
            1
          )} KW`,
        }));
        setAllLocations(addressArray);
        setIsLoading(false);
      });
    });
  };
  console.log("allLocations data", allLocations);

  useEffect(() => {
    if (selectedLocationId) {
      getChargerStatus();
    } else {
      console.log("no need to get all charger ");
    }
  }, [selectedLocationId]);
  useEffect(() => {
    getAllLocations();
  }, []);

  const getChargerStatus = async () => {
    try {
      setIsChargerLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_OCPP_BASE_URL}/active_clients`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch chargers");
      }

      const apiData = await response.json();
      console.log("API Data:", apiData); // ✅ Debug API data

      // Fetch Chargers data from Parse
      const ChargePoints = Parse.Object.extend("Chargers");
      const parseQuery = new Parse.Query(ChargePoints);
      parseQuery.include("Location");
      parseQuery.include("CP_Vendors");
      parseQuery.include("ConnectorType");
      parseQuery.include("ChargePointOperators");
      parseQuery.limit(1000);

      if (selectedLocationId) {
        const Location = Parse.Object.extend("Locations");
        const locationQuery = new Parse.Query(Location);
        locationQuery.equalTo("objectId", selectedLocationId);
        parseQuery.matchesQuery("Location", locationQuery);
        parseQuery.equalTo("isEnabled", true);
      }

      const parseData = await parseQuery.find();
      console.log("Parse Data:", parseData); // ✅ Debug Parse data

      // Create a map for Charger data from Parse
      const chargerMap = new Map();
      parseData.forEach((item) => {
        let cpo = item.get("CPO");
        chargerMap.set(item.get("ChargeId"), {
          power: item.get("Power"),
          cpo: {
            id: cpo?.id,
            name: cpo?.get("Name"),
          },
          ocppName: item.get("ChargeId"),
          chargerObjID: item.get("ObjectId"),
        });
      });

      console.log("Charger Map:", chargerMap); // ✅ Debug mapped chargers

      // Fetch ChargeSession data for active charging connectors

      const sessionQuery = new Parse.Query("ChargeSession");
      // parseQuery.include("User");
      sessionQuery.include("User.Fleet");
      sessionQuery.include("User");
      sessionQuery.include("ChargePoint");
      sessionQuery.equalTo("Live", true); // Only fetch active charging sessions
      const chargeSessions = await sessionQuery.find();

      console.log("Charge Sessions:", chargeSessions); // ✅ Debug ChargeSession data

      const sessionMap = new Map();
      chargeSessions.forEach((session) => {
        const chargerId = session.get("ChargePoint")?.get("ChargeId"); // Ensure correct key
        const chargeGunId = session.get("ChargeGunId");
        console.log("chargeGunId", chargeGunId);
        console.log("chargerId", chargerId);

        if (chargerId && chargeGunId) {
          const user = session?.get("User");
          let fleet = null;
          let fleetCost = 0; // Avoid undefined keys
          fleet = user?.get("Fleet");
          fleetCost = fleet?.get("FleetCost") ?? "Fleet has no cost";
          console.log("Fleet exists", fleet);
          console.log("Fleet Cost exists", fleetCost);

          sessionMap.set(`${chargerId}-${chargeGunId}`, {
            user: session?.get("User")?.get("FullName") || "Unknown",
            ocppUserId: session?.get("User")?.id || "Unknown",
            energy: session.get("energy") || 0,
            chargeSessionId: session?.id,
            cost: fleetCost || "User has no fleet",
            status: session.get("status"), // Add default value
            ocppTransactionId: session.get("TransactionId") || "Unknown", // Add default value
            createdAt: session.get("createdAt") || "Unknown", // Add default value
            meterStart: session.get("MeterStart") || "Unknown", // Add default value
            gunId: session.get("ChargeGunId") || "Unknown", // Add default value
          });
        } else {
          console.warn("Missing chargerId or chargeGunId", {
            chargerId,
            chargeGunId,
          });
        }
      });

      console.log("sessionMap ", sessionMap);

      const processedChargers = await Promise.all(
        apiData
          .filter((charger: any) => {
            if (!chargerMap.has(charger.name)) return false;

            const validConnectors = charger.connectors.filter(
              (c: any) => c.connector_num !== 0
            );

            const hasConnector1 = validConnectors.some(
              (c: any) => c.connector_num === 1
            );
            const hasConnector2 = validConnectors.some(
              (c: any) => c.connector_num === 2
            );

            return hasConnector1 && hasConnector2;
          })
          .flatMap((charger: any) => {
            const chargerDetails = chargerMap.get(charger.name);

            return charger.connectors
              .filter((c: any) => c.connector_num !== 0)
              .map(async (c: any) => {
                const key = `${charger.name.trim()}-${c.connector_num}`;
                const chargeSessionData = sessionMap.get(key) || {};

                let energyValue = chargeSessionData.energy || null;
                let duration = " ";
                let ocppCost = " ";

                if (chargeSessionData?.ocppTransactionId) {
                  try {
                    const ocppResponse = await fetch(
                      `${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${chargeSessionData.ocppTransactionId}`
                    );
                    const res = await ocppResponse.json();
                    energyValue = (
                      res.energy_active_import_register / 1000
                    ).toFixed(2);
                    // duration =
                    // moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).hours() +
                    // "hr" +
                    // " " +
                    // moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).minutes() +
                    // "min";

                    ocppCost = (
                      res.tariff *
                      (res.energy_active_import_register / 1000)
                    ).toFixed(2);
                  } catch (error) {
                    console.error(
                      `Error fetching OCPP data for ${key}:`,
                      error
                    );
                  }
                }

                return {
                  charge_point_serial_number:
                    charger.charger_attributes[0]?.charge_box_serial_number ||
                    charger?.charger_attributes[0]
                      ?.charge_point_serial_number ||
                    null,
                  power: chargerDetails?.power || null,
                  cpo: chargerDetails?.cpo || {},
                  ocppName: chargerDetails?.ocppName || "",
                  chargerObjID: chargerDetails?.chargerObjID || "",
                  connector_num: c.connector_num,
                  status: chargeSessionData.status || c.status,
                  user: chargeSessionData.user || null,
                  energy: energyValue,
                  duration: duration,
                  ocppCost: ocppCost,
                  cost: chargeSessionData.cost || null,
                  meterStart: chargeSessionData.meterStart || null,
                  gunId: chargeSessionData.gunId || null,
                };
              });
          })
      );
      // const singleGunProcessedChargers = await Promise.all(
      //   apiData
      //     .filter((charger: any) => {
      //       if (!chargerMap.has(charger.name)) return false;

      //       const validConnectors = charger.connectors.filter(
      //         (c: any) => c.connector_num !== 0
      //       );

      //       const hasConnector1 = validConnectors.some(
      //         (c: any) => c.connector_num === 1
      //       );

      //       return hasConnector1;
      //     })
      //     .flatMap((charger: any) => {
      //       const chargerDetails = chargerMap.get(charger.name);

      //       return charger.connectors
      //         .filter((c: any) => c.connector_num !== 0)
      //         .map(async (c: any) => {
      //           const key = `${charger.name.trim()}-${c.connector_num}`;
      //           const chargeSessionData = sessionMap.get(key) || {};

      //           let energyValue = chargeSessionData.energy || null;
      //           let duration = " ";
      //           let ocppCost = " ";

      //           if (chargeSessionData?.ocppTransactionId) {
      //             try {
      //               const ocppResponse = await fetch(
      //                 `${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${chargeSessionData.ocppTransactionId}`
      //               );
      //               const res = await ocppResponse.json();
      //               energyValue = (res.energy_active_import_register / 1000).toFixed(2);
      //               // duration =
      //               // moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).hours() +
      //               // "hr" +
      //               // " " +
      //               // moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).minutes() +
      //               // "min";

      //             ocppCost = (
      //               res.tariff *
      //               (res.energy_active_import_register / 1000)
      //             ).toFixed(2);
      //             } catch (error) {
      //               console.error(`Error fetching OCPP data for ${key}:`, error);
      //             }
      //           }

      //           return {
      //             charge_point_serial_number:
      //               charger.charger_attributes[0]?.charge_box_serial_number || null,
      //             power: chargerDetails?.power || null,
      //             cpo: chargerDetails?.cpo || {},
      //             ocppName: chargerDetails?.ocppName || "",
      //             chargerObjID: chargerDetails?.chargerObjID || "",
      //             connector_num: c.connector_num,
      //             status: chargeSessionData.status || c.status,
      //             user: chargeSessionData.user || null,
      //             energy: energyValue,
      //             duration: duration,
      //             ocppCost: ocppCost,
      //             cost: chargeSessionData.cost || null,
      //             meterStart: chargeSessionData.meterStart || null,
      //             gunId : chargeSessionData.gunId || null
      //           };
      //         });
      //     })
      // );

      // Wait for all async map operations to complete

      const singleGunProcessedChargers = await Promise.all(
        apiData
          .filter((charger: any) => {
            if (!chargerMap.has(charger.name)) return false;

            const validConnectors = charger.connectors.filter(
              (c: any) => c.connector_num !== 0
            );

            // Check if this is a single gun charger (only connector 1 exists)
            const hasConnector1 = validConnectors.some(
              (c: any) => c.connector_num === 1
            );
            const hasConnector2 = validConnectors.some(
              (c: any) => c.connector_num === 2
            );

            // Return true only for single gun chargers (connector 1 exists but not 2)
            return hasConnector1 && !hasConnector2;
          })
          .flatMap((charger: any) => {
            const chargerDetails = chargerMap.get(charger.name);

            return charger.connectors
              .filter((c: any) => c.connector_num !== 0)
              .map(async (c: any) => {
                const key = `${charger.name.trim()}-${c.connector_num}`;
                const chargeSessionData = sessionMap.get(key) || {};

                let energyValue = chargeSessionData.energy || null;
                let duration = " ";
                let ocppCost = " ";

                if (chargeSessionData?.ocppTransactionId) {
                  try {
                    const ocppResponse = await fetch(
                      `${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${chargeSessionData.ocppTransactionId}`
                    );
                    const res = await ocppResponse.json();
                    energyValue = (
                      res.energy_active_import_register / 1000
                    ).toFixed(2);
                    // duration =
                    // moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).hours() +
                    // "hr" +
                    // " " +
                    // moment.duration(moment(res.timestamp).diff(chargeSessionData?.createdAt)).minutes() +
                    // "min";

                    ocppCost = (
                      res.tariff *
                      (res.energy_active_import_register / 1000)
                    ).toFixed(2);
                  } catch (error) {
                    console.error(
                      `Error fetching OCPP data for ${key}:`,
                      error
                    );
                  }
                }

                return {
                  charge_point_serial_number:
                    charger.charger_attributes[0]?.charge_box_serial_number ||
                    charger.charger_attributes[0]?.charge_point_serial_number ||
                    null,
                  power: chargerDetails?.power || null,
                  cpo: chargerDetails?.cpo || {},
                  ocppName: chargerDetails?.ocppName || "",
                  chargerObjID: chargerDetails?.chargerObjID || "",
                  connector_num: c.connector_num,
                  status: chargeSessionData.status || c.status,
                  user: chargeSessionData.user || null,
                  energy: energyValue,
                  duration: duration,
                  ocppCost: ocppCost,
                  cost: chargeSessionData.cost || null,
                  meterStart: chargeSessionData.meterStart || null,
                  gunId: chargeSessionData.gunId || null,
                };
              });
          })
      );

      const filteredChargers = await Promise.all(processedChargers);
      const SingleGunFilteredChargers = await Promise.all(
        singleGunProcessedChargers
      );
      setDualGunChargers(filteredChargers);
      setSingleGunChargers(SingleGunFilteredChargers);
      setIsChargerLoading(false);
      console.log("Processed Charger Data:", filteredChargers); // ✅ Final check

      return filteredChargers; // Store this in state if needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log("dualGunChargersData", dualGunChargersData);
  console.log("singleGunChargersData", singleGunChargersData);

  return (
    <div className="m-5">
      <div className="flex justify-between mx-4 my-4">
        <h1 className="font-lexend text-2xl font-semibold">Load management</h1>
        <Tooltip title="Add Load">
          <button
            className="flex justify-around items-center w-max px-3 py-2 rounded-full"
            style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
          >
            <span className="mr-2 ml-5 ">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 9H11.25M9 6.75V11.25M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z"
                  stroke="white"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span style={{ color: "white" }} className="mr-5">
              <span className="font-lexend">Add Load</span>
            </span>
          </button>
        </Tooltip>
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className=" mx-5 grid mt-6  items-stretch grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
        {allLocations?.map((item: any) => (
          <div
            style={{ background: "#F6F8FA" }}
            key={item?.objectId}
            className="w-46 flex flex-col justify-between py-5 px-3"
          >
            <span className="flex items-center justify-between">
              <span className="flex items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 19H19M1 8H19M3 4L10 1L17 4M2 8V19M18 8V19M6 12V15M10 12V15M14 12V15"
                    stroke="#354052"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span className="font-lexend font-medium ml-2 max-w-44">
                  {item?.label}
                </span>
              </span>
              <Tooltip title="Set Load">
                <button
                  className="flex justify-around items-center p-2 rounded-full"
                  style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                  onClick={() => handleOpen(item?.id, item?.label)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 10L12 3L3 12H5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H14.5M9 21V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13C13.661 13 14.248 13.32 14.612 13.815M19 14L17 18H21L19 22"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Tooltip>
            </span>
            <div className="flex justify-between mt-8 items-end">
              <div className="flex flex-col">
                <span className="font-lexend">Total Load</span>
                <span className="font-lexend text-green-500 font-semibold">
                  {" "}
                  {item?.totalPower}
                </span>
              </div>
              <div>
                <span className="font-lexend">
                  {item?.chargerCount} Devices
                </span>
              </div>
            </div>
          </div>
        ))}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpenModal}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isOpenModal}>
            <Box sx={style}>
              <div>
                <h1 className="font-lexend font-semibold text-lg">
                  {selectedLocationName}'s Chargers List{" "}
                </h1>
              </div>
              <Paper
                sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}
              >
                <TableContainer sx={{ maxHeight: 326 }}>
                  {isChargerLoading == true ? (
                    <div className="flex justify-center items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="font-lexend font-semibold">
                              {" "}
                              Charger ID{" "}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="font-lexend font-semibold">
                              {" "}
                              Serial{" "}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="font-lexend font-semibold">
                              {" "}
                              GunID{" "}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-lexend font-semibold">
                              {" "}
                              Max Load (KWh)
                            </span>{" "}
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-lexend font-semibold">
                              {" "}
                              Status{" "}
                            </span>{" "}
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-lexend font-semibold">
                              {" "}
                              Set Load (KWh)
                            </span>{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {singleGunChargersData?.map((row: any) => {
                          return (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <span className="font-lexend font-semibold">
                                  {row?.ocppName}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="font-lexend">
                                  {row?.charge_point_serial_number}
                                </span>
                              </TableCell>
                              <TableCell
                                style={{
                                  fontFamily: "lexend",
                                  fontSize: "15px",
                                }}
                              >
                                {/* chargingGunId*/}
                                {row?.connector_num === 1 ? (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM10.994 5.886C10.911 5.109 9.986 4.726 9.377 5.216L9.293 5.293L7.293 7.293L7.21 7.387C7.07393 7.56237 7.00008 7.77803 7.00008 8C7.00008 8.22197 7.07393 8.43763 7.21 8.613L7.293 8.707L7.387 8.79C7.56237 8.92607 7.77803 8.99992 8 8.99992C8.22197 8.99992 8.43763 8.92607 8.613 8.79L8.707 8.707L9 8.414V14L9.007 14.117C9.03591 14.3601 9.15296 14.5841 9.33597 14.7466C9.51897 14.9091 9.75524 14.9989 10 14.9989C10.2448 14.9989 10.481 14.9091 10.664 14.7466C10.847 14.5841 10.9641 14.3601 10.993 14.117L11 14V6L10.994 5.886Z"
                                      fill="#57585a"
                                    />
                                  </svg>
                                ) : row?.connector_num === 2 ? (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM11 5H8L7.883 5.007C7.63995 5.03591 7.41594 5.15296 7.25341 5.33596C7.09088 5.51897 7.00111 5.75524 7.00111 6C7.00111 6.24476 7.09088 6.48103 7.25341 6.66403C7.41594 6.84704 7.63995 6.96409 7.883 6.993L8 7H11V9H9L8.85 9.005C8.37383 9.04078 7.92617 9.24576 7.58797 9.58286C7.24977 9.91996 7.04334 10.3669 7.006 10.843L7 11V13L7.005 13.15C7.04078 13.6262 7.24576 14.0738 7.58286 14.412C7.91996 14.7502 8.36695 14.9567 8.843 14.994L9 15H12L12.117 14.993C12.36 14.9641 12.5841 14.847 12.7466 14.664C12.9091 14.481 12.9989 14.2448 12.9989 14C12.9989 13.7552 12.9091 13.519 12.7466 13.336C12.5841 13.153 12.36 13.0359 12.117 13.007L12 13H9V11H11L11.15 10.995C11.6262 10.9592 12.0738 10.7542 12.412 10.4171C12.7502 10.08 12.9567 9.63305 12.994 9.157L13 9V7L12.995 6.85C12.9592 6.37383 12.7542 5.92617 12.4171 5.58797C12.08 5.24977 11.6331 5.04334 11.157 5.006L11 5Z"
                                      fill="#57585a"
                                    />
                                  </svg>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <span className="font-lexend">
                                  {row?.power}
                                </span>
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontFamily: "lexend",
                                  fontSize: "15px",
                                  width: "100px",
                                }}
                              >
                                {row?.status === "Available" && (
                                  <span className="flex items-center">
                                    {" "}
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                          stroke="#529fec"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>{" "}
                                    <span
                                      className="font-lexend"
                                      style={{ color: "#529fec" }}
                                    >
                                      Online
                                    </span>{" "}
                                  </span>
                                )}

                                {row?.status === "Charging" && (
                                  <>
                                    <span className="flex items-center">
                                      {" "}
                                      <span>
                                        <svg
                                          className="mr-2"
                                          width="15"
                                          height="14"
                                          viewBox="0 0 15 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.78906 2.75C1.78906 2.35218 1.9471 1.97064 2.2284 1.68934C2.50971 1.40804 2.89124 1.25 3.28906 1.25H11.5391C11.9369 1.25 12.3184 1.40804 12.5997 1.68934C12.881 1.97064 13.0391 2.35218 13.0391 2.75V3.125C13.0391 3.22446 13.0786 3.31984 13.1489 3.39017C13.2192 3.46049 13.3146 3.5 13.4141 3.5C13.5135 3.5 13.6089 3.53951 13.6792 3.60983C13.7496 3.68016 13.7891 3.77554 13.7891 3.875V6.125C13.7891 6.22446 13.7496 6.31984 13.6792 6.39016C13.6089 6.46049 13.5135 6.5 13.4141 6.5C13.3146 6.5 13.2192 6.53951 13.1489 6.60983C13.0786 6.68016 13.0391 6.77554 13.0391 6.875V7.25C13.0391 7.64782 12.881 8.02935 12.5997 8.31066C12.3184 8.59196 11.9369 8.75 11.5391 8.75H8.16406M3.28906 12.5V10.25M1.78906 7.25V5.375M4.78906 7.25V5.375M1.03906 7.25H5.53906V8.75C5.53906 9.14782 5.38103 9.52935 5.09972 9.81066C4.81842 10.092 4.43689 10.25 4.03906 10.25H2.53906C2.14124 10.25 1.75971 10.092 1.4784 9.81066C1.1971 9.52935 1.03906 9.14782 1.03906 8.75V7.25Z"
                                            stroke="#1AC47D"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>{" "}
                                      <span
                                        className="font-lexend"
                                        style={{ color: "#1AC47D" }}
                                      >
                                        Charging
                                      </span>{" "}
                                    </span>
                                  </>
                                )}

                                {row?.status === "Preparing" && (
                                  <span className="flex items-center">
                                    {" "}
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                          stroke="#52ec6c"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>{" "}
                                    <span style={{ color: "#52ec6c" }}>
                                      Connected
                                    </span>{" "}
                                  </span>
                                )}
                                {row?.status === "Finishing" && (
                                  <span style={{ color: "#fd9610" }}>
                                    Finishing
                                  </span>
                                )}
                                {row?.status === "Offline" && (
                                  <span className="flex items-center">
                                    {" "}
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.1625 13.2997C9.6009 13.4765 9.0093 13.5376 8.42344 13.4793C7.83759 13.4209 7.26964 13.2444 6.75395 12.9603C6.23826 12.6763 5.78553 12.2906 5.42313 11.8266C5.06072 11.3626 4.79618 10.8299 4.64549 10.2607C4.44852 9.51812 4.45141 8.73662 4.65385 7.99547C4.8563 7.25432 5.2511 6.57988 5.79824 6.0405L7.33874 4.5L12.7125 9.87375M3 15L5.625 12.375M11.25 3L8.625 5.625M15 6.75L12.375 9.375M12 12L15 15M15 12L12 15"
                                          stroke="#C99456"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>{" "}
                                    <span style={{ color: "#C99456" }}>
                                      Offline
                                    </span>{" "}
                                  </span>
                                )}
                                {row?.status === "Faulted" && (
                                  <span className="flex items-center">
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.55 14.733C9.8012 14.9687 9.0124 15.0501 8.23126 14.9724C7.45011 14.8946 6.69286 14.6592 6.00527 14.2804C5.31768 13.9017 4.71404 13.3874 4.23083 12.7688C3.74763 12.1501 3.39491 11.4399 3.19398 10.681C2.93136 9.69083 2.93521 8.64883 3.20514 7.66063C3.47506 6.67243 4.00146 5.77317 4.73098 5.054L6.78498 3L13.95 10.165M1 17L4.5 13.5M12 1L8.5 4.5M17 6L13.5 9.5M13 13L17 17M17 13L13 17"
                                          stroke="#981818"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <span className="text-red-900">
                                      Faulted
                                    </span>
                                  </span>
                                )}
                                {row?.status === "SuspendedEV" && (
                                  <span className="text-red-900">
                                    Suspended EV
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Manage Load">
                                  <button
                                    className="flex justify-around items-center p-2 rounded-full"
                                    style={{
                                      background: "#1AC47D",
                                      border: "2px solid #1AAD70",
                                    }}
                                    onClick={() =>
                                      handleSetLoadManagementModal()
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19 10L12 3L3 12H5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H14.5M9 21V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13C13.661 13 14.248 13.32 14.612 13.815M19 14L17 18H21L19 22"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {dualGunChargersData?.map((row: any) => {
                          return (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <span className="font-lexend font-semibold">
                                  {row?.ocppName}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="font-lexend">
                                  {row?.charge_point_serial_number}
                                </span>
                              </TableCell>
                              <TableCell
                                style={{
                                  fontFamily: "lexend",
                                  fontSize: "15px",
                                }}
                              >
                                {/* chargingGunId*/}
                                {row?.connector_num === 1 ? (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM10.994 5.886C10.911 5.109 9.986 4.726 9.377 5.216L9.293 5.293L7.293 7.293L7.21 7.387C7.07393 7.56237 7.00008 7.77803 7.00008 8C7.00008 8.22197 7.07393 8.43763 7.21 8.613L7.293 8.707L7.387 8.79C7.56237 8.92607 7.77803 8.99992 8 8.99992C8.22197 8.99992 8.43763 8.92607 8.613 8.79L8.707 8.707L9 8.414V14L9.007 14.117C9.03591 14.3601 9.15296 14.5841 9.33597 14.7466C9.51897 14.9091 9.75524 14.9989 10 14.9989C10.2448 14.9989 10.481 14.9091 10.664 14.7466C10.847 14.5841 10.9641 14.3601 10.993 14.117L11 14V6L10.994 5.886Z"
                                      fill="#57585a"
                                    />
                                  </svg>
                                ) : row?.connector_num === 2 ? (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM11 5H8L7.883 5.007C7.63995 5.03591 7.41594 5.15296 7.25341 5.33596C7.09088 5.51897 7.00111 5.75524 7.00111 6C7.00111 6.24476 7.09088 6.48103 7.25341 6.66403C7.41594 6.84704 7.63995 6.96409 7.883 6.993L8 7H11V9H9L8.85 9.005C8.37383 9.04078 7.92617 9.24576 7.58797 9.58286C7.24977 9.91996 7.04334 10.3669 7.006 10.843L7 11V13L7.005 13.15C7.04078 13.6262 7.24576 14.0738 7.58286 14.412C7.91996 14.7502 8.36695 14.9567 8.843 14.994L9 15H12L12.117 14.993C12.36 14.9641 12.5841 14.847 12.7466 14.664C12.9091 14.481 12.9989 14.2448 12.9989 14C12.9989 13.7552 12.9091 13.519 12.7466 13.336C12.5841 13.153 12.36 13.0359 12.117 13.007L12 13H9V11H11L11.15 10.995C11.6262 10.9592 12.0738 10.7542 12.412 10.4171C12.7502 10.08 12.9567 9.63305 12.994 9.157L13 9V7L12.995 6.85C12.9592 6.37383 12.7542 5.92617 12.4171 5.58797C12.08 5.24977 11.6331 5.04334 11.157 5.006L11 5Z"
                                      fill="#57585a"
                                    />
                                  </svg>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <span className="font-lexend">
                                  {row?.power}
                                </span>
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontFamily: "lexend",
                                  fontSize: "15px",
                                  width: "100px",
                                }}
                              >
                                {row?.status === "Available" && (
                                  <span className="flex items-center">
                                    {" "}
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                          stroke="#529fec"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>{" "}
                                    <span
                                      className="font-lexend"
                                      style={{ color: "#529fec" }}
                                    >
                                      Online
                                    </span>{" "}
                                  </span>
                                )}

                                {row?.status === "Charging" && (
                                  <>
                                    <span className="flex items-center">
                                      {" "}
                                      <span>
                                        <svg
                                          className="mr-2"
                                          width="15"
                                          height="14"
                                          viewBox="0 0 15 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.78906 2.75C1.78906 2.35218 1.9471 1.97064 2.2284 1.68934C2.50971 1.40804 2.89124 1.25 3.28906 1.25H11.5391C11.9369 1.25 12.3184 1.40804 12.5997 1.68934C12.881 1.97064 13.0391 2.35218 13.0391 2.75V3.125C13.0391 3.22446 13.0786 3.31984 13.1489 3.39017C13.2192 3.46049 13.3146 3.5 13.4141 3.5C13.5135 3.5 13.6089 3.53951 13.6792 3.60983C13.7496 3.68016 13.7891 3.77554 13.7891 3.875V6.125C13.7891 6.22446 13.7496 6.31984 13.6792 6.39016C13.6089 6.46049 13.5135 6.5 13.4141 6.5C13.3146 6.5 13.2192 6.53951 13.1489 6.60983C13.0786 6.68016 13.0391 6.77554 13.0391 6.875V7.25C13.0391 7.64782 12.881 8.02935 12.5997 8.31066C12.3184 8.59196 11.9369 8.75 11.5391 8.75H8.16406M3.28906 12.5V10.25M1.78906 7.25V5.375M4.78906 7.25V5.375M1.03906 7.25H5.53906V8.75C5.53906 9.14782 5.38103 9.52935 5.09972 9.81066C4.81842 10.092 4.43689 10.25 4.03906 10.25H2.53906C2.14124 10.25 1.75971 10.092 1.4784 9.81066C1.1971 9.52935 1.03906 9.14782 1.03906 8.75V7.25Z"
                                            stroke="#1AC47D"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>{" "}
                                      <span
                                        className="font-lexend"
                                        style={{ color: "#1AC47D" }}
                                      >
                                        Charging
                                      </span>{" "}
                                    </span>
                                  </>
                                )}

                                {row?.status === "Preparing" && (
                                  <span className="flex items-center">
                                    {" "}
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                          stroke="#52ec6c"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>{" "}
                                    <span style={{ color: "#52ec6c" }}>
                                      Connected
                                    </span>{" "}
                                  </span>
                                )}
                                {row?.status === "Finishing" && (
                                  <span style={{ color: "#fd9610" }}>
                                    Finishing
                                  </span>
                                )}
                                {row?.status === "Offline" && (
                                  <span className="flex items-center">
                                    {" "}
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.1625 13.2997C9.6009 13.4765 9.0093 13.5376 8.42344 13.4793C7.83759 13.4209 7.26964 13.2444 6.75395 12.9603C6.23826 12.6763 5.78553 12.2906 5.42313 11.8266C5.06072 11.3626 4.79618 10.8299 4.64549 10.2607C4.44852 9.51812 4.45141 8.73662 4.65385 7.99547C4.8563 7.25432 5.2511 6.57988 5.79824 6.0405L7.33874 4.5L12.7125 9.87375M3 15L5.625 12.375M11.25 3L8.625 5.625M15 6.75L12.375 9.375M12 12L15 15M15 12L12 15"
                                          stroke="#C99456"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>{" "}
                                    <span style={{ color: "#C99456" }}>
                                      Offline
                                    </span>{" "}
                                  </span>
                                )}
                                {row?.status === "Faulted" && (
                                  <span className="flex items-center">
                                    <span>
                                      <svg
                                        className="mr-2"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.55 14.733C9.8012 14.9687 9.0124 15.0501 8.23126 14.9724C7.45011 14.8946 6.69286 14.6592 6.00527 14.2804C5.31768 13.9017 4.71404 13.3874 4.23083 12.7688C3.74763 12.1501 3.39491 11.4399 3.19398 10.681C2.93136 9.69083 2.93521 8.64883 3.20514 7.66063C3.47506 6.67243 4.00146 5.77317 4.73098 5.054L6.78498 3L13.95 10.165M1 17L4.5 13.5M12 1L8.5 4.5M17 6L13.5 9.5M13 13L17 17M17 13L13 17"
                                          stroke="#981818"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <span className="text-red-900">
                                      Faulted
                                    </span>
                                  </span>
                                )}
                                {row?.status === "SuspendedEV" && (
                                  <span className="text-red-900">
                                    Suspended EV
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Tooltip title="Manage Load">
                                  <button
                                    className="flex justify-around items-center p-2 rounded-full"
                                    style={{
                                      background: "#1AC47D",
                                      border: "2px solid #1AAD70",
                                    }}
                                    // onClick={() => handleOpen(item?.id, item?.label)}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19 10L12 3L3 12H5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H14.5M9 21V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13C13.661 13 14.248 13.32 14.612 13.815M19 14L17 18H21L19 22"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Paper>
            </Box>
          </Fade>
        </Modal>

        {/* Set Load Management UI */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpenSetLoadManagementModal}
          onClose={handleCloseSetLoadManagementModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isOpenSetLoadManagementModal}>
            <Box sx={setLoadStyle}>
              <div className="flex justify-between px-4 items-center">
                <h1 className="font-lexend font-semibold text-lg ">
                  Load Balancing
                </h1>
                <button onClick={() => handleCloseSetLoadManagementModal()}>
                  Close
                </button>
              </div>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <SearchIcon />
                <TextField
                  id="outlined-basic"
                  label="Search by charger ID"
                  variant="standard"
                />
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
});

export default loadManagement;
